import { CustomButton, Header } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import HeaderFilter from "./headerFilter"
import TestQCTable from "./testQCTable"
import LotIndex from "./LotIndex"
import LotIndexTable from "./LotIndexTable"
import { getLotForChart, getQCDatapoints } from "store/actions"
import moment from "moment"
import qcDatapoints from "store/laboratoryIQC/ChartiQC/reducer"

const RESOURCE = ModuleIds.ChartiQC

const BodyLeft = ({
  t,
  lstLotId,
  filterChart,
  datapoints,
  onGetQCDatapoints,
  onGetLotForChart,
  onChangeSelected,
  onMachineItemsChange 
}) => {
  let initModel = {
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    machine: "",
    test: "",
    levels: "",
    lstLotId: [],
    lotGroup: "",
    lot: "",
    category: "",
  }

  const [selectedRow, setSelectedRow] = useState({})
  const [model, setModel] = useState(initModel)
  const [selectedLots, setSelectedLots] = useState([])
  const [chartDataInfo, setChartDataInfo] = useState([])
  const [chartDataIndex, setChartDataIndex] = useState([])
  useEffect(() => {
    onChangeSelected(selectedRow || {})
  }, [selectedRow])

  useEffect(() => {
    onGetLotForChart(model)
    onGetQCDatapoints(model)
  }, [])

  useEffect(() => {
    if (model.lstLotId != null && model.lstLotId.length > 0) {
      let tempModel = {
        start: model.start,
        end: model.end,
        machine: model.machine,
        test: model.test,
        levels: model.levels,
        lstLotId: model.lstLotId,
        lotGroup: "",
        lot: "",
        category: "",
      }
      onGetQCDatapoints(tempModel)
    }
  }, [model.lstLotId])

  const onRefreshHandler = () => {
    onGetLotForChart(model)
    onGetQCDatapoints(model)
  }

  const handleSelectedLotsChange = newSelectedLots => {
    setSelectedLots(newSelectedLots)
    setModel({ ...model, lstLotId: newSelectedLots })
  }

  useEffect(() => {
    let dicLevel = { 1: "", 2: "", 3: "" }
    let flatIndex = []
    for (let i = 0; i < 6; i++) {
      flatIndex.push([Object.assign({}, dicLevel)])
    }
    let fixIndex = [
      "Mean (PXN/NXS)",
      "SD (PXN/NXS)",
      "CV% (PXN/NXS)",
      "GHKS PXN",
      "GHKS NXS",
      t("Evaluation"),
    ]
    let newChartDataIndex = []
    let dataset = {}
    if (datapoints?.[selectedRow.testCode]?.["index"] != null) {
      dataset = datapoints[selectedRow.testCode]["index"]
    }

    Object.keys(dataset).forEach((key) => {
      const entry = dataset[key]
      let cvManual = entry?.cvManual !== null ? entry.cvManual : ""
      let meanManual = entry?.meanManual != null ? entry.meanManual : ""
      let sdManual = entry?.sdManual !== null ? entry.sdManual : ""
      let cvpi = entry?.cvpi !== null ? entry.cvpi : ""
      let meanPI = entry?.meanPI != null ? entry.meanPI : ""
      let sdpi = entry?.sdpi !== null ? entry.sdpi : ""
      let evaluate
      let manulimit = ""
      let lablimit = ""
      if (meanPI === "" || sdpi === "") {
        evaluate = t("Achieved")
      } else {
        manulimit =
          (meanPI - 2 * sdpi).toFixed(1) +
          " - " +
          (meanPI + 2 * sdpi).toFixed(1)
        if (meanManual !== "" && sdManual !== "") {
          lablimit =
            (meanManual - 2 * sdManual).toFixed(1) + " - " + (meanManual + 2 * sdManual).toFixed(1)
        }
        if (sdManual > sdpi) {
          evaluate = t("Unachieved")
        } else if (sdManual <= sdpi) {
          evaluate = t("Achieved")
        }
      }
      flatIndex[0][key] = meanManual + " / " + meanPI
      flatIndex[1][key] = sdManual + " / " + sdpi
      flatIndex[2][key] = cvManual + " / " + cvpi
      flatIndex[3][key] = lablimit
      flatIndex[4][key] = manulimit
      flatIndex[5][key] = evaluate
    })
    for (let i = 0; i < fixIndex.length; i++) {
      newChartDataIndex.push({
        index: fixIndex[i],
        level1: flatIndex[i][1] !== null ? flatIndex[i][1] : "",
        level2: flatIndex[i][2] !== null ? flatIndex[i][2] : "",
        level3: flatIndex[i][3] !== null ? flatIndex[i][3] : "",
      })
    }
    setChartDataIndex(newChartDataIndex)
  }, [filterChart, datapoints, selectedRow])

  useEffect(() => {
    let newChartDataInfo = []
    let setTestCode = new Set([])
    if (datapoints != null) {
      Object.keys(datapoints).forEach((key) => {
        const entry = datapoints[key]?.total
        Object.keys(entry).forEach((key2) => {
          if (!entry[key2]) {
            console.warn(`Invalid data points for ${key}`)
            return
          }
          const levelData = entry[key2]
          levelData.forEach(item => {
            if (!setTestCode.has(item.testCode)) {
              newChartDataInfo.push({
                testCode: item.testCode,
                testName: item.testName,
              })
            }
            setTestCode.add(item.testCode)
          })
        })
      })
      setChartDataInfo(newChartDataInfo)
    }
  }, [datapoints])

  return (
    <React.Fragment>
      <div className="d-flex pt-2 justify-content-between">
        <Header
          title={t("QualityManagement")}
          resource={RESOURCE}
          subtitle={t("sidebar:Chart iQC")}
        />
      </div>
      <HeaderFilter
        model={model}
        onChange={val => {
          setModel({ ...model, ...val, lstLotId: [] })
        }}
        onSubmitFilter={() => {
          onGetLotForChart(model)
          onGetQCDatapoints(model)
        }}
        onChangeMachine={onMachineItemsChange}
      />

      <TestQCTable
        onChangeModel={val => {
          setModel({ ...model, ...val, lstLotId: [] })
        }}
        model={model}
        configs={chartDataInfo || []}
        onRefresh={onRefreshHandler}
        paging={{ page: 1, size: chartDataInfo.length }}
        onSelected={row => {
          setSelectedRow(row)
        }}
        onReset={() => {
          setModel({
            ...model,
            start: moment().format("YYYY-MM-DD"),
            end: moment().format("YYYY-MM-DD"),
            lotGroup: "",
            lot: "",
            category: "",
          })
        }}
        onSubmitFilter={() => {
          onGetLotForChart(model)
          onGetQCDatapoints(model)
        }}
      />
      <LotIndex
        lstLotId={lstLotId}
        selectedRow={selectedRow}
        onChange={handleSelectedLotsChange}
      />
      <LotIndexTable
        configs={chartDataIndex || []}
        paging={{ page: 1, size: chartDataIndex.length }}
      />
    </React.Fragment>
  )
}

BodyLeft.propTypes = {
  datapoints: PropTypes.object,
  lstLotId: PropTypes.object,
}

const mapStateToProps = ({ qcDatapoints }) => ({
  lstLotId: qcDatapoints.lstLotId,
  datapoints: qcDatapoints.datapoints,
  loadingDatapoints: qcDatapoints.loadingDatapoints,
  error: qcDatapoints.error,
})
const mapDispatchToProps = dispatch => ({
  onGetLotForChart: payload => dispatch(getLotForChart(payload)),
  onGetQCDatapoints: payload => dispatch(getQCDatapoints(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["chartiQCPage", "message", "common", "sidebar"])(BodyLeft)
  )
)
