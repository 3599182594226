import { AvForm } from "availity-reactstrap-validation";
import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomDatePicker,
  CustomModal,
  CustomSelect,
  CustomSelectGroup,
} from "components/Common";
import { showWanringToast } from "components/Common/ShowWarningToastr";
import { parameterCode } from "constant/utility";
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Col, ModalBody, ModalFooter, Row } from "reactstrap";

const ConfigLotQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Lot")

  const [maxOpenDate, setMaxOpenDate] = useState(
      data.maxOpenDate ? convertDateFormat(data.maxOpenDate, "DD-MM-YYYY") : convertDateFormat(new Date(), "DD-MM-YYYY")
    );
  const [expireDate, setExpireDate] = useState(
      data.expireDate ? convertDateFormat(data.expireDate, "DD-MM-YYYY") : convertDateFormat(new Date(), "DD-MM-YYYY")
    );    
  const [instrumentIdsArray, setInstrumentIdsArray] = useState([]
  );
useEffect(() => {
  if (!isEdit) {
    setInstrumentIdsArray(null);
  } else if (data?.instrumentIds) {
    setInstrumentIdsArray(data?.instrumentIds.split(',').map(id => parseInt(id, 10)));
  }
}, [isEdit, data?.instrumentIds]);

  const handleValidSubmit = (event, values) => {
    const updatedData = {
      ...values,
      maxOpenDate: maxOpenDate,
      expireDate: expireDate,
      instrumentIds: instrumentIdsArray.join(','),
    };
    onValidSubmit(event, updatedData);
  };

  if (isEdit && isEmpty(data)) return null;

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="testForm"
          onValidSubmit={handleValidSubmit}
          model={data}
        >
          <AccordionWrapper defaultTab="1">
            <Accordion tabId={"1"} title={t("LOT Information")}>
              <CustomAvField name="id" type="hidden" value={data?.id || ""} />
              <Row className="px-2">
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="lotId"
                      type="text"
                      required
                      errorMessage={getInvalidMessageI18n(t, "Lot ID")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 100 },
                      }}
                      value={data?.lotId || ""}
                      disabled={isEdit}
                      label={t("Lot ID")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="lotName"
                      type="text"
                      required
                      disabled={isEdit}
                      errorMessage={getInvalidMessageI18n(t, "Lot Name")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 150 },
                      }}
                      value={data?.lotName || ""}
                      label={t("Lot Name")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                  <CustomDatePicker
                    name="expireDate"
                    label={t("Expiration Date")}
                    dateFormat={"d-m-Y"}
                    value={convertDateFormat(expireDate || new Date(), "DD-MM-YYYY")}
                    required
                    onChangeHandler={(e) => {
                      const selectedDate = e.time[0]
                      if (selectedDate >= maxOpenDate) {
                        showWanringToast(t("Expiration Date must be greater than Open Date"));
                        return;
                      }
                      setExpireDate(selectedDate);
                    }}
                  />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelectGroup
                      required
                      portal
                      errorMessage={getInvalidMessageI18n(t, "Test Category")}
                      name="testCategory"
                      value={data?.testCategory || ""}
                      code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                      label={t("Test Category")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="manufactor"
                      type="text"
                      value={data?.manufactor || ""}
                      label={t("Manufactor")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Lot Group")}
                      name="lotGroup"
                      portal
                      value={data?.lotGroup || ""}
                      code={parameterCode.LOT_GROUP_QC}
                    />
                  </div>
                </Col>
                <Col className="col-12">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Machines")}
                      name="instrumentId"
                      portal
                      isMulti
                      required
                      errorMessage={getInvalidMessageI18n(t, "Machines")}
                      value={instrumentIdsArray}
                      code={parameterCode.INSTRUMENT}
                      onChange={(name, value, label, selectedOptions) => {
                        const selectedIds = selectedOptions.map(option => option.value);
                        setInstrumentIdsArray(selectedIds);
                      }}
                      
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Levels")}
                      name="levels"
                      portal
                      value={data?.levels}
                      code={parameterCode.LEVEL_QC}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfPointPI"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Number of PI Points")}
                      value={data?.noOfPointPI || ""}
                      validate={{
                        pattern: { value: /^[0-9]+$/, errorMessage: t("Only positive numbers are allowed") }
                      }}
                      label={t("Number of PI Points")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfRun"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Number of Runs")}
                      value={data?.noOfRun || ""}
                      validate={{
                        pattern: { value: /^[0-9]+$/, errorMessage: t("Only positive numbers are allowed") }
                      }}
                      label={t("Number of Runs")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfPointCal"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Number of Cal Points")}
                      value={data?.noOfPointCal || ""}
                      validate={{
                        pattern: { value: /^[0-9]+$/, errorMessage: t("Only positive numbers are allowed") }
                      }}
                      label={t("Number of Calibration Points")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="maxDayOpen"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Max Day Open")}
                      value={data?.maxDayOpen || ""}
                      validate={{
                        pattern: { value: /^[0-9]+$/, errorMessage: t("Only positive numbers are allowed") }
                      }}
                      label={t("Max Days Open")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <Row className="d-flex">
                    <div className="mb-3">
                    <CustomDatePicker
                      name="maxOpenDate"
                      label={t("Open Date")}
                      dateFormat={"d-m-Y"}
                      value={convertDateFormat(maxOpenDate || new Date(), "DD-MM-YYYY")}
                      onChangeHandler={e => {
                        const selectedDate = e.time[0]
                        if (selectedDate >= expireDate) {
                          showWanringToast(t("Open Date must be smaller than Expiration Date"));
                          return;
                        }
                        setMaxOpenDate(selectedDate);
                      }}
                    />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["managementQCPage", "common"])(ConfigLotQCModal);
