import { CustomButton, CustomNav, CustomNavLink } from "components/Common"
import { convertDateFormat_VN } from "helpers/utilities"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, TabContent, TabPane } from "reactstrap"
import { addNewLotTestQC, getQCLotTest } from "store/actions"
import TabResult from "./TabResult"
import TabOptional from "./TabOptional"
import PropTypes from "prop-types"

const RESOURCE = ModuleIds.ChartiQC

const BodyRight = ({ t, filterChart, datapoints, selected }) => {
  const [currentTab, setCurrentTab] = useState("1")
  const [dicResultBySeq, setDicResultBySeq] = useState([])

  useEffect(() => {
    let arrline = []
    let mapDayRunSeq = new Map()
    if (
      Object.keys(datapoints).length != 0 &&
      selected?.testCode != null
    ) {
      let selectedTest = datapoints[selected.testCode][filterChart]
      for (const key in selectedTest) {
        selectedTest[key].forEach(item => {
          if (item.runTime != null && item.runSeq != null) {
            let dicKey =
              convertDateFormat_VN(item.runTime) + item.runSeq + item.lotId
            if (!mapDayRunSeq.has(dicKey)) {
              mapDayRunSeq.set(dicKey, [
                { res: "", err: "" },
                { res: "", err: "" },
                { res: "", err: "" },
                "",
                "",
              ])
            }
            mapDayRunSeq.get(dicKey)[3] = convertDateFormat_VN(item.runTime)
            mapDayRunSeq.get(dicKey)[4] = item.id
            if (filterChart === "validated") {
              if (item.levels == 1 && item.validatedBy != null) {
                mapDayRunSeq.get(dicKey)[0].res = item?.result
                mapDayRunSeq.get(dicKey)[0].err =
                  item?.error !== undefined ? item.error : ""
              }
              if (item.levels == 2 && item.validatedBy != null) {
                mapDayRunSeq.get(dicKey)[1].res = item?.result
                mapDayRunSeq.get(dicKey)[1].err =
                  item?.error !== undefined ? item.error : ""
              }
              if (item.levels == 3 && item.validatedBy != null) {
                mapDayRunSeq.get(dicKey)[2].res = item?.result
                mapDayRunSeq.get(dicKey)[2].err =
                  item?.error !== undefined ? item.error : ""
              }
            } else {
              if (item.levels == 1) {
                mapDayRunSeq.get(dicKey)[0].res = item?.result
                mapDayRunSeq.get(dicKey)[0].err =
                  item?.error !== undefined ? item.error : ""
              }
              if (item.levels == 2) {
                mapDayRunSeq.get(dicKey)[1].res = item?.result
                mapDayRunSeq.get(dicKey)[1].err =
                  item?.error !== undefined ? item.error : ""
              }
              if (item.levels == 3) {
                mapDayRunSeq.get(dicKey)[2].res = item?.result
                mapDayRunSeq.get(dicKey)[2].err =
                  item?.error !== undefined ? item.error : ""
              }
            }
          }
        })
      }
    }
    mapDayRunSeq.forEach((line, key) => {
      arrline.push({
        id: line[4],
        runTime: line[3],
        res1: line[0]?.res,
        err1: line[0]?.err,
        res2: line[1]?.res,
        err2: line[1]?.err,
        res3: line[2]?.res,
        err3: line[2]?.err,
      })
    })
    setDicResultBySeq(arrline)
  }, [datapoints, filterChart, selected])

  return (
    <Card>
      <CardBody className="p-0">
        <CustomNav
          onToggle={e => {
            setCurrentTab(e)
          }}
          defaultTab={"1"}
          tabs
          className="nav-tabs-custom p-0"
          tabContents={customActiveTab => (
            <TabContent activeTab={customActiveTab} className="text-muted p-0">
              <TabPane tabId="1">
                {currentTab == "1" && (
                  <TabResult dicResultBySeq={dicResultBySeq} />
                )}
              </TabPane>
              <TabPane tabId="2">
                {currentTab == "2" && <TabOptional />}
              </TabPane>
            </TabContent>
          )}
        >
          <CustomNavLink tabId="1">
            <span>{t("Result")}</span>
          </CustomNavLink>
          <CustomNavLink tabId="2">
            <span>{t("Optional")}</span>
          </CustomNavLink>
        </CustomNav>
      </CardBody>
    </Card>
  )
}

BodyRight.propTypes = {
  t: PropTypes.any,
  datapoints: PropTypes.object,
}
const mapStateToProps = ({ qcDatapoints }) => ({
  datapoints: qcDatapoints.datapoints,
})
const mapDispatchToProps = dispatch => ({
  onGetQCLotTest: payload => dispatch(getQCLotTest(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "message", "common"])(BodyRight)))
