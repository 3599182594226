import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    convertDateFormat,
    convertKeysToPascalCase,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { addNewReport, deleteReports, getReportDetail, getReports, resetReportSearchQuery, updateReport }
    from "store/fastreport/reports/actions"
// import ReportTable from "./ReportTable"
// import ReportModal from "./Modal/ReportModal"
import { getReportFile } from "helpers/app-backend/reports_backend_helper"
import { insertUrlParam, appendUrl, getUrlParams, getUrlParamByKey, appendParams } from "helpers/utilities"
import ReportStatisticTable from "./ReportStatisticTable"
import FilterForm from "./FilterForm"
import { getFilePreviewFromFW } from "helpers/app-backend"
import ModalPreviewReportStatistic from "components/Common/Modals/ModalPreviewReportStatistic"

const RESOURCE = ModuleIds.ReportStatistic
const DEFAULT_REPORT = { label: 'Blank', value: 'Blank' }
const ratioPixel = window.devicePixelRatio;

const ReportStatistic = ({
    t,
}) => {
    const initModel = {
        fromDate: convertDateFormat(new Date(), 'YYYY-MM-DD 00:00'),
        toDate: convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm'),
        category: "",
        serviceType: "",
        state: "",
        vendorCode: "",
        groupCode: "",
        searchText: "",
        vendorId: "",
        code: "",
        groupId: "",
        receiverId: "",
        returnId: "",
        sampleType: "",
        instrumentId: "",
        dateType: "",
        resultState: "",
        resultStateIns: "",
        qcLot: "",
        level: "",
        deleteName: "",
        sigma: "",
        ruleIQC: ""
    }
    const [model, setModel] = useState(initModel)
    const [currentReport, setCurrentReport] = useState(DEFAULT_REPORT);
    const [reportUrl, setReportUrl] = useState('');
    const [reportHeight, setReportHeight] = useState(1000)
    const [reportWidth, setReportWidth] = useState('1200px');
    const [isReady, setIsReady] = useState(true);
    const [modalPreview, setModalPreview] = useState(false);
    const [modal, setModal] = useState(false)
    const [localReport, setLocalReport] = useState()

    const onChangeModel = (val) => {
        setModel(val)
    }

    const getReportView = async (values, id) => {
        let data = {
            reportId: id
        };
        if (values.instrumentId && typeof values.instrumentId === 'object') {
            values.instrumentId = values.instrumentId.insID;
        }
        let newValues = convertKeysToPascalCase(values)
        data = { ...data, ...newValues }
        const res = await getFilePreviewFromFW(data)
        setReportUrl(res?.reportUrl)
    }

    const onViewReport = (reportId) => {
        setLocalReport(reportId)
        setModalPreview(true)
        getReportView(model, reportId)
    }

    const onLoad = (ref) => {
        let localReportId = localReport;
        let elements = reportUrl?.split('?');
        if (elements.length > 0) {
            let reportIdd = elements[elements.length - 1].split('=');
            if (reportIdd.length > 0) {
                localReportId = reportIdd[reportIdd.length - 1];
                console.log(localReportId)
                // setReportId(localReportId)
            }
        }

        if (localReportId) {
            //document.getElementsByClassName(`fr${localReportId}-report`)[0].childNodes ? [1]?.style?.width
            //console.log("report", `fr${localReportId}-report`, document.getElementsByClassName(`fr${localReportId}-report`));
            //console.log("container", `fr${localReportId}-container`, document.getElementsByClassName(`fr${localReportId}-container`));
            if (document.getElementsByClassName(`fr${localReportId}-report`)[0]) {
                let length = document.getElementsByClassName(`fr${localReportId}-report`)[0].childNodes.length;
                let divtemp = document.getElementsByClassName(`fr${localReportId}-report`)[0].childNodes[length - 1];
                if (divtemp) {
                    setReportWidth(divtemp?.style?.width)
                    console.log("width", divtemp?.style?.width);
                }
            }
        }

        try {
            setTimeout(() => {
                try {
                    let htmlElement = document.getElementById("iframe").contentWindow.document;
                    let width = htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.width;
                    if (width) {
                        setReportWidth((parseInt(width.replace(/px/, "")) + 20) + "px")
                        htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.width = (parseInt(width.replace(/px/, "")) + 6) + "px"
                    }
                    let height = htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.height;
                    if (height) {
                        reportIdGlobal = localReportId
                        onResizeHeight(localReportId)
                        var target = htmlElement.querySelector(`.fr${localReportId}-body`)
                        observer.observe(target, { attributes: true, attributeFilter: ['style'] });

                        var targetChild = document.querySelector(`.fr${localReportId}-body`)
                        observerChild.observe(targetChild, { attributes: true, attributeFilter: ['style'] });
                    }
                } catch (error) {
                    console.log(error);
                }

            }, 300);
        } catch (e) {
            console.log(e);
        }


        //document.getElementById("iframe").contentDocument.body.childNodes[1].childNodes[9].childNodes[1].childNodes[1].style.width

        //document.getElementsByClassName("fr7a5a3ad7a426401796a1867c1e642e03-report")[0].childNodes[1].style.width

        //console.log(ref.target.contentWindow.document.body);
        //setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    };

    return (
        <React.Fragment>
            {/* Body */}

            <TitleAndTable
                customComponent={() => (
                    <div className="report-statistic">
                        <FilterForm
                            onChangeModel={(model) => {
                                onChangeModel(model)
                            }}
                            model={model}
                            onViewReport={onViewReport}
                        // onExport={onExport}
                        />
                    </div>
                )}
                title={t("Report manager")}
                subtitle={t("sidebar:Report Statistic")}
            />
            {/* {modalPreview &&
                <ModalPreviewReportStatistic
                    modal={modalPreview}
                    toggle={() => { setModalPreview(false) }}
                    reportUrl={reportUrl}
                >

                </ModalPreviewReportStatistic>
            } */}
            <div className="col-lg-12" style={{ margin: "20px auto" }}>
                <div style={{ display: 'flex', justifyContent: 'center', height: `500px` }}>
                    {reportUrl && reportUrl != "" &&
                        <React.Fragment>
                            <iframe id="iframe" height={`600px`}
                                width={"2000px"}
                                onLoad={onLoad}
                                style={{ minHeight: "auto", overflowX: "hidden", visibility: `${isReady == true ? 'unset' : 'hidden'}` }} src={process.env.REACT_APP_BASE_ENDPOINT + reportUrl}
                                allow="fullscreen"

                            />
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

ReportStatistic.propTypes = {
    reports: PropTypes.array,
    onGetReportDetail: PropTypes.func,
    onGetReports: PropTypes.func,
    onAddNewReport: PropTypes.func,
    onUpdateReport: PropTypes.func,
    onDeleteReport: PropTypes.func,
    onResetReportSearchQuery: PropTypes.func,
    paging: PropTypes.object,
    loadingReports: PropTypes.bool,
    updateReportTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ report }) => {
    return ({
        reports: report.reports,
        report: report.report,
        paging: report.paging,
        loadingReports: report.loadingReport,
        updateReportTime: report.updateReportTime,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetReports: payload => dispatch(getReports(payload)),
    onAddNewReport: (report, history) => dispatch(addNewReport(report, history)),
    onUpdateReport: report => dispatch(updateReport(report)),
    onDeleteReport: report => dispatch(deleteReports(report)),
    onGetReportDetail: reportId => dispatch(getReportDetail(reportId)),
    onResetReportSearchQuery: () => dispatch(resetReportSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["reportPage", "message", "common"])(ReportStatistic)))
