import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"

import {
    GET_QC_DATAPOINTS,
    GET_LOT_FOR_CHART,
    GET_RESULT_BY_SEQ
} from "./actionTypes"

import {
    getQCDatapointsFail,
    getQCDatapointsSuccess,
    getLotForChartFail,
    getLotForChartSuccess,
} from "./actions"

import { GetQCDatapoints, GetLotForChart } from "helpers/app-backend/IQC/QCDatapoints_backend_helper"
const t = (msg, param) => i18n.t(msg, param)

function* fetchQCDatapoints({ payload,callback }) {
    try {
        let response = yield call(GetQCDatapoints, payload)
        yield put(getQCDatapointsSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getQCDatapointsFail(error))
    }
}

function* fetchLotForChart({ payload,callback }) {
    try {
        let response = yield call(GetLotForChart, payload)
        yield put(getLotForChartSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getLotForChartFail(error))
    }
}

function* qcDatapointsSaga() {
    yield takeEvery(GET_QC_DATAPOINTS, fetchQCDatapoints)   
    yield takeEvery(GET_LOT_FOR_CHART, fetchLotForChart)   
}

export default qcDatapointsSaga