import { Header } from "components/Common"
import { ModuleIds } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import HeaderFilter from "./headerFilter"
import ConfigLotTestQCTable from "./configLotTestQCTable"
import { getLotQCListFromLotTest } from "store/actions"
import moment from "moment"

const RESOURCE = ModuleIds.ManagementQC


const ConfigLotTestQCLeft = ({
    t,
    onGetLotQCListFromLotTest,
    updatedTimeListFromLotTest,
    loadinglotListFromLotTest,
    lotListFromLotTests,
    pagingListFromLotTest,
    onChangeSelected
}) => {
    let initModel = {
        start: null,
        end: null,
        lotGroup: '',
        isExpired: false,
        category: '',
        machine: '',
        search: '',
        passDate: true
    }
    const [selectedRow, setSelectedRow] = useState({})
    const [model, setModel] = useState(initModel)
    useEffect(() => {
        onChangeSelected(selectedRow || {})
    }, [selectedRow])

    useEffect(() => {
        fetchLotQCListFromLotTest()
    }, [])

    const onRefreshHandler = () => {
        fetchLotQCListFromLotTest()
    }

    const fetchLotQCListFromLotTest = (page = {}, isSubmitForm = false) => {
        let dt = { data: { ...model, ...page } }
        if (isSubmitForm) {
            dt.passDate = false
        }
        onGetLotQCListFromLotTest(dt)
    }
    const onSizePerPageChange = size => {
        fetchLotQCListFromLotTest({ page: 1, size })
    }

    const onPageChange = page => {
        fetchLotQCListFromLotTest({ page })
    }
    return (
        <React.Fragment>
            <div className="pt-2">
                <Header
                    title={t("Config Lot Test QC Title")}
                    resource={RESOURCE}
                    subtitle={t("Config Lot Test QC Sub Title")}
                />
            </div>
            <HeaderFilter
                model={model}
                onChange={val => {
                    setModel({ ...model, ...val })
                }}
                onSubmitFilter={() => {
                    fetchLotQCListFromLotTest(model)
                }}
                onClear={() => {
                    fetchLotQCListFromLotTest({ ...model, search: '' })
                }}
            />
            <ConfigLotTestQCTable
                onChangeModel={val => {
                    setModel({ ...model, ...val })
                }}
                model={model}
                configs={lotListFromLotTests || []}
                onRefresh={onRefreshHandler}
                onPageChange={onPageChange}
                paging={pagingListFromLotTest}
                onSizePerPageChange={onSizePerPageChange}
                loading={loadinglotListFromLotTest}
                updatedTime={updatedTimeListFromLotTest}
                onSelected={row => {
                    setSelectedRow(row)
                }}
                onReset={() => {
                    let val = {
                        ...model,
                        start: null,
                        end: null,
                        lotGroup: '',
                        isExpired: false,
                        passDate: true
                    }
                    setModel(val)
                    fetchLotQCListFromLotTest({ ...model, ...val })
                }}
                onSubmitFilter={() => {
                    setModel({ ...model, passDate: false })
                    fetchLotQCListFromLotTest({ ...model, passDate: false })
                }}
            />
        </React.Fragment>
    )
}
const mapStateToProps = ({ lotQCs }) => ({
    updatedTimeListFromLotTest: lotQCs.updatedTimeListFromLotTest,
    loadinglotListFromLotTest: lotQCs.loadinglotListFromLotTest,
    lotListFromLotTests: lotQCs.lotListFromLotTests,
    pagingListFromLotTest: lotQCs.pagingListFromLotTest,
})
const mapDispatchToProps = dispatch => ({
    onGetLotQCListFromLotTest: payload => dispatch(getLotQCListFromLotTest(payload)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["managementQCPage", "message", "common"])(ConfigLotTestQCLeft)))
