import React, { useState } from "react"
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

// import pdf libs
import jsPDF from "jspdf"
import "jspdf-autotable"
import RobotoFont from "./Roboto-Regular-normal"

// import excel libs
import ReactExport from "react-export-excel"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportButton = ({ data }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const exportPDF = () => {
    const unit = "pt"
    const size = "A2" // Use A1, A2, A3 or A4
    const orientation = "portrait" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    var font = RobotoFont

    // add the font to jsPDF
    doc.addFileToVFS("Roboto-Regular-normal.ttf", font)
    doc.addFont("Roboto-Regular-normal.ttf", "Roboto-Regular", "normal")
    doc.setFont("Roboto-Regular")

    doc.setFontSize(15)

    const title = ""
    const headers = [Object.keys(data[0]).map(col => camelCase(col))]
    const body = data.map(elt => Object.values(elt))
    let content = {
      startY: 50,
      head: headers,
      body: body,
      styles: {
        font: "Roboto-Regular",
        fontStyle: "normal",
      },
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save("report.pdf")
  }

  const camelCase = str => {
    return str.substring(0, 1).toUpperCase() + str.substring(1)
  }

  const filterColumns = data => {
    // Get column names
    const columns = Object.keys(data[0])

    // Remove by key (firstname)
    const filterColsByKey = columns.filter(c => c !== "firstname")

    // OR use the below line instead of the above if you want to filter by index
    // columns.shift()

    return filterColsByKey // OR return columns
  }

  return (
    <ButtonDropdown
      isOpen={isMenuOpen}
      toggle={() => setIsMenuOpen(prev => !prev)}
      direction="left"
      className="dropstart btn-export"
      disabled={data.length === 0}
      
    >
      <DropdownToggle caret color="secondary" outline>
        <i className="fas fa-file-export"></i>
      </DropdownToggle>
      <DropdownMenu data-popper-placement="left-start">
        <ExcelFile filename="test" element={<DropdownItem>Excel</DropdownItem>}>
          <ExcelSheet data={data} name="Test">
            {data?.length > 0 &&
              filterColumns(data).map((col, _idx) => {
                return (
                  <ExcelColumn key={_idx} label={camelCase(col)} value={col} />
                )
              })}
          </ExcelSheet>
        </ExcelFile>
        <DropdownItem onClick={exportPDF}>PDF</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default ExportButton
