import ReactECharts from "echarts-for-react"
import "echarts/theme/roma"
import { convertDateTimeFormat_VN, convertDayMonth } from "helpers/utilities"
import { get } from "lodash"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { reset } from "redux-form"

const TabChart = ({
  showBothCharts,
  showUnvalidatedPoint,
  levelsChartOption,
  timeOption,
  levels,
  datapoints,
  selected,
  onChangeFilterChart,
  machineItems,
  t,
}) => {
  const [dataset, setDataset] = useState({})
  const [oneLevelDataset, setOneLeveDataset] = useState({})
  const [filterChart, setFilterChart] = useState("validated")
  const [optionsNSX, setOptionsNSX] = useState({})
  const [options1, setOptions1] = useState({})
  const [options2, setOptions2] = useState({})
  const [options3, setOptions3] = useState({})
  const [options4, setOptions4] = useState({})
  const [mapMachineItems, setMapMachineItems] = useState(null)
  const [options, setOptions] = useState({
    title: {
      text: "",
      subtext: "",
      left: "center",
    },
    legend: {
      data: ["Mean", "+3 SD", "-3 SD", "+2 SD", "-2 SD", "+1 SD", "-1 SD"],
      top: "5%",
      left: "center",
      textStyle: {
        fontSize: 12,
        color: "#444",
      },
    },
    grid: {
      left: "10%",
      right: "10%",
      bottom: "15%",
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        fontSize: 12,
      },
      scale: true,
      boundaryGap: false,
      name: "",
    },
    yAxis: {
      type: "value",
      name: "",
      interval: 5,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "#ddd",
        },
      },
      min: Math.min(dataset.mean - 3 * dataset.sd, 0),
      data: [
        dataset.mean + 3 * dataset.sd,
        dataset.mean + 2 * dataset.sd,
        dataset.mean + dataset.sd,
        dataset.mean,
        dataset.mean - dataset.sd,
        dataset.mean - 2 * dataset.sd,
        dataset.mean - 3 * dataset.sd,
      ],
      axisLabel: {
        fontSize: 12,
        formatter: function (value) {
          if (value === dataset.mean) {
            return "Mean"
          } else if (value === dataset.mean + 3 * dataset.sd) {
            return "+3 SD"
          } else if (value === dataset.mean + 2 * dataset.sd) {
            return "+2 SD"
          } else if (value === dataset.mean + dataset.sd) {
            return "+1 SD"
          } else if (value === dataset.mean - dataset.sd) {
            return "-1 SD"
          } else if (value === dataset.mean - 2 * dataset.sd) {
            return "-2 SD"
          } else if (value === dataset.mean - 3 * dataset.sd) {
            return "-3 SD"
          }
          return value
        },
      },
      scale: true,
      boundaryGap: false,
    },

    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0,0,0,0.7)",
      textStyle: {
        color: "#FFF",
        fontSize: 13,
        fontFamily: "Google Sans",
      },
      appendToBody: true,
      formatter: param => {
        if (param.data.status !== "REJ") {
          return `${t("Test Name")}: ${param.data.testName}<br/>
                ${t("Machine")}: ${param.data.insName}<br/>
                ${t("Level")}: ${param.data.levels}<br/>
                ${t("Lot")}: ${param.data.lotName}<br/>
                ${t("Result")}: ${param.data.value || "N/A"}<br/>
                ${t("Unit")}: ${param.data.unit || "N/A"}<br/>
                ${t("Mean")}: ${param.data.mean || "N/A"}<br/>
                ${t("SD")}: ${param.data.sd || "N/A"}<br/>
                ${t("GHKS")}: ${
            param.data.mean -
              2 * param.data.sd +
              " - " +
              param.data.mean +
              2 * param.data.sd || "N/A"
          }<br/>
                ${t("6Sigma")}: ${param.data._6Sigma || "N/A"}<br/>
                ${t("Run Time")}: ${
            param.data.runTime
              ? convertDateTimeFormat_VN(param.data.runTime)
              : ""
          }<br/>
                ${t("Validated Time")}: ${
            param.data.validatedTime
              ? convertDateTimeFormat_VN(param.data.validatedTime)
              : ""
          }
                ${t("Validated By")}: ${param.data.validatedBy || ""}<br/>`
        } else {
          return `${t("Test Code")}: ${param.data.testCode} - ${
            param.data.testName
          }<br/>
                  ${t("Lot")}: ${param.data.lotName}<br/>
                  ${t("Result")}: ${param.data.value || "N/A"}<br/>
                  ${t("Mean")}: ${param.data.mean || "N/A"} - ${t("SD")}: ${
            param.data.sd || "N/A"
          }
                  ${t("Run Time")}: ${
            param.data.runTime
              ? convertDateTimeFormat_VN(param.data.runTime)
              : ""
          }<br/>
                  ${t("Validated Time")}: ${
            param.data.validatedTime
              ? convertDateTimeFormat_VN(param.data.validatedTime)
              : ""
          }  - ${t("Validated By")}: ${param.data.validatedBy || ""}<br/>
                  ${t("Violated Rules")}: ${
            param.data.singleRules || "N/A"
          }<br/>`
        }
      },
    },
    series: [
      {
        name: "Data Series",
        type: "line",
        data: dataset,
        markLine: {
          symbol: "none",
          data: [
            { name: "Mean", yAxis: dataset.mean },
            { name: "+3 SD", yAxis: dataset.mean + 3 * dataset.sd },
            { name: "-3 SD", yAxis: dataset.mean - 3 * dataset.sd },
            { name: "+2 SD", yAxis: dataset.mean + 2 * dataset.sd },
            { name: "-2 SD", yAxis: dataset.mean - 2 * dataset.sd },
            { name: "+1 SD", yAxis: dataset.mean + dataset.sd },
            { name: "-1 SD", yAxis: dataset.mean - dataset.sd },
          ],
          lineStyle: {
            color: "#333",
            width: 2,
            type: "solid",
          },
          label: {
            show: true,
            position: "end",
            fontSize: 12,
            color: "#333",
          },
        },
      },
    ],
  })

  const ref = useRef()
  const refNSX = useRef()
  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()

  useEffect(() => {
    let tempMapMachine = new Map()
    for (let i = 0; i < machineItems.length; i++) {
      tempMapMachine.set(machineItems[i].insID, machineItems[i].instrumentQC)
    }
    setMapMachineItems(tempMapMachine)
  }, [machineItems])

  useEffect(() => {
    let showType = showUnvalidatedPoint ? "total" : "validated"
    setFilterChart(showType)
    if (onChangeFilterChart) {
      onChangeFilterChart(showType)
    }
  }, [showUnvalidatedPoint])

  useEffect(() => {
    if (!datapoints || typeof datapoints !== "object") {
      return
    }
    const showType = showUnvalidatedPoint ? "total" : "validated"
    let levelData = null
    if (selected?.testCode != null) {
      levelData = datapoints[selected.testCode]
    }
    if (levelData == null) {
      return
    }
    let newDataset = {}
    setFilterChart(showType)

    if (levels != null) {
      let getQCDatapointsDetails = levelData[showType][levels]
      let dicIndexByLevel = levelData["index"][levels]
      if (getQCDatapointsDetails) {
        newDataset[levels] = getQCDatapointsDetails
        newDataset["mean"] = dicIndexByLevel?.mean
        newDataset["cv"] = dicIndexByLevel?.cv
        newDataset["sd"] = dicIndexByLevel?.sd
        newDataset["meanPI"] = dicIndexByLevel?.meanPI
        newDataset["sdpi"] = dicIndexByLevel?.sdpi
        newDataset["cvpi"] = dicIndexByLevel?.cvpi
      }
    } else {
      let getQCDatapointsDetails = levelData[showType]
      let key = Object.entries(levelData["index"])[0]
      let dicIndexByLevel = levelData["index"][key[0]]
      newDataset = { ...getQCDatapointsDetails }
      newDataset["mean"] = dicIndexByLevel?.mean
      newDataset["cv"] = dicIndexByLevel?.cv
      newDataset["sd"] = dicIndexByLevel?.sd
      newDataset["meanPI"] = dicIndexByLevel?.meanPI
      newDataset["sdpi"] = dicIndexByLevel?.sdpi
      newDataset["cvpi"] = dicIndexByLevel?.cvpi
      if (levelsChartOption === "one") {
        let newOneLevelDataset = new Object()
        Object.keys(getQCDatapointsDetails).forEach((key) => {
          newOneLevelDataset[key] = {} // Initialize the key as an empty object
          newOneLevelDataset[key][key] = getQCDatapointsDetails[key]
          let dicIndexByLevel = levelData["index"][key]
          newOneLevelDataset[key]["mean"] = dicIndexByLevel?.mean
          newOneLevelDataset[key]["cv"] = dicIndexByLevel?.cv
          newOneLevelDataset[key]["sd"] = dicIndexByLevel?.sd
          newOneLevelDataset[key]["meanPI"] = dicIndexByLevel?.meanPI
          newOneLevelDataset[key]["sdpi"] = dicIndexByLevel?.sdpi
          newOneLevelDataset[key]["cvpi"] = dicIndexByLevel?.cvpi
        })
        setOneLeveDataset(newOneLevelDataset)
      }
    }
    setDataset(newDataset)
    if (onChangeFilterChart) {
      onChangeFilterChart(showType)
    }
  }, [levels, levelsChartOption, showUnvalidatedPoint, datapoints, selected])

  const createObjFixLine = (mean, sd) => {
    let cloneXAxis = Math.min(mean - 4 * sd, -3 * mean)
    let objFixLine = {
      mean: {
        name: `Mean`,
        type: "line",
        value: mean,
        symbol: "none",
        lineStyle: { color: "black", width: 1 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "Mean",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels ? `Mean  ${mean.toFixed(1)}` : `Mean  `
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
      _3sd: {
        name: `+3 SD`,
        type: "line",
        value: mean + 3 * sd,
        symbol: "none",
        lineStyle: { color: "red", type: "solid", width: 2 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "+3 SD",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels
                    ? `+3SD  ${(mean + 3 * sd).toFixed(1)}`
                    : `+3SD `
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
      __3sd: {
        name: `-3 SD`,
        type: "line",
        value: mean - 3 * sd,
        symbol: "none",
        lineStyle: { color: "red", type: "solid", width: 2 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "-3 SD",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels
                    ? `-3SD  ${(mean - 3 * sd).toFixed(1)}`
                    : `-3SD `
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
      clone: {
        name: ``,
        type: "line",
        value: cloneXAxis,
        symbol: "none",
        lineStyle: { color: "black", type: "solid", width: 2 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels ? `` : ``
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
      _1sd: {
        name: `+1 SD`,
        type: "line",
        value: mean + sd,
        symbol: "none",
        lineStyle: { color: "black", type: "dashed", width: 1 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "+1 SD",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels ? `+1SD  ${(mean + sd).toFixed(1)}` : `+1SD `
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
      __1sd: {
        name: `-1 SD`,
        type: "line",
        value: mean - sd,
        symbol: "none",
        lineStyle: { color: "black", type: "dashed", width: 1 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "-1 SD",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels ? `-1SD  ${(mean - sd).toFixed(1)}` : `-1SD `
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
      _2sd: {
        name: `+2 SD`,
        type: "line",
        value: mean + 2 * sd,
        symbol: "none",
        lineStyle: { color: "black", type: "dotted", width: 1 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "+2 SD",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels
                    ? `+2SD  ${(mean + 2 * sd).toFixed(1)}`
                    : `+2SD `
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
      __2sd: {
        name: `-2 SD`,
        type: "line",
        value: mean - 2 * sd,
        symbol: "none",
        lineStyle: { color: "black", type: "dotted", width: 1 },
        markPoint: {
          symbol: "rect",
          symbolSize: 1,
          data: [
            {
              type: "max",
              name: "-2 SD",
              label: {
                show: true,
                position: "left",
                formatter: () => {
                  return levels
                    ? `-2SD  ${(mean - 2 * sd).toFixed(1)}`
                    : `-2SD `
                },
                fontSize: 14,
                color: "black",
              },
            },
          ],
        },
      },
    }
    return objFixLine
  }

  const createChartData = (dataset, type) => {
    let allDataPoints = []
    let allFixedLines = []
    let datachart = []
    if (
      dataset?.mean == null ||
      dataset?.sd == null ||
      (type == "NSX" && (dataset?.meanPI == null || dataset?.sdpi == null))
    ) {
      return null
    }
    let mean = Number(dataset.mean)
    let sd = Number(dataset.sd)
    let cv = Number(dataset.cv)
    if (type === "NSX") {
      mean = Number(dataset.meanPI)
      sd = Number(dataset.sdpi)
      cv = Number(dataset.cvpi)
    }

    let maxDataLength = 0
    let dateArr = []
    let objFixLine = createObjFixLine(mean, sd)
    if (!isNaN(mean) && !isNaN(sd)) {
      if (sd != 0) {
        allFixedLines.push(
          objFixLine._1sd,
          objFixLine._2sd,
          objFixLine._3sd,
          objFixLine.__1sd,
          objFixLine.__2sd,
          objFixLine.__3sd,
          objFixLine.mean,
          objFixLine.clone
        )
      } else {
        allFixedLines.push(objFixLine.mean, objFixLine.clone)
      }
    }

    Object.keys(dataset).forEach((key) => {
      if (["mean", "cv", "sd", "meanPI", "sdpi", "cvpi"].includes(key)) return
      let entry = dataset[key]
      if (!Array.isArray(entry)) {
        console.warn(`Skipping key "${key}" because it is not an array.`)
        return
      }
      for (let i = 0; i < entry.length; i++) {
        entry[i]["insName"] = mapMachineItems.get(entry[i]["insId"])
      }

      datachart = entry
        .map((item, index) => ({
          ...item,
          sd: sd,
          mean: mean,
          status: item.status,
          value: Number(item.result),
          isFirstLot: index === 0 || item.lotId !== entry[index - 1]?.lotId,
        }))
        .filter(
          item =>
            item.value !== null &&
            !isNaN(item.value) &&
            mapMachineItems.has(item.insId)
        )

      if (datachart.length > maxDataLength) {
        maxDataLength = datachart.length
        for (let i = 0; i < entry.length; i++) {
          let time = convertDayMonth(entry[i].runTime)
          dateArr.push(time)
        }
      }

      dateArr.sort()
      let color = "blue"
      if (key == 2) {
        color = "green"
      } else if (key == 3) {
        color = "orange"
      }
      datachart = datachart.map(item => {
        let symbolPoint = "circle"
        let colorPoint = "blue"
        if (item.status === "WAR") {
          symbolPoint = "triangle"
          colorPoint = "orange"
        } else if (item.status === "REJ") {
          symbolPoint = "rect"
          colorPoint = "red"
        }
        return {
          ...item,
          symbol: symbolPoint,
          symbolSize: 10,
          itemStyle: {
            color: colorPoint,
          },
        }
      })
      allDataPoints.push({
        name: `${key} Data`,
        type: "line",
        data: datachart,
        lineStyle: { color: color, width: 2 },
      })
    })

    let labels = new Array()
    let dateLength = dateArr.length != 1 ? dateArr.length : 2
    labels = Array.from({ length: dateLength }, (_, idx) => dateArr[idx] || "")

    allDataPoints.forEach(dataSet => {
      if (dataSet.data.length < maxDataLength) {
        let padding = Array(maxDataLength - dataSet.data.length).fill(null)
        dataSet.data = [...dataSet.data, ...padding]
      }
    })
    let dynamicDatasets = allDataPoints.map((point, index) => ({
      ...point,
      name: point.name,
      type: point.type,
      data: point.data,
      markLine: {
        data: point.data
          .map((item, position) => {
            if (
              item?.isFirstLot &&
              item?.value >= mean + 3 * sd &&
              item?.value <= mean - 3 * sd
            ) {
              return { xAxis: `Point ${position}` }
            } else {
              return null
            }
          })
          .filter(item => !!item),
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#FFF",
            fontSize: 13,
            fontFamily: "Google Sans",
          },
          appendToBody: true,
          formatter: () => {
            let pointInfo = point.data[index]
            if (pointInfo.status !== "REJ") {
              return `${t("Test Name")}: ${pointInfo.testName}<br/>
                  ${t("Machine")}: ${pointInfo.insName}<br/>
                  ${t("Level")}: ${pointInfo.levels}<br/>
                  ${t("Lot")}: ${pointInfo.lotName}<br/>
                  ${t("Result")}: ${pointInfo.value || "N/A"}<br/>
                  ${t("Unit")}: ${pointInfo.unit || "N/A"}<br/>
                  ${t("Mean")}: ${pointInfo.mean || "N/A"}<br/>
                  ${t("SD")}: ${pointInfo.sd || "N/A"}<br/>
                  ${t("GHKS")}: ${
                pointInfo.mean -
                  2 * pointInfo.sd +
                  " - " +
                  pointInfo.mean +
                  2 * pointInfo.sd || "N/A"
              }<br/>
                  ${t("6Sigma")}: ${pointInfo._6Sigma || "N/A"}<br/>
                  ${t("Run Time")}: ${
                pointInfo.runTime
                  ? convertDateTimeFormat_VN(pointInfo.runTime)
                  : ""
              }<br/>
                  ${t("Validated Time")}: ${
                pointInfo.validatedTime
                  ? convertDateTimeFormat_VN(pointInfo.validatedTime)
                  : ""
              }
                  ${t("Validated By")}: ${pointInfo.validatedBy || ""}<br/>`
            } else {
              return `${t("Test Code")}: ${pointInfo.testCode} - ${
                pointInfo.testName
              }<br/>
                    ${t("Lot")}: ${pointInfo.lotName}<br/>
                    ${t("Result")}: ${pointInfo.value || "N/A"}<br/>
                    ${t("Mean")}: ${pointInfo.mean || "N/A"} - ${t("SD")}: ${
                pointInfo.sd || "N/A"
              }
                    ${t("Run Time")}: ${
                pointInfo.runTime
                  ? convertDateTimeFormat_VN(pointInfo.runTime)
                  : ""
              }<br/>
                    ${t("Validated Time")}: ${
                pointInfo.validatedTime
                  ? convertDateTimeFormat_VN(pointInfo.validatedTime)
                  : ""
              }  - ${t("Validated By")}: ${pointInfo.validatedBy || ""}<br/>
                    ${t("Violated Rules")}: ${
                pointInfo.singleRules || "N/A"
              }<br/>`
            }
          },
        },
        label: { show: false },
        symbol: "none",
        lineStyle: {
          color: "black",
          type: "solid",
        },
      },
    }))

    let fixedDatasets = allFixedLines.map(line => ({
      ...line,
      name: line.name,
      type: line.type,
      data: Array(labels.length).fill(line.value),
    }))

    return {
      labels,
      datasets: [...fixedDatasets, ...dynamicDatasets],
    }
  }

  const formatter = (option, timeOption, chartData) => {
    if (timeOption === "ByRun") {
      option.xAxis.axisLabel.formatter = function (value, index) {
        if (index === 0 || value !== chartData?.labels[index - 1]) {
          return value
        } else {
          return ""
        }
      }
    } else {
      option.xAxis.axisLabel.formatter = function (value, index) {
        return value
      }
    }
  }

  const mapOption = (option, chartData) => {
    option.legend.data = chartData?.labels || []
    option.series = chartData?.datasets || []
    option.xAxis.data = chartData?.labels || []
    return option
  }

  const resetChart = (refer, option) => {
    if (refer.current !== null) {
      let chartInstance = refer?.current?.getEchartsInstance()
      if (chartInstance !== undefined) {
        chartInstance.clear()
        chartInstance.setOption(option)
      }
    }
  }

  useEffect(() => {
    let newOptions = { ...options }
    if (levelsChartOption !== "one") {
      if (
        Object.keys(dataset).length > 0 &&
        (dataset.sd > 0 || dataset.mean > 0)
      ) {
        const chartData = createChartData(dataset, "")
        mapOption(newOptions, chartData)
        formatter(newOptions, timeOption, chartData)
        resetChart(ref, newOptions)
        setOptions(newOptions)
        if (showBothCharts === true) {
          let newOptionsNSX = { ...options }
          const chartDataNSX = createChartData(dataset, "NSX")
          mapOption(newOptionsNSX, chartDataNSX)
          formatter(newOptionsNSX, timeOption, chartDataNSX)
          resetChart(refNSX, newOptionsNSX)
          setOptionsNSX(newOptionsNSX)
        }
      } else {
        newOptions.legend.data = []
        newOptions.series = []
        newOptions.xAxis.data = []
        setOptions(newOptions)
        setOptionsNSX(newOptions)
      }
    } else {
      let setLevel = new Set()
      
      if (levels !== "default" || levels != null) {
        if (Object.keys(oneLevelDataset).length > 0) {
          Object.keys(oneLevelDataset).forEach((key) => {
            const oneLevelChartData = createChartData(oneLevelDataset[key])
              setLevel.add(key)
              mapOption(newOptions, oneLevelChartData)
              formatter(newOptions, timeOption, oneLevelChartData)
              if (key == 1) {
                resetChart(ref1, newOptions)
                setOptions1(newOptions)
              }
              if (key == 2) {
                resetChart(ref2, newOptions)
                setOptions2(newOptions)
              }
              if (key == 3) {
                resetChart(ref3, newOptions)
                setOptions3(newOptions)
              }
              if (key == 4) {
                resetChart(ref4, newOptions)
                setOptions4(newOptions)
              }
            }
          )
        }
      }
      console.log("setKey",setLevel)
      newOptions.legend.data = []
      newOptions.series = []
      newOptions.xAxis.data = []
      if(!setLevel.has(1)){
        setOptions1(newOptions)
      }
      if(!setLevel.has(2)){
        setOptions2(newOptions)
      }
      if(!setLevel.has(3)){
        setOptions3(newOptions)
      }
      if(!setLevel.has(4)){
        setOptions4(newOptions)
      }
    }
  }, [dataset, levelsChartOption, timeOption, showBothCharts, machineItems])

  if (!datapoints || Object.keys(dataset).length === 0) {
    return <div>No data available to display the chart.</div>
  }

  return (
    <div style={{ overflowY: "auto" }}>
      <div className="mt-2" style={{ width: "100%" }}>
        {showBothCharts && levelsChartOption !== "one" && options.series.length > 0 ? (
          <>
            <h4 className="mb-0" style={{ textAlign: "center" }}>
              PXN
            </h4>
            <ReactECharts
              option={options}
              ref={ref}
              style={{ width: "100%" }}
              theme="roma"
            />
          </>
        ) : null}
  
        {showBothCharts && levelsChartOption !== "one" && optionsNSX?.series?.length > 0 ? (
          <>
            <h4 className="mb-0" style={{ textAlign: "center" }}>
              NSX
            </h4>
            <ReactECharts
              option={optionsNSX}
              ref={refNSX}
              style={{ width: "100%" }}
              theme="roma"
            />
          </>
        ) : null}
  
        {!showBothCharts &&
        levelsChartOption !== "one" &&
        options.series.length > 0 ? (
          <>
            <h4 className="mb-0" style={{ textAlign: "center" }}>
              PXN
            </h4>
            <ReactECharts
              option={options}
              ref={ref}
              style={{ width: "100%" }}
              theme="roma"
            />
          </>
        ) : null}

        {levelsChartOption === "one" &&
        Object.keys(oneLevelDataset).length > 0 &&
        (levels === "default" || levels === null) ? (
          <>
            <h4 className="mb-0" style={{ textAlign: "center" }}>
              PXN - {t("Level")} 1
            </h4>
            <ReactECharts
              option={options1}
              ref={ref1}
              style={{ width: "100%" }}
              theme="roma"
            />
            <h4 className="mb-0" style={{ textAlign: "center" }}>
              PXN - {t("Level")} 2
            </h4>
            <ReactECharts
              option={options2}
              ref={ref2}
              style={{ width: "100%" }}
              theme="roma"
            />
            <h4 className="mb-0" style={{ textAlign: "center" }}>
              PXN - {t("Level")} 3
            </h4>
            <ReactECharts
              option={options3}
              ref={ref3}
              style={{ width: "100%" }}
              theme="roma"
            />
            <h4 className="mb-0" style={{ textAlign: "center" }}>
              PXN - {t("Level")} 4
            </h4>
            <ReactECharts
              option={options4}
              ref={ref4}
              style={{ width: "100%" }}
              theme="roma"
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

TabChart.propTypes = {
  showBothCharts: PropTypes.bool,
  showUnvalidatedPoint: PropTypes.bool,
  levelsChartOption: PropTypes.string,
  levels: PropTypes.string,
  datapoints: PropTypes.object,
  loadingDatapoints: PropTypes.bool,
  error: PropTypes.string,
}

const mapStateToProps = ({ qcDatapoints }) => ({
  datapoints: qcDatapoints.datapoints,
  loadingDatapoints: qcDatapoints.loadingDatapoints,
  error: qcDatapoints.error,
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "common"])(TabChart)))
