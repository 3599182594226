import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"


const TabOptional = ({
    t
}) => {
    return (
        <div className="p-2">
            <AvForm>
                <div className="mb-3">
                    <AvRadioGroup
                        name='chart'
                        defaultValue='Levey Jennings'
                    >
                        <AvRadio label={t("Levey Jennings Chart")} value='Levey Jennings' />
                    </AvRadioGroup>
                </div>
            </AvForm >
        </div>
    )
}

export default withTranslation(["chartiQCPage", "common"])(TabOptional)
