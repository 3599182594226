import PropTypes from "prop-types"
//i18n
import { CustomNav, CustomNavLink, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, TabContent, TabPane } from "reactstrap"
import TabHistory from "./TabHistory"
import TabCharts from "./TabCharts"
import Header from "./headerFilter"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom/cjs/react-router-dom"

const RESOURCE = ModuleIds.ChartiQC

const BodyMid = ({ t, selected, machineItems, onChangeFilterChart, lstLotId }) => {
  const titleTag = t(`Chart iQC`)
  const [currentTab, setCurrentTab] = useState("1")
  const [showBothCharts, setShowBothCharts] = useState(false)
  const [levels, setLevels] = useState(null)
  const [showUnvalidatedPoint, setShowUnvalidatedPoint] = useState(false)
  const [timeOption, setTimeOption] = useState('ByTime')
  const [levelsChartOption, setLevelsChartOption] = useState("")
  return (
    <div className="mt-2">
      <Header
        t={t}
        lstLotId={lstLotId}
        setShowBothCharts={setShowBothCharts}
        setLevelsChartOption={setLevelsChartOption}
        setLevels={setLevels}
        setShowUnvalidatedPoint={setShowUnvalidatedPoint}
        setTimeOption={setTimeOption}
      />
      <Card>
        <CardBody className="p-0">
          <CustomNav
            onToggle={e => {
              setCurrentTab(e)
            }}
            defaultTab={"1"}
            tabs
            className="nav-tabs-custom p-0"
            tabContents={customActiveTab => (
              <TabContent
                activeTab={customActiveTab}
                className="text-muted p-0"
              >
                <TabPane tabId="1">
                  {currentTab == "1" && (
                    <TabCharts
                      onChangeFilterChart={onChangeFilterChart}
                      showBothCharts={showBothCharts}
                      levelsChartOption={levelsChartOption}
                      levels={levels}
                      selected={selected}
                      showUnvalidatedPoint={showUnvalidatedPoint}
                      timeOption={timeOption}
                      machineItems={machineItems}
                    />
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {currentTab == "2" && <TabHistory />}
                </TabPane>
              </TabContent>
            )}
          >
            <CustomNavLink tabId="1">
              <span>{t("Chart")}</span>
            </CustomNavLink>
            <CustomNavLink tabId="2">
              <span>{t("History")}</span>
            </CustomNavLink>
          </CustomNav>
        </CardBody>
      </Card>
    </div>
  )
}

BodyMid.propTypes = {
  t: PropTypes.any,
  lstLotId: PropTypes.any,
  datapoints: PropTypes.any,
}

const mapStateToProps = ({ qcDatapoints }) => ({
  lstLotId: qcDatapoints.lstLotId.lstLotId,
  datapoints: qcDatapoints.datapoints,
})
export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation(["chartiQCPage", "message", "common"])(BodyMid)))
