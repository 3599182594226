import {
  GET_QC_DATAPOINTS_FAIL,
  GET_QC_DATAPOINTS_SUCCESS,
  GET_LOT_FOR_CHART_FAIL,
  GET_LOT_FOR_CHART_SUCCESS,
  GET_RESULT_BY_SEQ_SUCCESS,
  GET_RESULT_BY_SEQ_FAIL
} from "./actionTypes"

const INIT_STATE = {
  datapoints: {},
  lstLotId: {},
  resultBySeq: {},
  loadingDatapoints: false,
  error: null,
}

const qcDatapoints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QC_DATAPOINTS_SUCCESS:
      return {
          ...state,
          datapoints: action.payload,
          updateNotifyTime: new Date(),
          loadingDatapoints: false
      }
    case GET_QC_DATAPOINTS_FAIL:
      return {
          ...state,
      }
    
    case GET_LOT_FOR_CHART_SUCCESS:
      return {
          ...state,
          lstLotId: action.payload,
          updateNotifyTime: new Date(),
          loadingDatapoints: false
      }
    case GET_LOT_FOR_CHART_FAIL:
      return {
          ...state,
      }
    
    case GET_RESULT_BY_SEQ_SUCCESS:
      return {
          ...state,
          resultBySeq: action.payload,
          updateNotifyTime: new Date(),
          loadingDatapoints: false
      }
      
    case GET_RESULT_BY_SEQ_FAIL:
      return {
          ...state,
      }

  default:
      return state
  }
}

export default qcDatapoints
