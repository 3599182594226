import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const ConfigLotQCButtons = ({ model, onChange, onSubmit, t }) => {
  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex">
          <div style={{width : '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="instrumentId"
              portal
              placeholder={t("Machines")}
              value={model.instrumentId || ""}
              code={parameterCode.INSTRUMENT}
              onChange={(name, value, label, item) => {
                onChange({
                  instrumentId: value[0],
                })
                setTimeout(() => {
                  onSubmit({ ...model, instrumentId: value[0] || "" })
                }, 100)
              }}
            />
          </div>
          <div style={{ marginLeft: "5px" }}>
            <CustomDatePicker
              conjunction=" - "
              name="requestDate"
              label={""}
              checkSameTime={true}
              dateFormat={"d-m-Y"}
              value={[convertDateFormat(model.start || new Date(), "DD-MM-YYYY"), convertDateFormat(model.end || new Date(), "DD-MM-YYYY")]}
              closeOnSelect={true}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  onSubmit && onSubmit()
                }
              }}
              onChangeHandler={date => {
                if (date.time.length == 2) {
                  onChange({
                    start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                    end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                  })
                  setTimeout(() => {
                    onSubmit({
                      ...model,
                      start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                      end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                    })
                  }, 100)
                } else {
                  onChange({
                    start: new Date(),
                    end: new Date(),
                  })
                }
              }}
              maxDate={new Date()}
              // minDate={minDate}
              mode="range"
            />
          </div>
          </div>
      </AvForm>
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({})
const mapDispatchToProps = dispatch => ({})

ConfigLotQCButtons.displayName = "ConfigLotQCButtons"
export default withTranslation(["managementQCPage", "common", "button"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ConfigLotQCButtons
  )
)
