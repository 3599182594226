import { Check, CustomBootstrapTable } from "components/Common"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const RESOURCE = ModuleIds.ChartiQC

const TabResult = ({
    dicResultBySeq,
    t,
}) => {
    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
        setDataSource(dicResultBySeq)
    }, [dicResultBySeq])
    
    const columns = [
        {
            dataField: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            formatter: (cellContent, item, index) => {
                return index + 1
            }
        },
        {
            dataField: "runTime",
            text: t("Date"),
        },
        {
            dataField: "res1",
            text: t("Level") + ' 1',
        },
        {
            dataField: "err1",
            text: t("Violated Error"),
        },
        {
            dataField: "res2",
            text: t("Level") + ' 2'
        },
        {
            dataField: "err2",
            text: t("Violated Error"),
        },
        {
            dataField: "res3",
            text: t("Level") + ' 3'
        },
        {
            dataField: "err3",
            text: t("Violated Error"),
        }
    ]

    return (
        <div className="">
            <CustomBootstrapTable
                columns={columns}
                data={dataSource}
                keyField="id"
                isEnableRefresh={false}
                resource={RESOURCE}
                showSelectRow={false}
                isHover={false}
                isEnableExport={false}
            />
        </div>
    )
}

TabResult.displayName = 'TabResult';
export default withTranslation(["chartiQCPage", "common", "message"], { withRef: true })(connect(
    null,
    null
)(TabResult))
