import BadgeLabel from "components/Common/BadgeLabel"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds } from "constant"
import {
  convertDateFormat,
  indexCalculator,
  selectCheckboxHandler,
} from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ConfigLotQCButtons from "./ConfigLotQCButtons"
import FilterForm from "./FilterForm"
import HistoryLotTestModal from "./Modals/HistoryLotTestModal"
import ImportExportButton from "../ImportExportForm/index"

const RESOURCE = ModuleIds.ManagementQC

let pageGlobal = 0
let dataGlobal = []
let rowsGlobal = []
const ConfigLotQCTable = ({
  lotQCs,
  onSort,
  onSearch,
  onRefresh,
  itemActive,
  updatedTime,
  onSubmitFilter,
  t,
  loadinglotQC,
  onEdit,
  onPageChange,
  paging,
  onSizePerPageChange,
  onChangeModel,
  isSelected,
  onDelete,
  onSelect,
  onSelectAll,
  onExportQCLot
}) => {
  const [dataSource, setDataSource] = useState([])
  const [page, setPage] = useState(0)
  const [row, setRow] = useState({})
  const [rows, setRows] = useState([])
  const [modalHistory, setModalHistory] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [model, setModel] = useState({
    search: "",
    testCategory: "",
    lotGroup: "",
    userId: "",
  })
  useEffect(() => {
    onChangeModel && onChangeModel(model)
  }, [model])
  const onResetHandler = () => {
    const initModel = {
      search: "",
      testCategory: "",
      lotGroup: "",
      userId: "",
    }
    setModel(initModel)
  }
  useEffect(() => {
    setDataSource(lotQCs)
  }, [lotQCs])
  const [elEnd, setElEnd] = useState(
    document.getElementById(`watch_end_of_document`)
  )
  useEffect(() => {
    setDataSource(lotQCs)
  }, [dataSource])

  useEffect(() => {
    rowsGlobal = rows
  }, [rows])

  let columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "",
      text: "#",
      classes: "stt-col-sticky",
      style: {
        borderLeft: "1px solid #F0F2F7",
        minWidth: 60,
        maxWidth: 60,
        with: 60,
      },
      headerStyle: {
        borderLeft: "1px solid #F0F2F7",
        minWidth: 60,
        maxWidth: 60,
        with: 60,
      },
      headerClasses: "stt-col-header-sticky",
      formatter: (cellContent, item, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "expireDate",
      text: t("Expire Date"),
      style: { minWidth: 110 },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "lotId",
      text: t("Lot ID"),
      style: {
        minWidth: 80,
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "lotName",
      text: t("Lot Name"),
      style: {
        minWidth: 210,
        color: "#3C9DEC",
        fontWeight: "bold",
        fontSize: 14,
      },
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, item, index) => {
        return (
          <label
            key={index}
            data-tip
            data-for={`lotName-${index}`}
            style={{
              color: "#5F5F5F",
              backgroundColor: "#fff",
              padding: "1px 5px",
              marginRight: "5px",
              borderRadius: "5px",
              border: `1px solid #808080`,
              minWidth: 40,
              textAlign: "center",
            }}
          >
            {cellContent}
          </label>
        )
      },
    },
    {
      dataField: "lotGroupName",
      text: t("Lot Group"),
      style: {
        minWidth: 210,
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "machineName",
      text: t("Machines"),
      style: {
        minWidth: 150,
      },
      align: "center",
      headerAlign: "center",
      formatter: cellContent => {
        const machineNames = cellContent?.split(",") || []

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {machineNames.map((name, index) => (
              <div
                key={`machine-name-${index}`}
                style={{ marginBottom: 4, fontSize: 15, color: "#333" }}
              >
                {name.trim()}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      dataField: "testCategoryName",
      text: t("Test Category"),
      style: {
        minWidth: 150,
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "levels",
      text: t("Levels"),
      style: {
        minWidth: 130,
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "noOfPointPI",
      text: t("Number of PI Points"),
      style: {
        minWidth: 130,
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "noOfPointCal",
      text: t("Number of Calibration Points"),
      style: {
        minWidth: 180,
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "manufactor",
      text: t("Manufactor"),
      style: {
        minWidth: "200px",
        color: "white",
      },
      headerStyle: { minWidth: "200px" },
      formatter: (cellContent, item, index) => {
        return (
          <>
            {cellContent && (
              <BadgeLabel
                value={cellContent}
                type={"rev"}
                className="badge-width"
                dataFor={cellContent}
              />
            )}
          </>
        )
      },
    },
    {
      dataField: "noOfRun",
      text: t("Number of Runs"),
      style: { minWidth: "150px" },
      headerStyle: { minWidth: "150px" },
      style: { fontWeight: "bold" },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "maxDayOpen",
      text: t("Max Days Open"),
      style: { minWidth: "150px" },
      headerStyle: { minWidth: "150px" },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "maxOpenDate",
      text: t("Open Date"),
      style: { minWidth: "180px" },
      headerStyle: { minWidth: "180px" },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "status",
      text: t("Status"),
      style: { minWidth: "220px", textAlign: "center" },
      headerStyle: { minWidth: "220px", textAlign: "center" },
      formatter: (cellContent, item) => {
        const now = new Date();
        const openDate = item.maxOpenDate ? new Date(item.maxOpenDate) : null;
        const expireDate = item.expireDate ? new Date(item.expireDate) : null;

        const isOpenExpired = openDate && openDate < now;
        const isExpireExpired = expireDate && expireDate < now;

        let status = "";
        let color = "#28a745"; // Màu xanh mặc định

        if (!isOpenExpired && !isExpireExpired) {
          status = "Còn hạn mở nắp / Còn hạn sử dụng";
          color = "#28a745"; // Xanh lá
        } else if (!isOpenExpired && isExpireExpired) {
          status = "Còn hạn mở nắp / Hết hạn sử dụng";
          color = "#ffc107"; // Vàng
        } else if (isOpenExpired && !isExpireExpired) {
          status = "Hết hạn mở nắp / Còn hạn sử dụng";
          color = "#17a2b8"; // Xanh dương
        } else {
          status = "Hết hạn mở nắp / Hết hạn sử dụng";
          color = "#dc3545"; // Đỏ
        }

        return <span style={{ color, fontWeight: "bold" }}>{status}</span>;
      },
    },
    {
      dataField: "createdByName",
      text: t("User Created"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{cellContent}</span>
      },
    },
    {
      dataField: "createdDate",
      text: t("Date Created"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "updatedByName",
      text: t("User Updated"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{cellContent}</span>
      },
    },
    {
      dataField: "updatedDate",
      text: t("Date Updated"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "action",
      text: "",
      classes: "status-col-sticky",
      headerClasses: "status-col-header-sticky",
      style: {
        width: "50px",
        margin: 0,
        paddingLeft: "0px",
        paddingRight: "0px",
        maxWidth: 50,
        minWidth: 50,
        textAlign: "center",
      },
      headerStyle: {
        width: "50px",
        margin: 0,
        paddingLeft: "0px",
        paddingRight: "0px",
        maxWidth: 50,
        minWidth: 50,
        textAlign: "center",
      },
      formatter: (cellContent, item, index) => (
        <div className="d-flex align-items-center ml-2">
          <span
            style={{ marginRight: 5 }}
            onClick={() => {
              setSelectedRow(item)
              setModalHistory(true)
            }}
          >
            <i className="fas fa-history font-size-16 me-1" />
          </span>
          <span onClick={e => onEdit(e, item.id, item)}>
            <i className="mdi mdi-pencil-outline font-size-16 text-primary me-1" />
          </span>
          <span onClick={e => onDelete(e, item)}>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </span>
        </div>
      ),
    },
  ]

  useEffect(() => {
    let el = document.getElementById("resizeMeAsync")
    if (el) {
      el.scrollIntoView(true)
      setTimeout(() => {
        setPage(0)
        pageGlobal = 0
      }, 100)
    }
  }, [itemActive])

  const io = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return
      }
      pageGlobal++
      setPage(pageGlobal)
    })
  })

  useEffect(() => {
    if (elEnd) {
      io.observe(elEnd)
    }
  }, [elEnd])


  return (
    <React.Fragment>
      <div className="test-request-table-toolbar">
        <CustomBootstrapTableAsync
          updatedTime={updatedTime}
          columns={columns}
          search
          loading={loadinglotQC}
          onReset={onResetHandler}
          data={dataSource}
          onSort={onSort}
          keyField="id"
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          searchText={model.search}
          onSearch={onSearch}
          resource={RESOURCE}
          onRefresh={onRefresh}
          isSelected={isSelected}
          filterForm={() => <FilterForm model={model} />}
          onSubmitFilter={onSubmitFilter}
          placeholder={t("Search by Test Code, Name")}
          isEnableExport={false}
          paging={paging}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          importExportForm={() => <ImportExportButton onExportQCLot={onExportQCLot} />}
          customButtonTable={() => (
            <ConfigLotQCButtons
              t={t}
              model={model}
              onChange={val => {
                setModel({ ...model, ...val })
              }}
              onSubmit={val => {
                onSubmitFilter(val)
              }}
            />
          )}
        />
      </div>
      <HistoryLotTestModal
        modal={modalHistory}
        toggle={() => {
          setModalHistory(prev => !prev)
        }}
        data={selectedRow || {}}
      //   config={config}
      />
    </React.Fragment>
  )
}

const mapStateToProps = ({ lotQCs }) => ({
  loadinglotQC: lotQCs.loadinglotQC,
})

const mapDispatchToProps = dispatch => ({})

ConfigLotQCTable.displayName = "ConfigLotQCTable"
export default withTranslation(["managementQCPage", "common", "message"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ConfigLotQCTable
  )
)
