import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"

import {
    ADD_NEW_CAUSAL_ACTION_QC,
    DELETE_CAUSAL_ACTION_QC,
    GET_CAUSAL_ACTION_QC_DETAIL,
    GET_FULL_CAUSAL_ACTION_QC,
    UPDATE_CAUSAL_ACTION_QC
} from "./actionTypes"

import {
    addCausalActionQCFail,
    addCausalActionQCSuccess,
    deleteCausalActionQCsFail,
    deleteCausalActionQCsSuccess,
    getCausalActionQCDetailFail,
    getCausalActionQCDetailSuccess,
    getFullCausalActionQCFail,
    getFullCausalActionQCSuccess,
    setCausalActionQCSearchQuery,
    updateCausalActionQCFail,
    updateCausalActionQCSuccess
} from "./actions"


import {
    createCausalActionQC,
    deleteCausalActionQCByIds,
    getCausalActionQCById,
    getCausalActionQCList,
    updateCausalActionQCById
} from "helpers/app-backend/IQC/QC_Causal_Action_backend_helper"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchCausalActionQC({ payload }) {
    try {
        const response = yield call(getCausalActionQCById, payload)
        yield put(getCausalActionQCDetailSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getCausalActionQCDetailFail(error))
    }
}

function* onUpdateCausalActionQC({ payload, callback }) {
    try {
        const response = yield call(updateCausalActionQCById, payload)
        yield put(updateCausalActionQCSuccess(response))

        const message = payload.category == 1 ? 'Causal' : 'Corrective Action'
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "causalActionQCPage:" + message
                )} <span class='text-decoration-underline fw-bold'>${payload["name"]
                    }</span>`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateCausalActionQCFail(error))
    }
}

function* onDeleteCausalActionQC({ payload: { causalActionQCs, callback } }) {
    try {
        const response = yield call(deleteCausalActionQCByIds, causalActionQCs)
        yield put(deleteCausalActionQCsSuccess(causalActionQCs))

        const message = payload.category == 1 ? 'Causal' : 'Corrective Action'
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("causalActionQCPage:" + message)}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(deleteCausalActionQCsFail(error))
    }
}

function* onAddNewCausalActionQC({ payload, callback }) {
    try {
        const response = yield call(createCausalActionQC, payload)
        yield put(addCausalActionQCSuccess(response))

        const message = payload.category == 1 ? 'Causal' : 'Corrective Action'
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "causalActionQCPage:" + message
                )} <span class='text-decoration-underline fw-bold'>${payload["name"]
                    }</span>`,
            })}`
        )
        if (callback) {
            callback()
        }
    } catch (error) {
        console.log(error)
        yield put(addCausalActionQCFail(error))
    }
}


function* fetchFullCausalActionQC({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.causalActionQC.searchQuery;
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getCausalActionQCList, payload)

        yield put(getFullCausalActionQCSuccess(response))

        yield put(setCausalActionQCSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getFullCausalActionQCFail(error))
    }
}

function* causalActionQCsSaga() {
    // yield takeEvery(GET_WESTGARD_SIGMA_DETAIL, fetchCausalActionQCDetail)
    yield takeEvery(GET_CAUSAL_ACTION_QC_DETAIL, fetchCausalActionQC)
    yield takeEvery(ADD_NEW_CAUSAL_ACTION_QC, onAddNewCausalActionQC)
    yield takeEvery(UPDATE_CAUSAL_ACTION_QC, onUpdateCausalActionQC)
    yield takeEvery(DELETE_CAUSAL_ACTION_QC, onDeleteCausalActionQC)
    yield takeEvery(GET_FULL_CAUSAL_ACTION_QC, fetchFullCausalActionQC)
}

export default causalActionQCsSaga