import {
    GET_LOT_TEST_QC_LIST,
    GET_LOT_TEST_QC_LIST_FAIL,
    GET_LOT_TEST_QC_LIST_SUCCESS,
    RESET_LOT_TEST_QC_SEARCH_QUERY,
    SET_LOT_TEST_QC_SEARCH_QUERY,
    UPDATE_APPLY_SIGMA_TE_CALCULATION_QC,
    UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_QC_FAIL,
    UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_SUCCESS,
    UPDATE_CV_PEER_QC,
    UPDATE_LOT_TEST_CALCULATION_QC,
    UPDATE_LOT_TEST_CALCULATION_QC_FAIL,
    UPDATE_LOT_TEST_CALCULATION_QC_SUCCESS,
    UPDATE_MEAN_PEER_QC
} from "./actionTypes"

export const resetLottestQCSearchQuery = () => ({
    type: RESET_LOT_TEST_QC_SEARCH_QUERY,
})

export const setLottestQCSearchQuery = searchQuery => ({
    type: SET_LOT_TEST_QC_SEARCH_QUERY,
    payload: searchQuery,
})

export const getLottestListQC = (payload, callback) => {
    return {
        type: GET_LOT_TEST_QC_LIST,
        payload: payload || {},
        callback
    }
}
export const getLottestListQCSuccess = payload => ({
    type: GET_LOT_TEST_QC_LIST_SUCCESS,
    payload: payload,
})

export const getLottestListQCFail = error => ({
    type: GET_LOT_TEST_QC_LIST_FAIL,
    payload: error,
})

export const updateLottestCalculationQC = (lottestCalculation, callback) => ({
    type: UPDATE_LOT_TEST_CALCULATION_QC,
    payload: lottestCalculation,
    callback
})

export const updateLottestCalculationQCSuccess = lottestCalculation => ({
    type: UPDATE_LOT_TEST_CALCULATION_QC_SUCCESS,
    payload: lottestCalculation
})

export const updateLottestCalculationQCFail = error => ({
    type: UPDATE_LOT_TEST_CALCULATION_QC_FAIL,
    payload: error
})

export const updateApplySigmaTeCalculationQC = (lottestCalculation, callback) => ({
    type: UPDATE_APPLY_SIGMA_TE_CALCULATION_QC,
    payload: lottestCalculation,
    callback
})

export const updateApplySigmaTeCalculationQCSuccess = lottestCalculation => ({
    type: UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_SUCCESS,
    payload: lottestCalculation
})

export const updateApplySigmaTeCalculationQCFail = error => ({
    type: UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_QC_FAIL,
    payload: error
})

export const updateMeanPeer = (mean) => ({
    type: UPDATE_MEAN_PEER_QC,
    payload: mean,
})

export const updateCvPeer = (cv) => ({
    type: UPDATE_CV_PEER_QC,
    payload: cv,
})
