
import { ModalBody, ModalFooter } from "reactstrap"

import {
    CustomButton,
    CustomModal
} from "components/Common"

import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import InputCalculateInfo from "./InputCalculateInfo"
import { updateMeanPeer } from "store/actions"

const AddMeanPeerInfoModal = ({
    modal,
    toggle,
    data,
    t,
    onInputInfo,
    onMeanPeerUpdate,
    machine
}) => {

    const [means, setMeans] = useState(data || [])

    useEffect(() => {
        if (data) {
            setMeans(data)
        }
    }, [data])

    const styleTd = (isLast) => {
        if (isLast) {
            return {
                minHeight: '56px',
            }
        } else {
            return {
                borderBottom: '2px solid #f0f2f7',
                minHeight: '56px',
            }
        }

    }

    const columns = [
        {
            dataField: "id",
            text: "",
            hidden: true,
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            style: { width: "50px", verticalAlign: 'top' },
            formatter: (cellContent, item, index) => {
                return index + 1
            },
        },
        {
            dataField: "insId",
            text: t("common:Instrument"),
            style: { width: "150px", verticalAlign: 'top' },
            formatter: (cellContent, item, index) => {
                return <div className="d-flex flex-column ">
                    {machine.label}
                </div>
            },
        },
        {
            dataField: "testCode",
            text: t("Test Code"),
            style: { width: "150px", verticalAlign: 'top' },
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            style: { width: "430px", verticalAlign: 'top' },
        },
        {
            dataField: "levels",
            text: t("Level"),
            style: { width: "100px", padding: "0px" },
            formatter: (cellContent, item, index) => {
                return <div className="d-flex flex-column">
                    {item?.lottests?.map((x, position) => (
                        <div key={position} className="p-2" style={styleTd(item.lottests.length == position + 1)}>
                            {x.levels}
                        </div>
                    ))}
                </div>
            },
        },
        {
            dataField: "mean",
            text: t("Mean"),
            style: { width: "150px", padding: "0px" },
            formatter: (cellContent, item, position) => {
                return <div className="d-flex flex-column">
                    {item?.lottests?.map((x, index) => (
                        <div key={index} className="p-2" style={styleTd(item.lottests.length == index + 1)}>
                            <InputCalculateInfo
                                data={x}
                                index={index}
                                defaultValue={data.mean}
                                onChange={(value) => {
                                    means[position].lottests[index].mean = parseFloat(value)
                                    setMeans([...means])

                                    let newMeanPeer = []
                                    means.forEach(item => { newMeanPeer = [...newMeanPeer, ...item.lottests] })
                                    onMeanPeerUpdate([...newMeanPeer])
                                }}
                            />
                        </div>
                    ))}
                </div>
            },
        }
    ]


    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add Mean Peer Info")} onToggle={toggle} size="xl">
                <ModalBody>
                    <div className="calculation-table">
                        <CustomBootstrapTableAsync
                            columns={columns}
                            showSelectRow={false}
                            paging={{
                                dataSize: means.length
                            }}
                            data={means}
                            keyField="id"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Save")}
                        type="buttom"
                        onClick={() => {
                            setMeans([...means])
                            toggle()
                            onInputInfo([...means])
                        }}
                        color="primary"
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

AddMeanPeerInfoModal.defaultProps = {

}

const mapDispatchToProps = dispatch => ({
    onMeanPeerUpdate: (data) => (dispatch(updateMeanPeer(data)))
})

export default connect(
    null,
    mapDispatchToProps
)(withTranslation(["calculationQCPage", "common"])(AddMeanPeerInfoModal))
