import { CustomBootstrapTable } from "components/Common";
import { CustomTooltipButton } from "components/Common/Button";
import IconStatus from "components/Common/IconStatus";
import { ModuleIds } from "constant";
import { indexCalculator } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ImportExportButton from "./ImportExportForm";

const RESOURCE = ModuleIds.WestgardSigmaQC

const WestgardSigmaQCTable = ({
    onSelect,
    onSelectAll,
    westgardSigmaQCs,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    onClone,
    loading,
    t,
    updatedTime
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        type: "",
        category: "",
        sampleType: "",
    });
    const searchQuery = useSelector(state => state.westgardSigmaQC.searchQuery);

    const onResetHandler = () => {
        const initModel = {
            search: "",
            type: ""
        }
        let x = document.getElementsByName("type")
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        if (westgardSigmaQCs) {
            let tmp = westgardSigmaQCs || []
            setData(tmp)
        }
    }, [westgardSigmaQCs])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            formatter: (cellContent, westgardSigmaQC, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "#",
            style: { width: 50 },
            formatter: (cellContent, westgardSigmaQC, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "code",
            text: t("Code"),
            sort: true,
        },
        {
            dataField: "name",
            text: t("Name"),
            style: { maxWidth: "300px" },
            sort: true,
        },
        {
            dataField: "warningRuleName",
            text: t("Warning Rules"),
        },
        {
            dataField: "rejectRuleName",
            text: t("Reject Rules"),

        },
        {
            dataField: "inUse",
            text: t("common:Status"),
            style: { minWidth: "85px" },
            align: 'center',
            headerAlign: 'center',
            sort: true,
            formatter: (cellContent, westgardSigmaQC, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent ? "Active" : "Inactive"}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            style: { width: 50 },
            formatter: (cellContent, param, index) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <CustomTooltipButton
                            index={index}
                            component={param}
                            onClone={onClone}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            t={t}
                            resource={RESOURCE}
                        />
                    </div >

                )
            },
        },
    ]

    return (
        <div>
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                isSelected
                onRefresh={onRefresh}
                // filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                isImportExportButton={true}
                importExportForm={() => <ImportExportButton model={model} />}
            />

        </div>
    )
}

export default withTranslation(["westgardSigmaQCPage"])(WestgardSigmaQCTable)