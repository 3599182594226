import {
  Button
} from "reactstrap"

// import pdf libs
import "jspdf-autotable"

// import excel libs
import ReactExport from "react-data-export"
import { forEach } from "lodash"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const CustomExportExcel = ({
  data, columnExport,
  config = {
    fileName: 'test',
    font: "Times New Roman",
    fontSize: "12",
    fontSizeHeader: "12"
  }
}) => {

  const checkStyleData = () => {
    if (data && data.length > 0 && (config.font || config.fontSize)) {
      let newData = data;
      newData.forEach(item => {
        item.forEach(element => {
          element.style = { font: { sz: config.fontSize, name: config.font } }
        });
      });
      return newData
    }
    return data || []
  }

  const checkStyleColumn = () => {
    if (columnExport && columnExport.length > 0 && (config.font || config.fontSizeHeader)) {
      let newData = columnExport;
      newData.forEach(element => {
        element.style = { font: { sz: config.fontSizeHeader, name: config.font, bold: true} }
      });
      return newData
    }
    return columnExport || []
  }

  let multiDataSet = [
    {
      columns: checkStyleColumn(),
      data: checkStyleData()
    }
  ];

  //data mẫu
  //   const multiDataSet = [
  //     {
  //         columns: [
  //             {title: "Headings", width: {wpx: 80}},//pixels width 
  //             {title: "Text Style", width: {wch: 40}},//char width 
  //             {title: "Colors", width: {wpx: 90}},
  //         ],
  //         data: [
  //             [
  //                 {value: "H1", style: {font: {sz: "24", bold: true}},alignment: {wrapText: true, horizontal: 'center', vertical: 'top'}},
  //                 {value: "Bold", style: {font: {bold: true}}},
  //                 {value: "Red", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}},
  //             ],
  //             [
  //                 {value: "H2", style: {font: {sz: "18", bold: true}}},
  //                 {value: "underline", style: {font: {underline: true}}},
  //                 {value: "Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FF0000FF"}}}},
  //             ],
  //             [
  //                 {value: "H3", style: {font: {sz: "14", bold: true}}},
  //                 {value: "italic", style: {font: {italic: true}}},
  //                 {value: "Green", style: {fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}}},
  //             ],
  //             [
  //                 {value: "H4", style: {font: {sz: "12", bold: true}}},
  //                 {value: "strike", style: {font: {strike: true}}},
  //                 {value: "Orange", style: {fill: {patternType: "solid", fgColor: {rgb: "FFF86B00"}}}},
  //             ],
  //             [
  //                 {value: "H5", style: {font: {sz: "10.5", bold: true}}},
  //                 {value: "outline", style: {font: {outline: true}}},
  //                 {value: "Yellow", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}}},
  //             ],
  //             [
  //                 {value: "H6", style: {font: {sz: "7.5", bold: true}}},
  //                 {value: "shadow", style: {font: {shadow: true}}},
  //                 {value: "Light Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
  //             ]
  //         ]
  //     }
  // ];

  return (
    <ExcelFile filename={config.fileName} element={<Button color="secondary" outline><i className="fas fa-file-export"></i></Button>}>
      <ExcelSheet dataSet={multiDataSet} name="Test" />
    </ExcelFile>
  )
}

export default CustomExportExcel
