import { CustomButton, FilterButton } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds, parameterCode, QCResultState, QCResultStatus } from "constant"
import { convertDateFormat, getI18nextLng, selectCheckboxHandler } from "helpers/utilities"
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import FilterLeftCustom from "./Filter/FilterLeftCustom"
import ConfigModal from "./Filter/ConfigModal"
import { AvForm } from "availity-reactstrap-validation"
import FilterForm from "./Filter/FilterForm"
import HistoryQCResultModal from "./Modal/HistoryQCResultModal"
import ResultQCReportModal from "./Modal/Report"
import { getCodesByParameterId } from "helpers/app-backend"
const RESOURCE = ModuleIds.ResultIQC

let itemActiveGlobal = {}
let pageGlobal = 0
let dataGlobal = []
let rowsGlobal = []
let ignoreFetchFirstTime = true;
const ResultIQC = forwardRef(
    ({
        data,
        onSort,
        onRefresh,
        itemActive,
        updatedTime,
        t,
        loading,
        onChangeConfig,
        onChangeModel,
        onChangeTableConent,
        onChangeCancelResultSelected,
        onDeleteRow,
        onCancelResultRow,
        onChangeSelectedRows
    }, ref) => {
        const size = 30;
        let lang = getI18nextLng()
        const [dataSource, setDataSource] = useState([])
        const [page, setPage] = useState(0)
        const [row, setRow] = useState({})
        const [rows, setRows] = useState([])
        const [rowDeletes, setRowDeletes] = useState([])
        const [modalHistory, setModalHistory] = useState(false)
        const [modalReport, setModalReport] = useState(false)
        const [selectedRow, setSelectedRow] = useState({})
        const [config, setConfig] = useState({ isAddManual: false })
        const [model, setModel] = useState({
            runTime: new Date(),
            start: convertDateFormat(new Date(), "YYYY-MM-DD"),
            end: convertDateFormat(new Date(), "YYYY-MM-DD"),
        })
        const [dataRuleSelected, setDataRuleSelected] = useState({})
        const [dataColorRule, setDataColorRule] = useState([])
        const [dataColorState, setDataColorState] = useState([])
        const [dataColorStatus, setDataColorStatus] = useState([])
        const refFrormLeft = useRef()

        useEffect(() => {
            loadColor()
        }, [])

        useEffect(() => {
            onChangeSelectedRows(rows)
        }, [rows])

        useEffect(() => {
        }, [rowDeletes])

        const loadColor = async () => {
            const query = { lang }
            let colorRules = await getCodesByParameterId(parameterCode.QC_RESULT_RULE_RESULT_COLOR, query)
            colorRules?.map(_item => {
                _item.value = _item.code
                _item.label = _item.message
                return _item
            })
            setDataColorRule(colorRules || [])
            let colorState = await getCodesByParameterId(parameterCode.QC_RESULT_STATE_RESULT_COLOR, query)
            colorState?.map(_item => {
                _item.value = _item.code
                _item.label = _item.message
                return _item
            })
            setDataColorState(colorState || [])
            let colorStatus = await getCodesByParameterId(parameterCode.QC_RESULT_STATUS_RESULT_COLOR, query)
            colorStatus?.map(_item => {
                _item.value = _item.code
                _item.label = _item.message
                return _item
            })
            setDataColorStatus(colorStatus || [])
        }

        useImperativeHandle(ref, () => ({
            onGetData: () => {
                return {
                    data: dataGlobal.filter(x => rowsGlobal.map(z => z.id).includes(x.id)) || [],
                    deletes: rowDeletes || []
                }
            },
            onGetDataNew: () => {
                return {
                    data: dataGlobal.filter(x => x.isNew == true && ((x.result && x.result != "") || (x.resultText && x.resultText != ""))) || [],
                    deletes: rowDeletes || []
                }
            },
            onCheckForm: () => {
                if (Object.keys(refFrormLeft.current?.state?.invalidInputs).length > 0) {
                    refFrormLeft.current?.submit()
                    return false
                }
                return true
            },
            onGetSubmitIds: () => {
                return getDataSubmit()
            },
            onGetValidIds: () => {
                return getValidIds()
            },
            onGetCancelValidIds: () => {
                return getCancelValidIds()
            },
            onGetCancelResultIds: () => {
                return getCancelResultIds()
            },
            onGetDelResultIds: () => {
                return getCancelResultIds()
            }
        }))

        const getDataSubmit = () => {
            let dataSelecteds = dataGlobal.filter(x => rowsGlobal.map(z => z.id).includes(x.id) && x.isNew != true) || [];
            if (dataSelecteds.length > 0) {
                let res = dataSelecteds || [];
                return res
            }
            else {
                let res = dataGlobal.filter(x => x.isNew != true) || [];
                return res
            }
        }

        const getValidIds = () => {
            let dataSelecteds = dataGlobal.filter(x => rowsGlobal.map(z => z.id).includes(x.id) && x.isNew != true) || [];
            if (dataSelecteds.length > 0) {//có check
                let res = dataSelecteds.filter(x => x.state == '' || x.state == null || x.status == QCResultStatus.Warning) || [];
                return res.map(x => x.id)
            }
            else {//không check
                let res = dataGlobal.filter(x => x.isNew != true &&
                    (
                        (x.state == '' || x.state == null)
                        && (x.status == QCResultStatus.Warning || x.status == QCResultStatus.Accept)
                    )
                ) || [];
                return res.map(x => x.id)
            }
        }

        const getCancelValidIds = () => {
            let dataSelecteds = dataGlobal.filter(x => rowsGlobal.map(z => z.id).includes(x.id) && x.isNew != true) || [];
            if (dataSelecteds.length > 0) {
                let res = dataSelecteds.filter(x => x.valState == QCResultState.Valid) || [];
                return res.map(x => x.id)
            }
            else {
                return []
            }
        }

        useEffect(() => {
            onChangeConfig(config)
        }, [config])

        useEffect(() => {
            if (ignoreFetchFirstTime) {
                ignoreFetchFirstTime = false
            }
            else {
                onChangeModel(model)
            }
        }, [model])


        useEffect(() => {
            setRow([])
            setRowDeletes([])
            setDataSource(data || [])
            setTimeout(() => {
                if (elEnd == undefined || elEnd == null) {
                    setElEnd(document.getElementById(`watch_end_of_document`))
                }
            }, 100);
        }, [data])
        const [elEnd, setElEnd] = useState(document.getElementById(`watch_end_of_document`))
        // let elEnd = document.getElementById(`watch_end_of_document`)
        useEffect(() => {
            dataGlobal = dataSource
            setRows([])
            setRow({})
        }, [dataSource])

        useEffect(() => {
            rowsGlobal = rows
            onChangeCancelResultSelected(getCancelResultIds())
        }, [rows])

        const getCancelResultIds = () => {
            let dataSelecteds = rowsGlobal.filter(x => x.isNew != true) || [];
            if (dataSelecteds.length > 0) {//có check
                let res = dataSelecteds.filter(x => (x.state == '' || x.state == null || x.status == QCResultStatus.Warning) && x.isDeleted != true) || [];
                return res.map(x => x.id)
            }
            else {
                return []
            }
        }

        const checkAndTab = (e, idHTML, key) => {
            if (e.key === 'Enter' || e.key === "Tab") {
                const fields =
                    Array.from(document.getElementsByClassName(key)) ||
                    []
                const position = fields.findIndex(x => x.id == `${idHTML}`)
                if (position >= 0) {
                    setTimeout(() => {
                        let el = fields[position + 1]
                        if (el) {
                            const tmpClick = $(`#${el.id} form a`)
                            if (tmpClick && tmpClick.length > 0)
                                tmpClick[0].click()
                        }
                    }, 0);

                }
            }
        }

        const getColorRule = (key) => {
            let color = dataColorRule.find(x => x.value == key)
            return color?.message || '#000'
        }

        const getColorStatus = (key) => {
            let color = dataColorStatus.find(x => x.value == key)
            return color?.message || '#000'
        }
        const getColorState = (key) => {
            let color = dataColorState.find(x => x.value == key)
            return color?.message || '#000'
        }
        const arrNum = ['mean', 'sd', 'deCOdd', 'meanPI', 'sdpi', 'meanPeer', 'tea', '_6Sigma']
        const renderEditField = (item, key) => {
            const idHTML = `qcLotTestBlurItemFocus${item.id}-${key}`
            return <React.Fragment>
                <div
                    className={"qcLotTestBlurItemFocus"}
                    id={idHTML}
                    onKeyDown={e => {
                        checkAndTab(e, idHTML, 'qcLotTestBlurItemFocus')
                    }}
                    style={{ width: '100%', margin: "0 auto" }}
                    onClick={e => {
                        e.stopPropagation()
                    }}
                    onBlur={() => {
                        $(`#${item.id}-${key}`).click()
                    }}
                >
                    <Editable
                        alwaysEditing={false}
                        disabled={false}
                        editText={(item[key] != null && item[key] != '') ? `${item[key]}` : "---"}
                        initialValue={(item[key] != null && item[key] != '') ? `${item[key]}` : ""}
                        id={item[key]}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        showText={false}
                        type="textfield"
                        renderConfirmElement={confirmElement(`${item.id}-${key}`)}
                        renderCancelElement={cancelElement}
                        onSubmit={(value) => {
                            if (arrNum.includes(key)) { //xử lý số
                                if (Number(value) || value == '' || value == '0') {
                                    if (item[key] != value && !(item[key] == null && value == ''))
                                        onSubmitRule(item, value, [key])
                                }
                            }
                            else {
                                if (item[key] != value && !(item[key] == null && value == ''))
                                    onSubmitRule(item, value, [key])
                            }
                        }}
                        validate={(value) => {
                            if (arrNum.includes(key))
                                if (!Number(value) && value != '' && value != '0') {
                                    return t("Numbers only");
                                }
                        }}
                    />
                </div>
            </React.Fragment>
        }

        const onDelete = (item) => {
            if (item.isNew == true) {
                //thêm row delete
                let dels = rowDeletes || []
                if (!dels.includes(x => x.id == item.id)) {
                    dels.push(item)
                    setRowDeletes(dels);
                }

                //update data source
                let tmp = dataGlobal;
                tmp = tmp.filter(x => x.id !== item.id)
                setDataSource([...tmp])

                //update data selected nếu có
                let tmpSelected = rowsGlobal;
                tmpSelected = tmpSelected.filter(x => x.id !== item.id)
                setRows([...tmpSelected])
            }
            else {
                onDeleteRow([item.id])
            }
        }


        let columns = [
            {
                dataField: "",
                text: "#",
                classes: 'stt-col-sticky',
                style: { borderLeft: '1px solid #F0F2F7', minWidth: 60, maxWidth: 60, with: 60 },
                headerStyle: { borderLeft: '1px solid #F0F2F7', minWidth: 60, maxWidth: 60, with: 60 },
                headerClasses: 'stt-col-header-sticky',
                formatter: (cellContent, item, index) => {
                    if (item.id == -1)
                        return <span id={`watch_end_of_document`}>End</span>
                    else
                        return index + 1
                }
            },
            {
                dataField: "lotCode",
                text: t("Lot Code"),
                classes: 'lotCode-col-sticky row-span',
                headerClasses: 'lotCode-col-header-sticky',
                style: { minWidth: 100, maxWidth: 100, with: 100 },
                headerStyle: { minWidth: 100, maxWidth: 100, with: 100 },
                formatter: (cellContent, item, index) => {
                    return <span>{item.lotName}</span>
                },
                // attrs: (cell, row, rowIndex, colIndex) => {
                //     return row.id == -1 ? {} : { "rowSpan": row.rowSpan ? row.rowSpan : "0" }
                // },
                // style: (cell, row, rowIndex, colIndex) => {
                //     if (row.rowSpan && row.rowSpan > 0) {
                //         return { minWidth: 100, maxWidth: 100, with: 100 }
                //     }
                //     else {
                //         return row.id == -1 ? {} : { display: 'none' }
                //     }
                // }
            },
            {
                dataField: "testCode",
                text: t("Test Code"),
                classes: 'testCode-col-sticky row-span',
                headerClasses: 'testCode-col-header-sticky',
                style: { minWidth: 100, maxWidth: 100, with: 100 },
                headerStyle: { minWidth: 100, maxWidth: 100, with: 100 },
                // attrs: (cell, row, rowIndex, colIndex) => {
                //     return row.id == -1 ? {} : { "rowSpan": row.rowSpan ? row.rowSpan : "0" }
                // },
                // style: (cell, row, rowIndex, colIndex) => {
                //     if (row.rowSpan && row.rowSpan > 0) {
                //         return { minWidth: 100, maxWidth: 100, with: 100 }
                //     }
                //     else {
                //         return row.id == -1 ? {} : { display: 'none' }
                //     }
                // }
            },
            {
                dataField: "machineName",
                text: t("Machine"),
                style: { minWidth: 100, maxWidth: 100 },
                headerStyle: { whiteSpace: 'nowrap', minWidth: 100, maxWidth: 100 },
                classes: 'machine-col-sticky row-span',
                headerClasses: 'machine-col-header-sticky',
                // attrs: (cell, row, rowIndex, colIndex) => {
                //     return row.id == -1 ? {} : { "rowSpan": row.rowSpan ? row.rowSpan : "0" }
                // },
                // style: (cell, row, rowIndex, colIndex) => {
                //     if (row.rowSpan && row.rowSpan > 0) {
                //         return { minWidth: 100, maxWidth: 100, with: 100 }
                //     }
                //     else {
                //         return row.id == -1 ? {} : { display: 'none' }
                //     }
                // }
            },
            {
                dataField: "testName",
                text: t("Test Name"),
                classes: 'testName-col-sticky row-span',
                headerClasses: 'testName-col-header-sticky',
                style: { minWidth: 250, maxWidth: 250, with: 250 },
                headerStyle: { minWidth: 250, maxWidth: 250, with: 250 },
                // attrs: (cell, row, rowIndex, colIndex) => {
                //     return row.id == -1 ? {} : { "rowSpan": row.rowSpan ? row.rowSpan : "0" }
                // },
                // style: (cell, row, rowIndex, colIndex) => {
                //     if (row.rowSpan && row.rowSpan > 0) {
                //         return { minWidth: 250, maxWidth: 250, with: 250 }
                //     }
                //     else {
                //         return row.id == -1 ? {} : { display: 'none' }
                //     }
                // }
            },
            {
                dataField: "levels",
                text: t("Levels"),
                style: { minWidth: 80, maxWidth: 80 },
            },
            {
                dataField: "result",
                text: t("Result"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 150, maxWidth: 150 },
                formatter: (cellContent, item, index) => {
                    if (item.isDeleted == true || item.valState == QCResultState.Valid || item.valState == QCResultState.REF
                        || item.valState == QCResultState.DREW || item.valState == QCResultState.NOT_DRAW || item.isNew != true
                    )
                        return <span>{cellContent}</span>
                    if ((item.isDeleted == false || item.isDeleted == null) && (item.valState == '' || item.valState == null))
                        return renderEditField(item, 'result')
                    else
                        return <span>{cellContent}</span>
                },
            },
            {
                dataField: "resultText",
                text: t("Result Text"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 150, maxWidth: 150 },
                formatter: (cellContent, item, index) => {
                    if (item.isDeleted == true || item.valState == QCResultState.Valid || item.valState == QCResultState.REF
                        || item.valState == QCResultState.DREW || item.valState == QCResultState.NOT_DRAW || item.isNew != true
                    )
                        return <span>{cellContent}</span>
                    if ((item.isDeleted == false || item.isDeleted == null) && item.isPosNeg == true && (item.valState == '' || item.valState == null))
                        return renderEditField(item, 'resultText')
                    else
                        return <span>{cellContent}</span>
                },
            },

            {
                dataField: "mean",
                text: t("Mean"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 100, maxWidth: 100 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return <span>{cellContent}</span>
                },
            },
            {
                dataField: "sd",
                text: t("SD"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 100, maxWidth: 100 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return <span>{cellContent}</span>
                },
            },
            {
                dataField: "valStateName",
                text: t("State Valid"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 100, maxWidth: 100 },
                formatter: (cellContent, item, index) => {
                    return <span style={{ color: getColorState(item.valState) }}>{cellContent}</span>
                },
            },
            {
                dataField: "statusName",
                text: t("Status"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 100, maxWidth: 100 },
                formatter: (cellContent, item, index) => {
                    return <span style={{ color: getColorStatus(item.status) }}>{cellContent}</span>
                },
            },
            {
                dataField: "userValidName",
                text: t("User Valid"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 100, maxWidth: 100 },
                // formatter: (cellContent, item, index) => {
                //     return renderEditField(item, 'temperature')
                // },
            },
            {
                dataField: "validatedDate",
                text: t("Date Valid"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 135, maxWidth: 135 },
                formatter: (cellContent, item, index) => {
                    return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
                },
            },
            {
                dataField: "_6Sigma",
                text: t("6 Sigma"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                formatter: (cellContent, item, index) => {
                    return <span>{cellContent}</span>
                },
            },
            {
                dataField: "singleRules",
                text: t("Rule"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 150, maxWidth: 150 },
                formatter: (cellContent, item, index) => {
                    if (item.isDeleted == false || item.isDeleted == null)
                        return (
                            <>
                                {item.rules && item.rules.length > 0 &&
                                    <div style={{
                                        display: 'flex',
                                        gap: '2px',
                                        flexWrap: 'wrap'
                                    }}>
                                        {item.rules?.map((row, index) => <div key={index}>
                                            <label
                                                style={{
                                                    color: `${getColorRule(row.color)}`,
                                                    marginRight: "5px",
                                                    cursor: 'pointer',
                                                    whiteSpace: 'nowrap',
                                                    backgroundColor: "#fff",
                                                    padding: "1px 5px",
                                                    borderRadius: "5px",
                                                    border: "1px solid #808080",
                                                    width: 'auto'
                                                }}
                                                onClick={() => {
                                                    setDataRuleSelected({ ...item, rule: row.ruleName })
                                                    setModalReport(true)
                                                }}
                                            >
                                                {row.ruleName}
                                            </label>
                                        </div>)}
                                    </div>
                                }
                            </>
                        )
                    else
                        return <span></span>
                },
            },
            {
                dataField: "note",
                text: t("Note"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 150, maxWidth: 150 },
                formatter: (cellContent, item, index) => {
                    if (item.isDeleted == true || item.valState == QCResultState.Valid || item.valState == QCResultState.REF
                        || item.valState == QCResultState.DREW || item.valState == QCResultState.NOT_DRAW
                    )
                        return <span>{cellContent}</span>
                    if ((item.isDeleted == false || item.isDeleted == null) && (item.valState == '' || item.valState == null))
                        return renderEditField(item, 'note')
                    else
                        return <span>{cellContent}</span>
                },
            },
            {
                dataField: "isPosNeg",
                text: t("IsPosNeg"),
                headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
                style: { minWidth: 80, maxWidth: 80, textAlign: 'center' },
                formatter: (cellContent, item, index) => {
                    return <span>{item.isPosNeg == true && <i className="fa fa-check-circle text-success"></i>}</span>
                },
            },
            {
                dataField: "isManual",
                text: t("IsManual"),
                headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
                style: { minWidth: 80, maxWidth: 80, textAlign: 'center' },
                formatter: (cellContent, item, index) => {
                    return <span>{item.isManual == true && <i className="fa fa-check-circle text-success"></i>}</span>
                },
            },
            {
                dataField: "runSeq",
                text: t("Number Of Run"),
                headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
                style: { minWidth: 80, maxWidth: 80, textAlign: 'center' },
                formatter: (cellContent, item, index) => {
                    return <span>{item.isDeleted != true && cellContent}</span>
                },
            },
            {
                dataField: "runTime",
                text: t("Run Time"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 135, maxWidth: 135 },
                formatter: (cellContent, item, index) => {
                    return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
                },
            },
            {
                dataField: "action",
                text: "",
                classes: 'status-col-sticky status-col-sticky-action-result-qc',
                headerClasses: 'status-col-header-sticky',
                style: { width: 80, margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 80, minWidth: 80, textAlign: 'center' },
                headerStyle: { width: 80, margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 80, minWidth: 80, textAlign: 'center' },
                formatter: (cellContent, item, index) => (
                    <div className="d-flex justify-content-center">
                        {item.isNew != true &&
                            <>
                                {item.isNew != true ?
                                    <div className="tooltip-custom-action-column">
                                        <div className="tooltip-custom">
                                            <span style={{ marginRight: 5, alignSelf: 'center', cursor: 'pointer' }} onClick={() => {
                                                setSelectedRow(item)
                                                setModalHistory(true)
                                            }}>
                                                <i className="fas fa-history font-size-14 me-1" />
                                            </span>
                                            <span className="tooltiptext-custom-button-icon-left">
                                                {t("History")}
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    <span style={{ marginRight: 5, alignSelf: 'center', visibility: 'hidden' }} >
                                        <i className="fas fa-history font-size-14 me-1" />
                                    </span>
                                }
                                {item.isDeleted != true && item.isOutQC != true ?
                                    <div className="tooltip-custom-action-column">
                                        <div className="tooltip-custom">
                                            <span style={{ marginRight: 5, alignSelf: 'center', cursor: 'pointer' }}
                                                onClick={() => {
                                                    onCancelResultRow([item.id])
                                                }}>
                                                <i className="fas fa-times-circle font-size-14 me-1" />
                                            </span>
                                            <span className="tooltiptext-custom-button-icon-left">
                                                {t("Cancel Result")}
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    <span style={{ marginRight: 5, alignSelf: 'center', visibility: 'hidden' }}>
                                        <i className="fas fa-times-circle font-size-14 me-1" />
                                    </span>
                                }
                            </>
                        }
                        {item.isDeleted != true ?
                            <div className="tooltip-custom-action-column">
                                <div className="tooltip-custom">
                                    <span style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            onDelete(item)
                                        }}>
                                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                                    </span>
                                    <span className="tooltiptext-custom-button-icon-left">
                                        {t("Del Result")}
                                    </span>
                                </div>
                            </div>
                            :
                            <span style={{ cursor: 'pointer', visibility: 'hidden' }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                            </span>
                        }
                    </div>
                ),
            },
        ]

        const confirmElement = id => (
            <button
                id={id}
                type="submit"
                className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
            >
                <i className="mdi mdi-check"></i>
            </button>
        )

        /** Cancel button */
        const cancelElement = (
            <button
                type="button"
                className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
            >
                <i className="mdi mdi-close"></i>
            </button>
        )

        const onSubmitRule = async (item, value, name) => {
            item[name] = `${value}`
            const dataReplace = dataGlobal.find(obj => obj.id === item.id)
            Object.assign(dataReplace, item)
            onChangeTableConent(false)
            setDataSource([...dataGlobal])
            //updateResultQCResult({ id: item.id, result: item.result })
        }


        useEffect(() => {
            itemActiveGlobal = itemActive
            //reset table
            let el = document.getElementById("resizeMeAsync")
            if (el) {
                el.scrollIntoView(true)
                setTimeout(() => {
                    setPage(0)
                    pageGlobal = 0
                }, 100);
            }
        }, [itemActive])

        const io = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                }
                pageGlobal++;
                setPage(pageGlobal)
            })
        })

        useEffect(() => {
            if (elEnd) {
                io.observe(elEnd);
            }
        }, [elEnd])

        const getDataDisplay = () => {
            if (dataSource?.length > 0) {
                let res = [...dataSource].slice(0, size * (page + 1))
                res.push({ id: -1, testCode: '' })
                return res
            }
            return []
        }

        const onSelectCheckbox = (row, isSelected) => {
            const { rowsState, currentRow } = selectCheckboxHandler(
                rows,
                row,
                isSelected
            )
            setRows(rowsState)
            setRow(currentRow)
        }

        const onSelectAllCheckbox = (rows, isSelected) => {
            if (isSelected !== undefined) {
                if (rows.length < 1) {
                    setRow({})
                    setRows([])
                }
                else {
                    setRows(dataSource)
                    setRow(dataSource[dataSource.length - 1])
                }
            }
        }

        const onMouseEnter = (e, row, index) => {
            try {
                let el = document.querySelectorAll(`.table-row-result-qc-${row.lotTestId}-${row.machineId}-${row.testCode?.replaceAll('.', '\\.')} .row-span`)
                if (el) {
                    el.forEach(element => {
                        element.style.backgroundColor = '#f8f9fa'
                    });

                }
            } catch (error) {
                //console.log(error);
            }
        }

        const onMouseLeave = (e, row, index) => {
            try {
                let el = document.querySelectorAll(`.table-row-result-qc-${row.lotTestId}-${row.machineId}-${row.testCode?.replaceAll('.', '\\.')} .row-span`)
                if (el) {
                    el.forEach(element => {
                        element.style.backgroundColor = '#fff'
                    });
                }
            } catch (error) {
                // console.log(error);
            }
        }

        const rowClasses = (row, index) => {
            return (row.rowSpan && row.rowSpan > 0 && row.isNew != true) ? `table-row-result-qc-${row.lotTestId}-${row.machineId}-${row.testCode}` : '';
        }

        const getDataExport = () => {
            let dt = dataSource || [];
            if (dt.length > 0) {
                return dt.filter(x => x.id != -1).map((item, index) => {
                    return {
                        '#': index + 1,
                        [`${t("Lot Code")}`]: item.lotName,
                        [`${t("Test Code")}`]: item.testCode,
                        [`${t("Machine")}`]: item.machineName,
                        [`${t("Test Name")}`]: item.testName,
                        [`${t("Levels")}`]: item.levels,
                        [`${t("Result Text")}`]: item.resultText,
                        [`${t("Mean")}`]: item.mean,
                        [`${t("SD")}`]: item.sd,
                        [`${t("State Valid")}`]: item.valStateName,
                        [`${t("Status")}`]: item.statusName,
                        [`${t("User Valid")}`]: item.userValidName,
                        [`${t("Date Valid")}`]: convertDateFormat(item.validatedDate, "HH:mm DD-MM-YYYY"),
                        [`${t("6 Sigma")}`]: item._6Sigma,
                        [`${t("Rule")}`]: item.rules?.map(z => z.ruleName).join(', '),
                        [`${t("Note")}`]: item.note,
                        [`${t("IsPosNeg")}`]: item.isPosNeg,
                        [`${t("IsManual")}`]: item.isManual,
                        [`${t("Run Time")}`]: convertDateFormat(item.runTime, "HH:mm DD-MM-YYYY")
                    }
                });
            }
            return dt
        }
        return (
            <React.Fragment>
                <div style={{ backgroundColor: 'white' }} className={`table-expand-custom result-qc-table`}>
                    <CustomBootstrapTableAsync
                        updatedTime={updatedTime}
                        columns={columns}
                        // search
                        loading={loading}
                        isEnableLoadInfo={true}
                        data={getDataDisplay()}
                        onSort={onSort}
                        keyField="id"
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        // searchText={model.search || ''}
                        // onSearch={(val) => onSearch({ search: val || '' })}
                        resource={RESOURCE}
                        // onMouseEnter={onMouseEnter}
                        // onMouseLeave={onMouseLeave}
                        isEnableRefresh={false}
                        onRefresh={onRefresh}
                        striped={false}
                        isScrollable={true}
                        showSelectRow={true}
                        bodertable={true}
                        isHover={true}
                        placeholder={t("Search by Test Code, Name")}
                        isEnableExport={true}
                        dataExport={getDataExport()}
                        rowClasses={rowClasses}
                        paging={{
                            page: page == 0 ? 1 : page,
                            dataSize: dataSource.length,
                            size: size,
                            totalPages: dataSource.length / size
                        }}
                        customButtonRight={() => (
                            <ConfigModal data={config} onChangeConfig={vals => {
                                setConfig(vals)
                            }} />
                        )}
                        selectedRows={rows.map(x => x.id) || []}
                        customButtonTable={() =>
                            <>
                                <AvForm style={{ display: 'flex', gap: '3px' }}
                                    ref={refFrormLeft}
                                >
                                    <FilterLeftCustom
                                        model={model}
                                        onChange={(vals) => {
                                            setModel({ ...model, ...vals })
                                        }} />
                                </AvForm>
                                <FilterButton
                                    disableToggle={true}
                                    onReset={() => {
                                        setModel({
                                            ...model,
                                            numberOfRun: '',
                                            userValid: '',
                                            userNameValid: ''
                                        })
                                    }}
                                    onSubmit={(e, vals) => {
                                        setModel({ ...model, ...vals })
                                    }}
                                >
                                    <FilterForm model={model} />
                                </FilterButton>
                            </>
                        }
                    />
                </div>
                <HistoryQCResultModal
                    modal={modalHistory}
                    toggle={() => { setModalHistory(prev => !prev) }}
                    data={selectedRow || {}}
                />
                {modalReport &&
                    <ResultQCReportModal
                        data={dataRuleSelected || {}}
                        modal={modalReport}
                        toggle={() => {
                            setModalReport(prev => !prev)
                            setDataRuleSelected({})
                        }}
                        onRefreshData={() => { onRefresh() }}
                    />
                }
            </React.Fragment>
        )
    })

const mapStateToProps = ({ resultQCs }) => ({
    loading: resultQCs.loading,
})

const mapDispatchToProps = dispatch => ({
    // onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload)),
    // onGetPositionScroll: payload => dispatch(getPositionScroll(payload)),
    // onUpdateNormalRangeSub: (payload, callback) => dispatch(updateNormalRangeSub(payload, callback)),
})

ResultIQC.displayName = 'ResultIQC';
export default withTranslation(["resultIQCPage", "common", "message"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(ResultIQC))
