import { CustomBootstrapTable } from "components/Common";
import { ModuleIds } from "constant";
import { convertDateFormat, getColorBookmark, indexCalculator } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import FilterForm from "./FilterForm";

const RESOURCE = ModuleIds.ChartiQC;

const TestQCTable = ({
    configs,
    onSearch,
    onRefresh,
    paging,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    onChangeModel,
    model,
    onReset,
    onSelected
}) => {
    const [testCode, setTestCode] = useState('')
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(configs)
    }, [configs])

    const activeStyle = {
        fontWeight: 'bold'
    }
    const checkActiveRow = (row) => {
        if (row.testCode === testCode) {
            return activeStyle;
        }
        return {}
    }

    let columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            headerStyle: { width: "30px" },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{indexCalculator(paging.page, paging.size, index + 1)}</span>
            },
        },
        {
            dataField: "testCode",
            text: t("Test Code"),
            style: { whiteSpace: 'nowrap' },
            headerStyle: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={{ color: "#556ee6", ...checkActiveRow(row) }}>{cellContent}</span>
            },
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            headerStyle: { whiteSpace: 'nowrap' },
            style: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
    ]

    return (
        <div className="">
            <CustomBootstrapTable
                columns={columns}
                data={data}
                style={{ height: '300px' }}
                onSearch={onSearch}
                onRefresh={onRefresh}
                paging={{ dataSize: data?.length }}
                filterForm={() => <FilterForm model={model} onChange={onChangeModel} />}
                onReset={onReset}
                onSubmitFilter={onSubmitFilter}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                showSelectRow={false}
                isEnableExport={false}
                disableToggle={true}
                TableTitle={t("ListTest")}
                onRowClick={(e, row, index) => {
                    if (data?.length > 0) {
                        onSelected(row)
                        setTestCode(row.testCode)
                        setData(data.map(x => ({ ...x, selected: x.testCode === row.testCode })))
                    }
                }}
                headerClasses={'table-light result-test-color'}
            />
        </div>
    )
}

export default withTranslation(["chartiQCPage", "common"])(TestQCTable)