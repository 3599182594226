import { TitleAndTable, WarningModal } from "components/Common"
import { ModuleIds } from "constant"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { exportLottestQCs } from "helpers/app-backend/IQC/QC_Calculation_backend_helper"
import moment from "moment"
import {
  getLottestListQC,
  getLottestListQCSuccess,
  updateApplySigmaTeCalculationQC,
  updateLottestCalculationQC,
} from "store/actions"
import CalculationSigmaTETable from "./CalculationSigmaTETable"
import HeaderButtons from "./HeaderButtons"
const RESOURCE = ModuleIds.CalculationiQC

let lotTests = []

const CalculationSigmaTE = ({
  paging,
  onGetLotTestQCList,
  onGetLottestListQCSuccess,
  loadingLottest,
  updatedTime,
  lottests,
  onUpdateApplySigmaTeCalculationQC,
  t,
}) => {
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [config, setConfig] = useState({})
  const [model, setModel] = useState({
    search: "",
  })

  useEffect(() => {
    onGetLottestListQCSuccess([])
  }, [])

  const onGetLotQCList = payload => {
    onGetLotTestQCList(payload)
  }

  const resetState = () => {
    setRows([])
  }

  const onSelectCheckbox = (row, isSelected) => {
    if (isSelected) {
      rows.push(row)
      setRows(rows)
    } else {
      const newRows = rows.filter(item => item.id != row.id)
      setRows([...newRows])
    }
  }

  const featchLotQC = () => {
    onGetLotTestQCList({
      ...model,
      page: 1,
    })
  }

  const onRefreshHandler = () => {
    resetState()
    featchLotQC()
  }

  const onSearch = searchText => {
    let val = {
      search: searchText || "",
    }
    setModel({ ...model, ...val })
    onGetLotQCList({ ...model, ...val })
  }

  const onSubmitFilter = values => {
    onGetLotQCList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetLotQCList({ page: 1, sort: sortString })
  }

  const onLottestExport = async () => {
    const fileData = await exportLottestQCs({ ...model })
    const blob = new Blob([fileData])
    saveAs(blob, "LottestCalculation.xlsx")
  }
  const submitConfig = values => {
    setConfig(values)
  }

  const onLottestChange = lottests => {
    lotTests = lottests
  }
  const onApply = () => {
    let lottestData = [];
    console.log('model', model)
    console.log('lottests', lottests)
    lottests.forEach(item => {
        lottestData = [
            ...lottestData,
            ...item.lottests.map(lotTest => {
                let formattedLotTest = {
                    id: lotTest.id || 0,
                    testCode: lotTest.testCode || '',
                    lotId: lotTest.lotId || '',
                    insId: lotTest.insId || 0,
                    levels: lotTest.levels || '',
                    method: lotTest.method || '',
                    mean: lotTest.mean || 0,
                    sd: lotTest.sd || 0,
                    cv: lotTest.cv || 0,
                    oldSD: lotTest.oldSD || 0,
                    oldMean: lotTest.oldMean || 0,
                    calNum: lotTest.calNum ?? null,
                    biasUnit: lotTest.biasUnit || '',
                    biasPercent: lotTest.biasPercent || ''
                };

                if (lotTest.TE !== null && lotTest.TE !== undefined) {
                    formattedLotTest.TE = lotTest.TE;
                }
                if (lotTest.sigma !== null && lotTest.sigma !== undefined) {
                    formattedLotTest.sigma = lotTest.sigma;
                }
                if (lotTest.QGI !== null && lotTest.QGI !== undefined) {
                    formattedLotTest.QGI = lotTest.QGI;
                }

                return formattedLotTest;
            })
        ];
    });

    const firstLotTest = lottests.length > 0 && lottests[0].lottests.length > 0 ? lottests[0] : {};

    const data = {
        ...model,
        lotId: firstLotTest.lotId || '',
        lotTests: lottestData
    };
    console.log('data', data)
    onUpdateApplySigmaTeCalculationQC(data);
};


  const onCalculate = () => {
    if (config.isCV) {
      config.formulaCodeCV = config.cvCalculation
      if (config.lotQcs) {
        config.startCV = null
        config.endCV = null
      } else {
        config.lotIds = null
        config.startCV = moment(config?.cvDate[0], "DD-MM-YYYY").format(
          "YYYY-MM-DD 00:00:00"
        )
        config.endCV = moment(config?.cvDate[1], "DD-MM-YYYY").format(
          "YYYY-MM-DD 00:00:00"
        )
      }
    }
    if (config.isBias) {
      config.formulaCodeBias = config.meanPeerCalculation
      config.startBias = moment(config?.biasDate[0], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
      config.endBias= moment(config?.biasDate[1], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
  }
    onGetLotTestQCList({ ...model, ...config })
  }

  return (
    <React.Fragment>
      <TitleAndTable
        table={() => (
          <CalculationSigmaTETable
            lottests={lottests}
            config={config}
            onSelect={onSelectCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            paging={paging}
            onSubmitFilter={onSubmitFilter}
            loading={loadingLottest}
            updatedTime={updatedTime}
            submitConfig={submitConfig}
            onLottestExport={onLottestExport}
            onLottestChange={onLottestChange}
            onChangeModel={val => {
              setModel({ ...model, ...val })
            }}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtons
            disabledApply={lottests?.length <= 0}
            resource={RESOURCE}
            onApply={onApply}
            onCalculate={onCalculate}
          />
        )}
        external
        title={t("CalculationTitle")}
        subtitle={t("Calculation List")}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />

    </React.Fragment>
  )
}

CalculationSigmaTE.propTypes = {
  lottests: PropTypes.array,
  onGetLotTestQCList: PropTypes.func,
  onUpdateLottestCalculationQC: PropTypes.func,
  onUpdateApplySigmaTeCalculationQC: PropTypes.func,
  loadingLottest: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({ calculationQC }) => ({
  lottests: calculationQC.lottests,
  loadingLottest: calculationQC.loadingLottest,
})

const mapDispatchToProps = dispatch => ({
  onGetLotTestQCList: payload => dispatch(getLottestListQC(payload)),
  onUpdateLottestCalculationQC: (payload, callback) =>
    dispatch(updateLottestCalculationQC(payload, callback)),
  onUpdateApplySigmaTeCalculationQC: (payload, callback) =>
    dispatch(updateApplySigmaTeCalculationQC(payload, callback)),
  onGetLottestListQCSuccess: payload =>
    dispatch(getLottestListQCSuccess(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["calculationQCPage", "message", "common"])(
      CalculationSigmaTE
    )
  )
)
