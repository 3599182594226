
import { ModalBody, ModalFooter } from "reactstrap"

import {
    CustomButton,
    CustomModal
} from "components/Common"

import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import InputCalculateInfo from "./InputCalculateInfo"

const AddMeanPeerInfoModal = ({
    modal,
    toggle,
    data,
    t,
    onInputInfo
}) => {

    const [meanPeer, setMeanPeer] = useState(data || [])
    useEffect(() => {
        if (data) {
            setMeanPeer(data)
        }
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "",
            hidden: true,
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            style: { width: "50px", verticalAlign: 'top' },
            formatter: (cellContent, item, index) => {
                return index + 1
            },
        },
        {
            dataField: "insId",
            text: t("common:Instrument"),
            style: { width: "100px", verticalAlign: 'top' },
            formatter: (cellContent, item, index) => {
                return <div className="d-flex flex-column gap-3">
                    {item?.lottests?.map((x, position) => (
                        <div key={position}>
                            {x.insId}
                        </div>
                    ))}
                </div>
            },
        },
        {
            dataField: "testCode",
            text: t("Test Code"),
            style: { width: "150px", verticalAlign: 'top' },
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            style: { width: "430px", verticalAlign: 'top' },
        },
        {
            dataField: "levels",
            text: t("Level"),
            style: { width: "100px", padding: "0px", paddingTop: '10px' },
            formatter: (cellContent, item, index) => {
                return <div className="d-flex flex-column gap-3">
                    {item?.lottests?.map((x, position) => (
                        <div key={position}>
                            {x.levels}
                        </div>
                    ))}
                </div>
            },
        },
        {
            dataField: "meanPeer",
            text: t("Mean Peer"),
            formatter: (cellContent, item, position) => {
                return <div className="d-flex flex-column gap-3">
                    {item?.lottests?.map((x, index) => (
                        <div key={index}>
                            <InputCalculateInfo
                                data={x}
                                index={index}
                                defaultValue={data.meanPeer}
                                onChange={(value) => {
                                    meanPeer[position].lottests[index].meanPeer = value
                                    setMeanPeer([...meanPeer])
                                }}
                            />
                        </div>
                    ))}
                </div>
            },
        }
    ]


    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add Mean Peer Info")} onToggle={toggle} size="xl">
                <ModalBody>
                    <div className="calculation-table">
                        <CustomBootstrapTableAsync
                            columns={columns}
                            showSelectRow={false}
                            paging={{
                                dataSize: meanPeer.length
                            }}
                            data={meanPeer}
                            keyField="id"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Save")}
                        type="buttom"
                        onClick={() => {
                            setMeanPeer([...meanPeer])
                            toggle()
                            onInputInfo([...meanPeer])
                        }}
                        color="primary"
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

AddMeanPeerInfoModal.defaultProps = {

}

const mapDispatchToProps = dispatch => ({

})

export default connect(
    null,
    mapDispatchToProps
)(withTranslation(["calculationQCPage", "common"])(AddMeanPeerInfoModal))
