import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import React, { useRef, useState } from "react"
import { withTranslation } from "react-i18next"

const CalculationSigmaTEButtons = ({
  model,
  onChange,
  onSubmit,
  t,
  loading
}) => {

  const [searchText, setSearchText] = useState('')
  const [instrumentId, setInstrumentId] = useState(null)
  const ref = useRef()

  const onSubmitHandler = (e, value) => {
    value.search = searchText
    onSubmit({ ...value })
  }

  const onRefreshHandler = () => {
    ref.current.submit()
  }

  return (
    <React.Fragment>
      <AvForm
        ref={ref}
        onValidSubmit={(e, v) => {
          onSubmitHandler(e, v)
        }}>
        <div className="calculate-index-filter d-flex">
          <div style={{ width: '230px' }}>
            <CustomSelectAsync
              name="testCode"
              portal
              placeholder={t("Test Code")}
              value={model.testCode || ""}
              code={parameterCode.TEST_CODE_QC}
              errorMessage=''
              onChange={(name, value, label, item) => {
                onChange({
                  ...model,
                  testCode: value[0],
                })
                ref.current.submit()
              }}
            />
          </div>
          <div style={{ width: '200px', marginLeft: "5px" }}>
            <CustomSelectAsync
              name="insId"
              portal
              required
              isClearable={false}
              placeholder={t("Machine")}
              value={model.insId || ""}
              code={parameterCode.INSTRUMENT}
              errorMessage=''
              onChange={(name, value, label, item) => {
                onChange({
                  ...model,
                  insId: value[0],
                  lotId: null
                })
                setInstrumentId(value)
                ref.current.submit()
              }}
            />
          </div>
          <div style={{ width: '200px', marginLeft: "5px" }}>
            <CustomSelectAsync
              name="lotId"
              portal
              required
              errorMessage=''
              readOnly={!instrumentId}
              isClearable={false}
              placeholder={t("Lot QC")}
              customSearchQuery={{ ...model, instrumentId: instrumentId }}
              value={model.lotId || ""}
              code={parameterCode.LOT_QC}
              onChange={(name, value, label, item) => {
                onChange({
                  ...model,
                  lotId: value[0],
                })
                ref.current.submit()
              }}
            />
          </div>
          <div style={{ width: '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="levels"
              portal
              errorMessage=''
              placeholder={t("Level")}
              value={model.level || ""}
              code={parameterCode.LEVEL_QC}
              onChange={(name, value, label, item) => {
                onChange({
                  levels: value[0],
                })
                ref.current.submit()
              }}
            />
          </div>
          <div>
            <CustomButton
              color="secondary"
              outline
              onClick={onRefreshHandler}
            >
              <i className={`fas fa-sync-alt ${loading == true ? " loading-spin" : ""}`}></i>
            </CustomButton>
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

CalculationSigmaTEButtons.displayName = "CalculationSigmaTEButtons"
export default withTranslation(["calculationQCPage", "common", "button"], { withRef: true, })(CalculationSigmaTEButtons)
