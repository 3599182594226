import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  CustomButton,
  CustomDatePicker,
  CustomSelectAsync,
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { BIAS, CV_FORMULA, MEAN_FORMULA, parameterCode } from "constant"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getLottestListQCSuccess } from "store/actions"
import AddCVInfoModal from "./AddCVInfoModal"
import AddMeanPeerInfoModal from "./AddMeanPeerInfoModal"
import AddMeanEQCModal from "./AddMeanEQCModal"

let cvCalculateName = ""
let meanPeerCalculateName = ""

let newDataCalculate = []

const ConfigCalculateModal = ({
  t,
  submitConfig,
  model,
  data,
  onGetLottestListQCSuccess,
}) => {
  const [height, setHeight] = useState(window.innerHeight)
  const [isRender, setIsRender] = useState(false)
  const [modal, setModal] = useState(false)
  const [isCV, setIsCV] = useState(false)
  const [isLot, setIsLot] = useState(false)
  const [isBias, setIsBias] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [cvCalculation, setCVcalCulation] = useState("")
  const [meanPeerCalculation, setMeanPeerCalculation] = useState("")
  const [cvDate, setCVDate] = useState([
    convertDateFormat(new Date(), "DD-MM-YYYY"),
    convertDateFormat(new Date(), "DD-MM-YYYY"),
  ])
  const [biasDate, setBiasDate] = useState([
    convertDateFormat(new Date(), "DD-MM-YYYY"),
    convertDateFormat(new Date(), "DD-MM-YYYY"),
  ])
  const [lots, setLots] = useState([])
  const [cvModal, setCVModal] = useState(false)
  const [meanPeer, setMeanPeerModal] = useState(false)
  const [meanEQC, setMeanEQCModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      getHeight()
    }, 500)
  }, [])

  const getHeight = () => {
    let top = document
      .getElementById("settings-config-button")
      .getBoundingClientRect().top
    setHeight((window.innerHeight - top - 65) * window.devicePixelRatio)
  }

  useEffect(() => {
    if (modal == false) {
      setIsRender(false)
    } else {
      newDataCalculate = data
      setIsRender(true)
    }
  }, [modal])

  useEffect(() => {
    setLots([])
  }, [model?.insId])

  const onInputInfo = values => {
    newDataCalculate = values.map(item => ({
      ...item,
      lottests: item.lottests.map(x => {
        let biasPercent = null;
        let biasUnit = null;
  
        if (x.meanPeer !== undefined && x.meanPeer !== null) {
          if (x.meanPeer !== 0) {
            biasPercent = Math.abs(x.mean - x.meanPeer) / x.meanPeer * 100;
          }
          biasUnit = Math.abs(x.mean - x.meanPeer);
        } else if (x.meanEQC !== undefined && x.meanEQC !== null) {
          if (x.meanEQC !== 0) {
            biasPercent = Math.abs(x.mean - x.meanEQC) / x.meanEQC * 100;
          }
          biasUnit = Math.abs(x.mean - x.meanEQC);
        }

        // Tính TE
         const TE = biasPercent !== null && x.sd !== undefined ? Math.abs(biasPercent) + 1.65 * x.sd : null;

        // Tính Sigma
        const sigma = TE !== null && biasPercent !== null && x.sd !== undefined && x.sd !== 0
            ? (TE - Math.abs(biasPercent)) / x.sd 
            : null;
            
        // Tính QGI
        const QGI = biasPercent !== null && x.cv !== null && x.cv !== 0
        ? biasPercent / (1.5 * x.cv) 
        : null;
        
          return { ...x, biasPercent, biasUnit, TE, sigma, QGI };
        })
    }));
    return newDataCalculate;
  };
  

  return (
    <React.Fragment>
      <div className="settings-config">
        <CustomButton
          color="secondary"
          outline
          disabled={!model.insId}
          id="settings-config-button"
          onClick={() => setModal(prev => !prev)}
        >
          <i style={{ fontSize: 16 }} className="bx bx-cog"></i>
        </CustomButton>

        {isRender && (
          <div
            className={`settings-config-modal ${modal ? "active" : "inactive"}`}
            style={{ height: "auto", maxHeight: `${height}px` }}
          >
            <div className="settings-config-modal-loading">
              <div className="col-12">
                <AccordionWrapper defaultTab={["1"]}>
                  <Accordion tabId={"1"} title={t("Calculate Setting")}>
                    <AccordionBody>
                      <AvForm
                        onValidSubmit={(e, values) => {
                          values.cvCalculateName = cvCalculateName
                          values.meanPeerCalculateName = meanPeerCalculateName
                          submitConfig(values)
                          setIsRender(prev => !prev)
                          onGetLottestListQCSuccess(newDataCalculate)
                        }}
                      >
                        <div className="mx-3">
                          <FormSwitchForm
                            name="isBias"
                            label={t("Bias Calculate Method")}
                            value={isBias}
                            onChange={value => {
                              setIsBias(value)
                              if (!value) {
                                setMeanPeerCalculation("")
                                setBiasDate([
                                  convertDateFormat(new Date(), "DD-MM-YYYY"),
                                  convertDateFormat(new Date(), "DD-MM-YYYY"),
                                ])
                                cvCalculateName = ""
                              }
                            }}
                          />
                        </div>
                        <div className="d-flex mx-3 mb-5 gap-1">
                          <div className="col-sm-6">
                            <CustomSelectAsync
                              name="meanPeerCalculation"
                              customSearchQuery={{ applicable: "%Bias" }}
                              portal
                              value={meanPeerCalculation}
                              isEdit
                              errorMessage={getInvalidMessageI18n(
                                t,
                                "Bias Calculate Method"
                              )}
                              required={isBias}
                              readOnly={!isBias}
                              code={parameterCode.EXPRESSION_IQC}
                              onChange={(name, value, label, item) => {
                                setMeanPeerCalculation(value)
                                meanPeerCalculateName = label
                                if (item[0].manageCode == BIAS.MEAN_PEER) {
                                  setMeanPeerModal(true)
                                }
                                if (item[0].manageCode == BIAS.MEAN_EQC) {
                                  setMeanEQCModal(true)
                                }
                              }}
                            />
                          </div>

                          <div className="col-sm-6">
                            <CustomDatePicker
                              name="biasDate"
                              disabled={!isBias}
                              value={biasDate}
                              isEdit
                              dateFormat={"d-m-Y"}
                              placeholder={"DD-MM-YYYY"}
                              onChangeHandler={(date, value) => {
                                if (date.time.length == 2) {
                                  setBiasDate([
                                    convertDateFormat(
                                      date.time[0] || new Date(),
                                      "DD-MM-YYYY"
                                    ),
                                    convertDateFormat(
                                      date.time[1] || new Date(),
                                      "DD-MM-YYYY"
                                    ),
                                  ])
                                }
                              }}
                              mode="range"
                              closeOnSelect={true}
                            />
                          </div>
                        </div>
                        <div className="d-flex mx-3">
                          <FormSwitchForm
                            name="isCV"
                            label={t("CV Calculate Method")}
                            value={isCV}
                            onChange={value => {
                              setIsCV(value)
                              if (!value) {
                                setIsLot(false)
                                setCVcalCulation("")
                                setCVDate([
                                  convertDateFormat(new Date(), "DD-MM-YYYY"),
                                  convertDateFormat(new Date(), "DD-MM-YYYY"),
                                ])
                                setLots([])
                                meanPeerCalculateName = ""
                              }
                            }}
                          />
                        </div>
                        <div className="d-flex gap-1 mx-3 mb-2">
                          <div className="col-sm-6">
                            <CustomSelectAsync
                              name="cvCalculation"
                              portal
                              isEdit
                              readOnly={!isCV}
                              required={isCV}
                              value={cvCalculation}
                              customSearchQuery={{ applicable: "CV" }}
                              code={parameterCode.EXPRESSION_IQC}
                              errorMessage={getInvalidMessageI18n(
                                t,
                                "CV Calculate Method"
                              )}
                              onChange={(name, value, label, item) => {
                                setCVcalCulation(value)
                                cvCalculateName = label
                                if (item[0].manageCode == CV_FORMULA.CVNGK) {
                                  setCVModal(true)
                                }
                              }}
                            />
                          </div>
                          <div className="col-sm-6">
                            <CustomDatePicker
                              name="cvDate"
                              value={cvDate}
                              isEdit
                              disabled={!(isCV && !isLot)}
                              placeholder={"DD-MM-YYYY"}
                              dateFormat={"d-m-Y"}
                              onChangeHandler={(date, value) => {
                                if (date.time.length == 2) {
                                  setCVDate([
                                    convertDateFormat(
                                      date.time[0] || new Date(),
                                      "DD-MM-YYYY"
                                    ),
                                    convertDateFormat(
                                      date.time[1] || new Date(),
                                      "DD-MM-YYYY"
                                    ),
                                  ])
                                }
                              }}
                              mode="range"
                              closeOnSelect={true}
                            />
                          </div>
                        </div>

                        <div className="d-flex mx-3">
                          <FormSwitchForm
                            name="lotQcs"
                            label={t("Lot Calculate")}
                            value={isLot}
                            disabled={!isCV}
                            onChange={value => {
                              setIsLot(value)
                              if (!value) {
                                setLots([])
                              }
                            }}
                          />
                        </div>
                        <div className="gap-1 mx-3 mb-5 ">
                          <div className="col-sm-12">
                            <CustomSelectAsync
                              isMulti
                              name="LotIds"
                              portal
                              isEdit
                              readOnly={!isLot}
                              required={isLot}
                              customSearchQuery={{
                                ...model,
                                instrumentId: model.insId,
                              }}
                              errorMessage={getInvalidMessageI18n(
                                t,
                                "Lot Calculate"
                              )}
                              value={lots}
                              code={parameterCode.LOT_QC}
                              onChange={(name, value) => {
                                setLots(value)
                              }}
                            />
                          </div>
                        </div>

                        <div className="mx-3 mb-3">
                          <FormSwitchForm
                            name="isValid"
                            label={t("Point Valid Calculate")}
                            value={isValid}
                            onChange={value => {
                              setIsValid(value)
                            }}
                          />
                        </div>
                        <div>
                          <CustomButton
                            text={t("common:Save")}
                            type="submit"
                            className="m-3"
                            color="primary"
                            isEdit
                          />
                        </div>
                      </AvForm>
                    </AccordionBody>
                  </Accordion>
                </AccordionWrapper>
              </div>
            </div>
          </div>
        )}

        <AddMeanEQCModal
          modal={meanEQC}
          toggle={() => {
            setMeanEQCModal(prev => !prev)
          }}
          data={data}
          onInputInfo={onInputInfo}
        />

        <AddMeanPeerInfoModal
          modal={meanPeer}
          toggle={() => {
            setMeanPeerModal(prev => !prev)
          }}
          data={data}
          onInputInfo={onInputInfo}
        />

        <AddCVInfoModal
          modal={cvModal}
          toggle={() => {
            setCVModal(prev => !prev)
          }}
          data={data}
          onInputInfo={onInputInfo}
        />
      </div>
    </React.Fragment>
  )
}

ConfigCalculateModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({})

const mapDispatchToProps = dispatch => ({
  onGetLottestListQCSuccess: payload =>
    dispatch(getLottestListQCSuccess(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["calculationQCPage", "common"])(ConfigCalculateModal))
