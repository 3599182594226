import { CustomButton, showToast } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { columnConnectorHisCode, columnConnectorHisCodeCode } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { getProfileMappingListWithPageUploadSuccess } from "store/actions"
import { read, utils } from "xlsx"
import ButtonExport from "./ButtonExport"
import { autoMappingProfileTest } from "helpers/app-backend"
import { showErrToast } from "components/Common/ErrToastr"

const HisCodeTableButtons = ({
  onRefresh,
  onSearch,
  loadingInstrumentMapping,
  data,
  t,
  searchText,
  onChangeSearchText,
  onClearSearchText,
  onSubmit,
  onSetRows,
  instrumentMappings,
  loading,
  hisConnector
}) => {
  const [dataSource, setDataSource] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [fileName, setFileName] = useState(t("common:Upload"))
  const [isUpload, setIsUpload] = useState(false)

  useEffect(() => {
    setDataSource(data)
  }, [data])
  const dispatch = useDispatch();

  const getDataExport = () => {
    let dt = dataExport.map(row => {
      let res = {}
      columnConnectorHisCode.forEach(element => {
        res[element] = row[columnConnectorHisCodeCode[element]]
      });
      return res
    })
    return dt
  }

  useEffect(() => {
    setDataExport(instrumentMappings || [])
  }, [instrumentMappings])

  const getColumnExport = () => {
    return columnConnectorHisCode
  }

  const getInstrumentHisString = (value) => {
    if (value) {
      return `${value}`
    }
    return null
  }

  const handleImport = e => {
    const files = e.target.files
    if (files.length) {
      let rowChoose = []
      const file = files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          let tmp = dataSource
          let rowChecked = [];
          rows.forEach(item => {
            if (item.lisCode && item.lisCode != '') {
              const ind = tmp.findIndex(x => x.hisCode == item.hisCode)
              if (ind >= 0) {
                tmp[ind].lisCode = getInstrumentHisString(item['lisCode']);
                tmp[ind].lisName = getInstrumentHisString(item['lisName']);
                tmp[ind].lisProfileCode = getInstrumentHisString(item['lisCode']);
                tmp[ind].lisProfileName = getInstrumentHisString(item['lisName']);
                tmp[ind].selected = 1;
                rowChoose.push(tmp[ind])
                rowChecked.push(item)
              }
            }
          })
          dispatch(getProfileMappingListWithPageUploadSuccess(tmp))
          onSetRows(rowChoose)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const autoMapping = async () => {
    let res = await autoMappingProfileTest(hisConnector.code)

    if (res.isSuccess == true) {
      showToast(
        `${t("message:Succeeded", {
          field: `${t("common:Mapping")}`,
        })}`
      )
    } else {
      // showErrToast(res.err.msgString)
      showErrToast("Đổng bộ không thành công.")
    }
  }
  return (
    <React.Fragment>
      <div className="input-table-header-tat-setting"
      >
        <CustomButton
          color="secondary"
          outline
          onClick={() => {
            onRefresh()
          }}
        >
          <i className={`fas fa-sync-alt ${loading == true ? " loading-spin" : ""}`}></i>
        </CustomButton>
        <CustomButton
          text={t("common:Mapping")}
          type="button"
          color="primary"
          onClick={() => {
            autoMapping()
          }}
          isEdit
          className="button-width"
        />
        <input
          id="importExcel"
          name="reportTemplateName"
          type="file"
          className=" d-none"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={e => {
            setFileName(e.target.files[0]?.name || t("common:Upload"))
            handleImport(e)
          }}
        />
        <CustomButton
          isEdit
          color="primary"
          outline
          onClick={() => {
            $(`#importExcel`).click()
            setIsUpload(true)
          }}
        >
          {fileName}
        </CustomButton>
        <ButtonExport
          rows={dataSource}
          data={getDataExport()}
          columns={getColumnExport()}
        />
        <CustomButton
          color="primary"
          onClick={() => { onSubmit() }}
        >
          {t('button:Save')}
        </CustomButton>
        <CustomSearchBar
          onSearch={onChangeSearchText}
          searchText={searchText}
          placeholder={t("common:Search")}
          onClear={onClearSearchText}
          onSubmit={(e) => {
            e.preventDefault()
            onSearch()
          }}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({
  loadingInstrumentMapping: hisConnector.loadingProfileMapping,
  instrumentMappings: hisConnector.profileMappings || [],
  hisConnector: hisConnector.hisConnector || {}
})

const mapDispatchToProps = dispatch => ({

})

HisCodeTableButtons.displayName = 'HisCodeTableButtons';
export default withTranslation(["testConfig", "common", "button"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(HisCodeTableButtons))
