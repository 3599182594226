


//i18n
import { CustomDatePicker, CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat, getI18nextLng } from "helpers/utilities"
import React, { useReducer } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"

const FilterLeftCustom = ({
    t,
    model = {},
    onChange,
}) => {
    let lang = getI18nextLng()
    const [config, setConfig] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

        }
    )
    return (
        <React.Fragment>
            <>
                <div className="tooltip-custom">
                    <CustomDatePicker
                        conjunction=" - "
                        name="runTimeFilter"
                        label={""}
                        checkSameTime={true}
                        dateFormat={"d-m-Y"}
                        value={[convertDateFormat(model.start || new Date(), "DD-MM-YYYY"), convertDateFormat(model.end || new Date(), "DD-MM-YYYY")]}
                        closeOnSelect={true}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                // onSubmit && onSubmit()
                            }
                        }}
                        onChangeHandler={date => {
                            if (date.time.length == 2) {
                                onChange({
                                    ...model,
                                    start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                                    end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                                })
                            } else {
                                onChange({
                                    ...model,
                                    start: new Date(),
                                    end: new Date(),
                                })
                            }
                        }}
                        maxDate={new Date()}
                        mode="range"
                    />
                    <span className="tooltiptext-custom-button-icon-left">
                        {t("Bộ lọc Thời gian chạy QC")}
                    </span>
                </div>
                {/* <ReactTooltip
                    id={`tooltip-custom-result-runtime-qc`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                >
                    <div className="tooltip-content">
                        <span>{t("Bộ lọc Thời gian chạy QC")}</span>
                    </div>
                </ReactTooltip>
                <span
                    className="tooltip-icon-wrapper"
                    data-tip
                    data-for={`tooltip-custom-result-runtime-qc`}
                >
                    <CustomDatePicker
                        conjunction=" - "
                        name="runTimeFilter"
                        label={""}
                        checkSameTime={true}
                        dateFormat={"d-m-Y"}
                        value={[convertDateFormat(model.start || new Date(), "DD-MM-YYYY"), convertDateFormat(model.end || new Date(), "DD-MM-YYYY")]}
                        closeOnSelect={true}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                // onSubmit && onSubmit()
                            }
                        }}
                        onChangeHandler={date => {
                            if (date.time.length == 2) {
                                onChange({
                                    ...model,
                                    start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                                    end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                                })
                            } else {
                                onChange({
                                    ...model,
                                    start: new Date(),
                                    end: new Date(),
                                })
                            }
                        }}
                        maxDate={new Date()}
                        mode="range"
                    />
                </span> */}
                <div style={{ width: '200px' }}>
                    <CustomSelect
                        name="machineId"
                        label={""}
                        onChange={(e, vals, a, items) => {
                            onChange({
                                ...model,
                                machineId: vals[0] || '',
                                machineName: items[0]?.name || ''
                            })
                        }}
                        placeholder={t("Machine")}
                        value={model.machineId || ''}
                        code={parameterCode.INSTRUMENT_QC}
                        // required={true}
                        errorMessage={''}
                    />
                </div>
                <div style={{ width: '200px' }}>
                    <CustomSelectAsync
                        name="lotIdFrom"
                        label={""}
                        placeholder={t("Lot")}
                        value={model.lotId || ''}
                        code={parameterCode.QC_LOT_LIST_FROM_LOT_TEST}
                        onChange={(e, vals, name, items) => {
                            onChange({
                                ...model,
                                lotId: vals[0] || '',
                                lotName: name || ''
                            })
                        }}
                        // required={true}
                        errorMessage={''}
                    />
                </div>
                <div style={{ width: '250px' }}>
                    <CustomSelectAsync
                        name="testCode"
                        placeholder={t("Search Test Code, Name")}
                        value={""}
                        valueName={""}
                        code={parameterCode.TEST_CODE}
                        label={""}
                        onChange={(e, vals, a, items) => {
                            onChange({
                                ...model,
                                testCode: vals[0] || ''
                            })
                        }}
                    />
                </div>
                <div style={{ width: '150px' }}>
                    <CustomSelectAsync
                        name="levels"
                        label={""}
                        placeholder={t("Levels")}
                        value={model.levels || ''}
                        code={parameterCode.LEVEL_QC}
                        onChange={(e, vals, name, items) => {
                            onChange({
                                ...model,
                                levels: vals[0] || '',
                                levelsName: name || ''
                            })
                        }}
                    />
                </div>
                <div className="run-time-date-picker" style={{ width: '140px' }}>
                    <div className="tooltip-custom">
                        <CustomDatePicker
                            label={t("")}
                            errorMessage=''
                            name="runTime"
                            placeholder={t("Run Time")}
                            value={model.runTime ? convertDateFormat(model.runTime, 'HH:mm DD-MM-YYYY') : ''}
                            enableTime={true}
                            dateFormat='H:i d-m-Y'
                            onClose={(e) => {
                                onChange({
                                    ...model,
                                    runTime: convertDateFormat(e[0], 'YYYY-MM-DD HH:mm')
                                })
                            }}
                        />
                        <span className="tooltiptext-custom-button-icon-left">
                            {t("Thời gian tạo QC thủ công")}
                        </span>
                    </div>
                    {/* <ReactTooltip
                        id={`tooltip-custom-result-runtime-manual-qc`}
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                    >
                        <div className="tooltip-content">
                            <span>{t("Thời gian tạo QC thủ công")}</span>
                        </div>
                    </ReactTooltip>
                    <span
                        className="tooltip-icon-wrapper"
                        data-tip
                        data-for={`tooltip-custom-result-runtime-manual-qc`}
                    >
                        <CustomDatePicker
                            label={t("")}
                            errorMessage=''
                            name="runTime"
                            placeholder={t("Run Time")}
                            value={model.runTime ? convertDateFormat(model.runTime, 'HH:mm DD-MM-YYYY') : ''}
                            enableTime={true}
                            dateFormat='H:i d-m-Y'
                            onClose={(e) => {
                                onChange({
                                    ...model,
                                    runTime: convertDateFormat(e[0], 'YYYY-MM-DD HH:mm')
                                })
                            }}
                        />
                    </span> */}
                </div>
            </>
        </React.Fragment>
    )
}

FilterLeftCustom.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(FilterLeftCustom))
