import { convertDateFormat, convertDateFormat_VN } from "helpers/utilities"
import React, { useState } from "react"

const Header = ({
  t,
  showBothCharts,
  setShowBothCharts,
  levels, setLevels,
  showUnvalidatedPoint,
  setShowUnvalidatedPoint,
  setTimeOption,
  levelsChart, setLevelsChartOption,
  lstLotId
}) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className="d-flex p-2 " style={headerStyle}>
      {isExpanded && (
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex flex-column">
            <div className="d-flex gap-2 mb-2">
            <input 
                defaultChecked
                type="radio" 
                name="timeOption" 
                value="ByTime" 
                onChange={() => setTimeOption('ByTime')} // Directly set state here
              />
              <label style={labelStyle}>
                {t('ByTime')}
              </label>
            </div>
            <div className="d-flex gap-2">
            <input 
                type="radio" 
                name="timeOption" 
                value="ByRun" 
                onChange={() => setTimeOption('ByRun')} // Directly set state here
              />
              <label style={labelStyle}>
                {t('ByRun')}
              </label>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex gap-2 mb-2">
              <input
                type="checkbox"
                checked={showBothCharts}
                onChange={(e) => setShowBothCharts(e.target.checked)}
              />
              <label style={labelStyle}>
                {t('UseManuIndicator')}
              </label>
            </div>
            <div className="d-flex gap-2">
              <input type="checkbox"
                checked={showUnvalidatedPoint}
                onChange={(e) => setShowUnvalidatedPoint(e.target.checked)} />
              <label style={labelStyle}>
                {t('ShowUnapprovedPoints')}
              </label>
            </div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div>
              <select style={dropdownStyle}
                value={levelsChart === null ? "multi" : levelsChart}
                onChange={(e) => {
                  const value = e.target.value
                  setLevelsChartOption(value)
                }}>
                <option value="multi">{t('MultiLevelChart')}</option>
                <option value="one">{t('LevelChart')}</option>
              </select>
            </div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div>
              <select style={dropdownStyle}
                value={levels === null ? "" : levels}
                onChange={(e) => {
                  const value = e.target.value
                  setLevels(value === "default" ? null : parseInt(value, 10))
                }}>
                <option value="default">{t('Level')}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
            <div style={verificationStyle}>
              <span style={verificationTextStyle}>✔ {t('Levey Jennings Chart')}</span>
            </div>
          </div>

        </div>
      )}

      {!isExpanded &&
        <div className="d-flex gap-2 w-100">
          <div>
            {t('Lot')}:
            <span style={{ fontWeight: 'bold' }}>
              {lstLotId && lstLotId.join(', ')}
            </span>
          </div>
          <div className="d-flex gap-1">
            {t('Start Date')}:
            {t('End Date')}:
          </div>
          <div className="d-flex gap-1" >
            {showBothCharts && <i className="fas fa-check-circle text-success font-size-14" style={{ alignSelf: 'center' }}></i>}
            {!showBothCharts && <i className="fas fa-times-circle text-secondary font-size-14" style={{ alignSelf: 'center' }}></i>}
            <span style={{ fontWeight: 'bold' }}>{t('UseManuIndicator')}</span>
          </div>
          <div className="d-flex gap-1" >
            <i className="fas fa-chart-bar font-size-15" style={{ alignSelf: 'center' }}></i>
            <span style={{ fontWeight: 'bold' }}>{t('Levey Jennings Chart')}</span>
          </div>
        </div>}
      <div>
        <span onClick={() => setIsExpanded(!isExpanded)} style={toggleButtonStyle}>
          <i className={isExpanded ? "dripicons-chevron-up" : "dripicons-chevron-down"} />
        </span>
      </div>

    </div>
  )
}

const headerStyle = {
  border: "1px solid #ccc",
  borderRadius: "5px",
  backgroundColor: "#f9f9f9", // Header background color
}

const labelStyle = {
  fontSize: "14px",
}


const dropdownStyle = {
  padding: "5px",
  fontSize: "14px",
  borderRadius: "5px",
  border: "1px solid #ccc",
}

const verificationStyle = {
  marginTop: "5px",
}

const verificationTextStyle = {
  color: "green",
  fontSize: "14px",
  fontWeight: "bold",
}

const toggleButtonStyle = {
  cursor: "pointer"
}

export default Header
