import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"

import {
    ADD_NEW_WESTGARD_SIGMA_QC,
    DELETE_WESTGARD_SIGMA_QC,
    GET_FULL_WESTGARD_SIGMA_QC,
    GET_WESTGARD_SIGMA_QC_DETAIL,
    UPDATE_WESTGARD_SIGMA_QC
} from "./actionTypes"

import {
    addWestgardSigmaQCFail,
    addWestgardSigmaQCSuccess,
    deleteWestgardSigmaQCsFail,
    deleteWestgardSigmaQCsSuccess,
    getFullWestgardSigmaQCFail,
    getFullWestgardSigmaQCSuccess,
    getWestgardSigmaQCDetailFail,
    getWestgardSigmaQCDetailSuccess,
    setWestgardSigmaQCSearchQuery,
    updateWestgardSigmaQCFail,
    updateWestgardSigmaQCSuccess
} from "./actions"


import {
    createWestgardSigmaQC,
    deleteWestgardSigmaQCByIds,
    getwestgardSigmaQCById,
    getWestgardSigmaQCList,
    updateWestgardSigmaQCById
} from "helpers/app-backend/IQC/QC_Westgard_Sigma_backend_helper"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

// function* fetchWestgardSigmaQCDetail({ id, callback }) {
//     try {
//         const ruleWestgardSigmaQC = yield select(state => {
//             return state.RuleManagement.ruleWestgardSigmaQC;
//         })
//         const response = yield call(getWestgardSigmaQCById, id)
//         yield put(getWestgardSigmaQCDetailSuccess({ ...ruleWestgardSigmaQC, ...response }))
//         if (callback) {
//             callback(response)
//         }
//     } catch (error) {
//         yield put(getWestgardSigmaQCDetailFail(error))
//     }
// }

function* fetchWestgardSigmaQC({ payload }) {
    try {
        const response = yield call(getwestgardSigmaQCById, payload)
        yield put(getWestgardSigmaQCDetailSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getWestgardSigmaQCDetailFail(error))
    }
}

function* onUpdateWestgardSigmaQC({ payload, callback }) {
    try {
        const response = yield call(updateWestgardSigmaQCById, payload)
        yield put(updateWestgardSigmaQCSuccess(response))

        const message = payload.type == 1 ? 'Westgard Sigma' : 'Westgard'

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "westgardSigmaQCPage:" + message
                )} <span class='text-decoration-underline fw-bold'>${payload["name"]
                    }</span>`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateWestgardSigmaQCFail(error))
    }
}

function* onDeleteWestgardSigmaQC({ payload: { westgardSigmaQCs, callback } }) {
    try {
        const response = yield call(deleteWestgardSigmaQCByIds, westgardSigmaQCs)
        yield put(deleteWestgardSigmaQCsSuccess(westgardSigmaQCs))

        const message = payload.type == 1 ? 'Westgard Sigma' : 'Westgard'

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("westgardSigmaQCPage:" + message)}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(deleteWestgardSigmaQCsFail(error))
    }
}

function* onAddNewWestgardSigmaQC({ payload, callback }) {
    try {
        const response = yield call(createWestgardSigmaQC, payload)
        yield put(addWestgardSigmaQCSuccess(response))

        const message = payload.type == 1 ? 'Westgard Sigma' : 'Westgard'

        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "westgardSigmaQCPage:" + message
                )} <span class='text-decoration-underline fw-bold'>${payload["name"]
                    }</span>`,
            })}`
        )
        if (callback) {
            callback()
        }
    } catch (error) {
        console.log(error)
        yield put(addWestgardSigmaQCFail(error))
    }
}


function* fetchFullWestgardSigmaQC({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.westgardSigmaQC.searchQuery;
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getWestgardSigmaQCList, payload)

        yield put(getFullWestgardSigmaQCSuccess(response))

        yield put(setWestgardSigmaQCSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getFullWestgardSigmaQCFail(error))
    }
}

function* westgardSigmaQCsSaga() {
    // yield takeEvery(GET_WESTGARD_SIGMA_DETAIL, fetchWestgardSigmaQCDetail)
    yield takeEvery(GET_WESTGARD_SIGMA_QC_DETAIL, fetchWestgardSigmaQC)
    yield takeEvery(ADD_NEW_WESTGARD_SIGMA_QC, onAddNewWestgardSigmaQC)
    yield takeEvery(UPDATE_WESTGARD_SIGMA_QC, onUpdateWestgardSigmaQC)
    yield takeEvery(DELETE_WESTGARD_SIGMA_QC, onDeleteWestgardSigmaQC)
    yield takeEvery(GET_FULL_WESTGARD_SIGMA_QC, fetchFullWestgardSigmaQC)
}

export default westgardSigmaQCsSaga