import { useEffect, useState } from "react"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import CustomTooltipButton from "components/Common/Button/CustomTooltipButton"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import ChangeSIDQCButtons from "./ChangeSIDQCButtons"
import { AvForm } from "availity-reactstrap-validation"

const RESOURCE = ModuleIds.ChangeSIDQC

const ChangeSIDQCTable = ({
  onSelect,
  onSelectAll,
  dataConfigs,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
  onChangeModel
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: ""
  })

  useEffect(() => {
      onChangeModel && onChangeModel(model)
  }, [model])

  const onResetHandler = () => {}
  useEffect(() => {
    setData([...dataConfigs])
  }, [dataConfigs])

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "id",
      text: "#",
      style: { width: 20 },
      formatter: (cellContent, item, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "insId",
      text: t("Instrument Id"),
      style: { width: 160 },
      formatter: (cellContent, item) => {
        return <span>{cellContent}</span>
      },
    },
    {
      dataField: "machineName",
      text: t("Instrument Name"),
    },
    {
      dataField: "lotName",
      text: t("Lot Name"),
    },
    {
      dataField: "sidqcIn",
      textAlign: "center",
      text: t("SID QC Instrument"),
      formatter: (cellContent, item, index) => {
        return (
          <label
            key={index}
            data-tip
            data-for={`sidqcIn-${index}`}
            style={{
              color: "#5F5F5F",
              backgroundColor: "#fff",
              padding: "1px 5px",
              marginRight: "5px",
              borderRadius: "5px",
              border: `1px solid #808080`,
              minWidth: 40,
              textAlign: "center",
            }}
          >
            {cellContent}
          </label>
        )
      },
    },
    {
      dataField: "levels",
      text: t("Levels"),
    },
    {
      dataField: "sidqcChg",
      textAlign: "center",
      text: t("SID QC Change"),
      formatter: (cellContent, item, index) => {
        return (
          <label
            key={index}
            data-tip
            data-for={`sidqcIn-${index}`}
            style={{
              color: "#5F5F5F",
              backgroundColor: "#fff",
              padding: "1px 5px",
              marginRight: "5px",
              borderRadius: "5px",
              border: `1px solid #808080`,
              minWidth: 40,
              textAlign: "center",
            }}
          >
            {cellContent}
          </label>
        )
      },
    },
    {
      dataField: "expireDate",
      text: t("Expire Date"),
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, item, index) => (
        <CustomTooltipButton
          index={index}
          component={item}
          onEdit={onEdit}
          onDelete={onDelete}
          onClone={onClone}
          t={t}
          resource={RESOURCE}
        />
      ),
    },
  ]
  return (
    <div className="test-request-table-toolbar">
      <CustomBootstrapTableAsync
        columns={columns}
        search
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        onRefresh={onRefresh}
        onReset={onResetHandler}
        onSubmitFilter={onSubmitFilter}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        searchText={model.search}
        loading={loading}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        isEnableExport={false}
        customButtonTable={() => (
          <ChangeSIDQCButtons
            t={t}
            model={model}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={val => {
              onSubmitFilter(val)
            }}
          />
        )}
        customButtonBefore={() => (
          <div className="d-flex align-items-center">
            <AvForm className="me-2">
              <FormSwitchForm
                name="activeLotExpired"
                label="Hiển thị tất cả các Lô"
                value={model.activeLotExpired ?? false}
                onChange={(val) => {
                  setModel({ ...model, activeLotExpired: val });
                  onSubmitFilter({ activeLotExpired: val });
                }}
              />
            </AvForm>
          </div>
        )}
      />
    </div>
  )
}

export default withTranslation(["changeSIDQCPage"])(ChangeSIDQCTable)
