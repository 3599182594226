import PropTypes from "prop-types"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ResultIQCTable from "./ResultIQCTable"

import {
    ConfirmModal,
    ConfirmModal2,
    showToast,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    convertDateFormat,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"


import { ModuleIds, QCResultState, QCResultStatus } from "constant"
import { withTranslation } from "react-i18next"
import { cancelResultsQC, cancelValidResultsQC, clickAddNewResultIQC, deleteResultsQC, getListResultQC, updateResultsQC, validResultsQC } from "store/actions"
import HeaderButtons from "./HeaderButtons"
import { set } from "store"
import { cancelQCResult, cancelValidQCResult, deleteQCResult, GetQCLotTestImportResult, GetQCLotTestList, updateQCResult, } from "helpers/app-backend"
// import ExpressionIQCModal from "./Modal/ExpressionIQCModal"

const RESOURCE = ModuleIds.ResultIQC

const stateConfirm = {
    "DelResult": "Del Result",
    "CancelResult": "Cancel Result"
}
const ResultIQC = ({
    onGetListResultQC,
    updating,
    updatedTime,
    resultQCs,
    loading,
    onUpdateResultsQC,
    onValidQCResult,
    onCancelValidQCResult,
    onCancelQCResult,
    onDeleteQCResult,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmModal2, setConfirmModal2] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [titleWarning, setTitleWarning] = useState('');
    const [keySearch, setKeySearch] = useState('')
    const [config, setConfig] = useState({})
    const [idSelecteds, setIdSelecteds] = useState([])
    const [disableHeader, setDisableHeader] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            disableSave: true,
            disableValid: true,
            disableInValid: true,
            disableCancelResult: true,
            disableDelResult: true,
            titleConfirm: ''
        }
    )
    const [model, setModel] = useState({
        runTime: new Date(),
        start: convertDateFormat(new Date(), "YYYY-MM-DD"),
        end: convertDateFormat(new Date(), "YYYY-MM-DD"),
    })
    const [dataSource, setDataSource] = useState([])
    const ref = useRef()

    useEffect(() => {
        setDataSource(resultQCs)
        checkDisableValid(resultQCs)
        // checkDisableCancelValid(resultQCs)
    }, [resultQCs])

    const onChangeSelectedRows = (data) => {
        setRows(data)
    }

    useEffect(() => {
        checkDisableValid(resultQCs)
        checkDisableCancelValid(rows)
    }, [rows])

    const resetState = () => {
        setRow({})
        setRows([])
    }


    const onDeleteToggleHandler = (e, test) => {
        setWarningModal(t("message:SelectRowWarning"))
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteExpressionQC({
            expression: rowsState, callback: () => {
                afterSubmit()
                resetState()
            }
        })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteExpressionQC({
            expression: rowState,
            callback: () => {
                afterSubmit()
                resetState()
            }
        })

        setRowDelete({})
        setRow({})
    }

    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    /**Get selected row and set to state
    *
    */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onRefreshHandler = () => {
        setDisableHeader({ disableSave: true })
        onGetListResultQC(model)
    }

    const onSearch = searchText => {
        setKeySearch(searchText)
        onGetListResultQC({ search: searchText })
    }


    const onSubmitFilter = values => {
        onGetListResultQC(model)
    }

    const afterSubmit = () => {
        setModal(false)
        onRefreshHandler()
    }

    const checkDisableValid = (data) => {
        if (data.findIndex(x => x.isNew == true) >= 0) {
            setDisableHeader({ disableValid: true })
            return;
        }
        if (rows.length == 0) {
            if (data.filter(x => x.isDeleted != true).findIndex(x => x.valState != QCResultState.Valid && x.isDeleted
                && (x.status == QCResultStatus.Accept || x.status == QCResultStatus.Warning)
            ) >= 0) {
                setDisableHeader({ disableValid: false })
            }
            else {
                setDisableHeader({ disableValid: true })
            }
        }
        else {
            let dt = rows;
            if (dt.filter(x => x.isDeleted != true).findIndex(x => x.valState != QCResultState.Valid
                && (x.status == QCResultStatus.Accept || x.status == QCResultStatus.Warning || x.status == QCResultStatus.Reject)
            ) >= 0) {
                setDisableHeader({ disableValid: false })
            }
            else {
                setDisableHeader({ disableValid: true })
            }
        }
    }

    //chỉ check row check
    const checkDisableCancelValid = (data) => {
        if (data.length == 0) {
            setDisableHeader({ disableInValid: true })
            return;
        }
        if (data.filter(x => x.isDeleted != true).findIndex(x => x.valState != QCResultState.Valid) >= 0) {
            setDisableHeader({ disableInValid: true })
        }
        else {
            setDisableHeader({ disableInValid: false })
        }
    }

    const onClickAddResult = async () => {

        if (!model.machineId || model.machineId == "" || !model.lotId || model.lotId == "") {
            setTitleWarning(t("PleaseSelectMachineLot"))
            setWarningModal(true);
            return;
        }

        let res = await GetQCLotTestImportResult({
            insId: model.machineId,
            lotId: model.lotId,
            levels: model.levels,
            testCode: model.testCode,
            runTime: convertDateFormat(model.runTime, "YYYY-MM-DD HH:mm")
        })
        // let newData = dataSource || []
        let newData = (dataSource.filter(x => x.isNew == true)) || []
        let dataForm = ref.current?.onGetDataNew();

        if (dataForm.deletes.length > 0) {
            newData = newData.filter(x => dataForm.deletes.findIndex(z => z.lotTestId == x.lotTestId 
                && z.machineId == x.machineId && z.testCode == x.testCode
                && z.levels == x.levels) < 0)
        }
        for (let i = res.length - 1; i >= 0; i--) {
            let item = res[i]
            let keyCheck = `${item.testCode}-${item.lotId}-${item.insId}-${item.levels}-${convertDateFormat(model.runTime, "YYYY-MM-DD HH:mm")}`
            let ind = newData.findIndex(x => `${x.testCode}-${x.lotCode}-${x.machineId}-${x.levels}-${x.runTime}` == keyCheck)
            if (ind < 0) {
                newData.unshift({
                    "id": new Date().getTime() + Math.floor(Math.random() * 1000) + i,
                    "lotTestId": item.id,
                    "lotCode": model.lotId,
                    "lotName": model.lotName,
                    "testCode": item.testCode,
                    "testName": item.testName,
                    "isPosNeg": item.isPosNeg,
                    "result": "",
                    "resultText": "",
                    "levels": item.levels,
                    "levelsName": item.levels,
                    "mean": item.mean,
                    "sd": item.sd,
                    "rule": "",
                    "_6Sigma": item._6Sigma,
                    "runTime": convertDateFormat(model.runTime, "YYYY-MM-DD HH:mm"),
                    "note": "",
                    "machineId": model.machineId || null,
                    "machineName": model.machineName || null,
                    "rowSpan": 1,
                    "isNew": true
                })
                setDisableHeader({ disableValid: true })
                if (disableHeader.disableSave == true) {
                    setDisableHeader({ disableSave: false })
                }
            }
        }
        setDataSource([...newData])

    }

    const onClickSave = () => {
        let dataNew = ref.current?.onGetDataNew();
        let dataForm = ref.current?.onGetData();
        //không chọn dòng cũ và dòng mới ko có hoặc ko nhập KQ
        if (dataForm.data.length == 0 && dataForm.deletes.length == 0 && dataNew.data.length == 0) {
            setTitleWarning(t("message:SelectRowWarning"))
            setWarningModal(true);
            return;
        }
        let dataSubmit = {
            data: [],
            dataDelete: []
        }
        //không chọn dòng nào
        if (dataForm.data.length == 0) {
            dataSubmit.data = dataNew.data.map(x => ({
                id: x.id,
                lotTestId: x.lotTestId,
                runTime: convertDateFormat(x.runTime, "YYYY-MM-DD HH:mm"),
                result: x.result,
                resultText: x.resultText,
                note: x.note,
                isNew: x.isNew == true
            }))
        }
        else {//có chọn dòng
            dataSubmit.data = dataForm.data.map(x => ({
                id: x.id,
                lotTestId: x.lotTestId,
                runTime: convertDateFormat(x.runTime, "YYYY-MM-DD HH:mm"),
                result: x.result,
                resultText: x.resultText,
                note: x.note,
                isNew: x.isNew == true
            }))
        }
        //data xóa
        dataSubmit.dataDelete = dataForm.deletes.map(x => ({
            id: x.id,
            lotTestId: x.lotTestId,
            runTime: convertDateFormat(x.runTime, "YYYY-MM-DD HH:mm"),
            result: x.result,
            resultText: x.resultText,
            isNew: x.isNew == true
        }))
        //lưu
        onUpdateResultsQC({
            data: dataSubmit, callback: () => {
                onRefreshHandler()
            }
        })
    }

    const onClickValid = async () => {
        let dataForm = ref.current?.onGetValidIds() || []
        if (dataForm.length == 0) {
            setTitleWarning(t("Data Invalid"))
            setWarningModal(true);
            return;
        }
        onValidQCResult({ resultIds: dataForm }, (err) => {
            if (!err) {
                onRefreshHandler()
            }
        })
    }

    const onClickCancelValid = async () => {
        let dataForm = ref.current?.onGetCancelValidIds() || []
        if (dataForm.length == 0) {
            setTitleWarning(t("Data Invalid"))
            setWarningModal(true);
            return;
        }
        onCancelValidQCResult({ resultIds: dataForm }, (err) => {
            if (!err) {
                onRefreshHandler()
            }
        })

        await cancelValidQCResult({ resultIds: dataForm })
    }

    const onClickCancelResult = () => {
        let dataForm = ref.current?.onGetCancelResultIds() || []
        if (dataForm.length == 0) {
            setTitleWarning(t("Data Invalid"))
            setWarningModal(true);
            return;
        }
        setIdSelecteds(dataForm)
        setConfirmModal2(true)
        setDisableHeader({ titleConfirm: stateConfirm.CancelResult })
    }

    const onSubmitCancelResult = async () => {
        onCancelQCResult({ resultIds: idSelecteds }, (err) => {
            setConfirmModal2(false)
            if (!err) {
                showToast(
                    `${t("message:Succeeded", {
                        field: `${t(disableHeader.titleConfirm)}`,
                    })}`
                )
                onRefreshHandler()
            }
        })
    }


    const onClickDeleteResult = () => {
        let dataForm = ref.current?.onGetDelResultIds() || []
        if (dataForm.length == 0) {
            setTitleWarning(t("Data Invalid"))
            setWarningModal(true);
            return;
        }
        setIdSelecteds(dataForm)
        setConfirmModal2(true)
        setDisableHeader({ titleConfirm: stateConfirm.DelResult })
    }

    const onSubmitDeleteResult = async () => {
        onDeleteQCResult({ resultIds: idSelecteds }, (err) => {
            setConfirmModal2(false)
            if (!err) {
                showToast(
                    `${t("message:Succeeded", {
                        field: `${t(disableHeader.titleConfirm)}`,
                    })}`
                )
                onRefreshHandler()
            }
        })
    }

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <ResultIQCTable
                        ref={ref}
                        data={dataSource}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        onSubmitFilter={onSubmitFilter}
                        onDelete={onDeleteToggleHandler}
                        onChangeSelectedRows={onChangeSelectedRows}
                        loading={loading}
                        updatedTime={updatedTime}
                        onChangeConfig={val => {
                            setConfig(val)
                        }}
                        onChangeModel={val => { setModel(val) }}
                        onChangeTableConent={(val) => {
                            setDisableHeader({ disableSave: val })
                        }}
                        onChangeCancelResultSelected={(vals) => {
                            setDisableHeader({ disableCancelResult: vals.length > 0 ? false : true })
                        }}
                        onDeleteRow={vals => {
                            setIdSelecteds(vals)
                            setConfirmModal2(true)
                            setDisableHeader({ titleConfirm: stateConfirm.DelResult })
                        }}
                        onCancelResultRow={vals => {
                            setIdSelecteds(vals)
                            setConfirmModal2(true)
                            setDisableHeader({ titleConfirm: stateConfirm.CancelResult })
                        }}
                    />
                )}
                resource={RESOURCE}
                onDelete={onDeleteToggleHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Quality Management")}
                subtitle={t("Internal Quality Control")}
                buttons={() => (
                    <HeaderButtons resource={RESOURCE}
                        loading={loading}
                        config={config}
                        disableSave={disableHeader.disableSave}
                        disableValid={disableHeader.disableValid}
                        disableInValid={disableHeader.disableInValid}
                        disableCancelResult={disableHeader.disableCancelResult}
                        disableDelResult={disableHeader.disableCancelResult}
                        onClickView={() => {
                            onSubmitFilter()
                        }}
                        onClickAddResult={() => {
                            onClickAddResult()
                        }}
                        onClickSave={() => {
                            onClickSave()
                        }}
                        onClickValid={() => {
                            onClickValid()
                        }}
                        onClickCancelValid={() => {
                            onClickCancelValid()
                        }}
                        onClickCancelResult={() => {
                            onClickCancelResult()
                        }}
                        onClickDeleteResult={() => {
                            onClickDeleteResult()
                        }}
                    />
                )}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={titleWarning}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            <ConfirmModal2
                modal={confirmModal2}
                title={t("message:Warning")}
                message={`${t("message:ConfirmRecord", {
                    entity: `${t(disableHeader.titleConfirm)}`,
                })}`}
                onToggle={() => { setConfirmModal2(prev => !prev) }}
                onConfirm={() => {
                    if (disableHeader.titleConfirm == stateConfirm.CancelResult) {
                        onSubmitCancelResult()
                    }
                    else if (disableHeader.titleConfirm == stateConfirm.DelResult) {
                        onSubmitDeleteResult()
                    }
                }}
                btnConfirmText={t("common:Save")}
                isEdit
            />
        </React.Fragment>
    )
}

ResultIQC.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = ({ resultQCs }) => ({
    loading: resultQCs.loading,
    updating: resultQCs.updating,
    updatedTime: resultQCs.updatedTime,
    resultQCs: resultQCs.resultQCs

})
//updateResultsQC
const mapDispatchToProps = dispatch => ({
    onGetListResultQC: payload => dispatch(getListResultQC(payload)),
    onUpdateResultsQC: payload => dispatch(updateResultsQC(payload)),
    onValidQCResult: (payload, callback) => dispatch(validResultsQC(payload, callback)),
    onCancelValidQCResult: (payload, callback) => dispatch(cancelValidResultsQC(payload, callback)),
    onCancelQCResult: (payload, callback) => dispatch(cancelResultsQC(payload, callback)),
    onDeleteQCResult: (payload, callback) => dispatch(deleteResultsQC(payload, callback)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["resultIQCPage", "message"])(ResultIQC)))