import { useEffect, useState } from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

const LotIndex = ({
  placeholder,
  isInvalid,
  errorMessage,
  readOnly,
  lstLotId,
  onChange,
  selectedRow,
  datapoints,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState(
    lstLotId?.lstLotId || []
  )
  const [selectedLots, setSelectedLots] = useState([])
  const [previousState, setPreviousState] = useState([])

  useEffect(() => {
    let newSetLotId = new Set()
    if (datapoints != null && Object.keys(datapoints).length > 0) {
      let dataset =
        datapoints[selectedRow.testCode]["total"]
      Object.keys(dataset).forEach((key) => {
        let entry = dataset[key]
        for(let i = 0; i < entry.length; i++){
          if(!newSetLotId.has(entry[i].lotId)){
            newSetLotId.add(entry[i].lotId)
          }else{
            newSetLotId.delete(entry[i].lotId)
            newSetLotId.add(entry[i].lotId)
          }
        }
      })
      setSelectedLots([...newSetLotId])
    }
  }, [selectedRow])

  useEffect(() => {
    if (
      lstLotId != null &&
      lstLotId.lstLotId != null &&
      lstLotId.lstLotId != []
    ) {
      setDropdownOptions(lstLotId?.lstLotId)
    }
  }, [lstLotId])

  const handleChange = selectedOptions => {
    const newSelectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setPreviousState(selectedLots)
    setSelectedLots(newSelectedValues)
    onChange(newSelectedValues)
  }

  return (
    <>
      {/* <span>{t("Index")}</span> */}
      <div style={styles.dropdownContainer}>
        <Select
          onChange={handleChange}
          options={dropdownOptions?.map(lot => ({
            value: lot,
            label: lot,
          }))}
          isClearable
          placeholder={placeholder || ""}
          value={selectedLots?.map(lot => ({
            value: lot,
            label: lot,
          }))}
          isDisabled={readOnly}
          isMulti
          menuPlacement="auto"
          maxMenuHeight={150}
        />
      </div>

      {isInvalid && (
        <div className="text-danger form-group">
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      )}
    </>
  )
}

const styles = {
  lotList: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  lotItem: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "8px 15px",
    margin: "5px",
    borderRadius: "25px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "14px",
  },
  lotText: {
    marginRight: "10px",
    color: "#333",
  },
  removeButton: {
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    fontSize: "14px",
    cursor: "pointer",
  },
  dropdownContainer: {
    marginTop: "10px",
  },
}

LotIndex.propTypes = {
  datapoints: PropTypes.object,
}

const mapStateToProps = ({ qcDatapoints }) => ({
  datapoints: qcDatapoints.datapoints,
  loadingDatapoints: qcDatapoints.loadingDatapoints,
  error: qcDatapoints.error,
})
const mapDispatchToProps = dispatch => ({
  onGetQCDatapoints: payload => dispatch(getQCDatapoints(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["chartiQCPage", "message", "common", "sidebar"])(LotIndex)
  )
)
