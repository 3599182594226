import {
  GET_QC_DATAPOINTS,
  GET_QC_DATAPOINTS_FAIL,
  GET_QC_DATAPOINTS_SUCCESS,
  GET_LOT_FOR_CHART,
  GET_LOT_FOR_CHART_FAIL,
  GET_LOT_FOR_CHART_SUCCESS,
  GET_RESULT_BY_SEQ,
  GET_RESULT_BY_SEQ_SUCCESS,
  GET_RESULT_BY_SEQ_FAIL
} from "./actionTypes"

export const getQCDatapoints = payload => ({
  type: GET_QC_DATAPOINTS,
  payload: payload,
})

export const getQCDatapointsSuccess = payload => ({
  type: GET_QC_DATAPOINTS_SUCCESS,
  payload: payload,
})

export const getQCDatapointsFail = error => ({
  type: GET_QC_DATAPOINTS_FAIL,
  payload: error,
})

export const getLotForChart = payload => ({
  type: GET_LOT_FOR_CHART,
  payload: payload,
})

export const getLotForChartSuccess = payload => ({
  type: GET_LOT_FOR_CHART_SUCCESS,
  payload: payload,
})

export const getLotForChartFail = error => ({
  type: GET_LOT_FOR_CHART_FAIL,
  payload: error,
})