import { AvForm } from "availity-reactstrap-validation"

import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import { Instrument_Translator, parameterCode } from "constant/utility"

//i18n
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { getInvalidMessageI18n } from "helpers/utilities"
import { forEach, isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"

let isChange = false;
const ExpressionIQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
}) => {

  const formEl = useRef()
  const [dataTestExpression, setDataTestExpression] = useState([
    { parameterCode: new Date().getTime() + Math.floor(Math.random() * 100) + 1, parameterValue: '' },
  ]);
  const [disableSave, setDisableSave] = useState(true)
  const checkValidate = () => {
    if (isEdit == true && data.id) {
      let check = checkChangeEdit(data, formEl.current?.getValues())
      if (check != true) {
        setDisableSave(true)
        return;
      }
    }
    if (formEl.current) {
      if (Object.keys(formEl.current?.state?.invalidInputs).length > 0) {
        setDisableSave(true)
        return;
      }
    }
    setDisableSave(false)
  }

  const checkChangeEdit = (oldData, newData) => {
    let fieldCheck = ['applicable', 'decOdd', 'formula', 'inUse', 'name']
    for (let index = 0; index < fieldCheck.length; index++) {
      const element = fieldCheck[index];
      if (oldData[element] != newData[element]) {
        return true
      }
    }
    //check parameter
    if (oldData.parameters == null) {
      if (dataTestExpression.findIndex(x => x.parameterValue != '' && x.parameterValue != null && x.parameterValue != undefined) >= 0) {
        return true;
      }
    }
    else {
      let tmp = dataTestExpression.filter(x => x.parameterValue != '' && x.parameterValue != null && x.parameterValue != undefined) || []
      if (oldData.parameters.length != tmp.length) {
        return true
      }
      else {
        for (let index = 0; index < oldData.parameters.length; index++) {
          const element = oldData.parameters[index];
          if (dataTestExpression.findIndex(x => x.parameterCode == element.parameterCode) < 0) {
            return true
          }
        }
      }
    }
    return false;
  }



  useEffect(() => {
    let dataNew = []
    if (data && data.parameters && data.parameters.length > 0) {
      data.parameters.forEach(element => {
        dataNew.push({ parameterCode: element.parameterCode, parameterValue: element.parameterName })
      });
    }
    else {
      dataNew.push({ parameterCode: new Date().getTime() + Math.floor(Math.random() * 100) + 1, parameterValue: '' })
    }
    setDataTestExpression(dataNew)
    setTimeout(() => {
      checkValidate()
    }, 100);
  }, [data])

  const renderIconExpression = (ind, length) => {
    if (ind != length) {
      return <CustomButtonIcon color=" btn btn-outline-danger btn-soft-danger" >
        <i className="mdi mdi-minus" />
      </CustomButtonIcon>
    }
    return <CustomButtonIcon color="btn btn-outline-success btn-soft-success" >
      <i className="mdi mdi-plus" />
    </CustomButtonIcon>
  }

  const renderTestExpression = (data = []) => {
    return <>
      {data.map((item, index) => {
        return <div key={index} className={`col-md-6 align-self-center d-flex`}>
          <div className="flex-1 mb-3">
            <CustomSelect
              name={`parameter${index}`}
              value={item.parameterCode || ""}
              valueName={item.parameterName || ''}
              code={parameterCode.EXPRESSION_FORMULA_IQC}
              label={t("Ingredient Calculate") + ` ${index + 1}`}
              detected={false}
              onChange={(e, vals, a, items) => {
                let tmp = dataTestExpression
                if (items[0]) {
                  item.parameterCode = items[0].code
                  item.parameterValue = items[0].message
                }
                else {
                  item.parameterCode = ''
                  item.parameterValue = ''
                }

                setDataTestExpression(tmp)
                checkValidate()
              }}
            />
          </div>
          {index < 5 &&
            <div className="mb-3" style={{ alignContent: 'end' }} onClick={() => {
              if (index + 1 == data.length) {
                if (index < 5) {
                  let dataNew = dataTestExpression
                  dataNew.push({ parameterCode: new Date().getTime() + Math.floor(Math.random() * 100) + 1, parameterValue: '' })
                  setDataTestExpression([...dataNew])
                }
              }
              else {
                //xóa 
                let dataNew = dataTestExpression.filter(x => x.parameterCode != item.parameterCode)
                setDataTestExpression([...dataNew])
              }
            }}>
              {renderIconExpression(index, data.length - 1)}
            </div>
          }
        </div>
      })}
    </>
  }

  const renderInput = () => {
    return <>
      <div className="col-md-6">
        <div className="mb-3">
          <CustomSelect
            required
            name="applicable"
            value={data.applicable || ""}
            errorMessage={getInvalidMessageI18n(t, "Applicable")}
            code={parameterCode.QC_APPLICABLE}
            label={t("Applicable")}
            detected={isEdit}
            onChange={(e, vals) => {
              setTimeout(() => {
                checkValidate()
              }, 100);
            }}
          />
        </div>
      </div>
      {renderTestExpression(dataTestExpression)}
      <div className="col-md-6">
        <div className="mb-3">
          <CustomAvField
            name="decOdd"
            type="number"
            value={`${data.decOdd || 0}`}
            label={t("Numeric Round")}
            detected={isEdit}
            maxLength={2}
            min={0}
            onChange={(e) => {
              setTimeout(() => {
                checkValidate()
              }, 100);
            }}
            onUndoClick={(e) => {
              setTimeout(() => {
                checkValidate()
              }, 100);
            }}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3" style={{ width: '100%' }}>
          <CustomAvField
            name="formula"
            type="textarea"
            placeholder="@A=@B/@C*@D-@E+@F*100%"
            value={`${data.formula || ''}`}
            label={t("Formula")}
            detected={isEdit}
            maxLength={500}
            onUndoClick={(e) => {
              setTimeout(() => {
                checkValidate()
              }, 100);
            }}
            onChange={(e) => {
              setTimeout(() => {
                checkValidate()
              }, 100);
            }}
          />
        </div>
      </div>
    </>
  }
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Expression IQC Title")
  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={() => {
      isChange = false;
      toggle()
    }}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="machineForm"
          onValidSubmit={(e, values) => {
            let dataSubmit = {
              manageCode: values.manageCode || '',
              applicable: values.applicable || '',
              name: values.name || '',
              formula: values.formula || '',
              decOdd: values.decOdd || 0,
              inUse: values.inUse || false,
              parameters: dataTestExpression.filter(x => x.manageCode != '' && x.parameterCode != null && x.parameterValue != '').map(x => x.parameterCode) || []
            }
            onValidSubmit(e, dataSubmit)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <div className="row px-3">
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  name="manageCode"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Expression Code")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.manageCode || ""}
                  label={t("Expression Code")}
                  detected={isEdit}
                  disabled={isEdit}
                  onChange={() => {
                    setTimeout(() => {
                      checkValidate()
                    }, 100);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomSelect
                  required
                  name="name"
                  value={data.name || ""}
                  errorMessage={getInvalidMessageI18n(t, "Expression Name")}
                  code={parameterCode.EXPRESSION_FORMULA_IQC}
                  label={t("Expression Name")}
                  detected={isEdit}
                  onChange={() => {
                    setTimeout(() => {
                      checkValidate()
                    }, 100);
                  }}
                />
              </div>
            </div>

            {renderInput()}
            <div className="mb-3">
              <FormSwitchForm
                name="inUse"
                label={`${t("common:Active")}: `}
                value={data.inUse ?? true}
                onClick={(e) => {
                  setTimeout(() => {
                    checkValidate()
                  }, 100);
                }}
              />
            </div>
          </div>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={() => {
            isChange = false;
            toggle()
          }}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          disabled={disableSave}
          onClick={() => {
            formEl?.current?.submit()
          }}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["expressionsIQCPage", "common"])(ExpressionIQCModal)
