import { withTranslation } from "react-i18next"
import { CustomButton } from "components/Common"
import { exportQCLot } from "helpers/app-backend"
const ImportExportButton = ({
    t, onExportQCLot
}) => {
    return (
        <>
            <div className="tooltip-custom">
                <CustomButton
                    isEdit
                    color="secondary"
                    outline
                    onClick={() => { onExportQCLot && onExportQCLot() }}
                >
                    <i className={"fas fa-download"}></i>
                </CustomButton>
                <span className="tooltiptext-custom-button-icon-left">
                    {t("Export")}
                </span>
            </div>
        </>
    )
}
export default withTranslation(["userPage", "common"])(ImportExportButton)