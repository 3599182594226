import { CustomNav, CustomNavLink } from "components/Common"
import { getUrlParamByKey } from "helpers/utilities"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, TabContent, TabPane } from "reactstrap"
import Tests from "./Tests"
import Machine from "./Machines"

const MachineTestQCTab = ({ t }) => {
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")
    return (
        <Row style={{ backgroundColor: 'white' }}>
            <CustomNav
                onToggle={e => {
                    setCurrentTab(e)
                }}
                defaultTab={currentTab}
                tabs
                className="nav-tabs-custom pt-3"
                tabContents={customActiveTab => (
                    <TabContent
                        activeTab={customActiveTab}
                    >
                        {currentTab == '1' && <TabPane tabId="1">
                            <div className='mt-1'>
                                <Tests />
                            </div>
                        </TabPane>}
                        {currentTab == '2' && <TabPane tabId="2">
                            <div>
                                <Machine />
                            </div>
                        </TabPane>}
                    </TabContent>
                )}
            >
                <CustomNavLink tabId="1">
                    <span className="">{t("testPage:Test List") + ' QC'}</span>
                </CustomNavLink>
                <CustomNavLink tabId="2">
                    <span className="">{t("machinePage:Instrument List") + ' QC' }</span>
                </CustomNavLink>
            </CustomNav>
        </Row>
    )
}


export default connect(
    null,
    null
)(withRouter(withTranslation(["testPage", "machinePage", "message"])(MachineTestQCTab)))
