import { ConfirmModal2, showToast, WarningModal } from "components/Common"
import { ModuleIds } from "constant"
import { updateQCLotTestSigma } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { addNewLotTestQC, getQCLotTest, updateLotTestQC } from "store/actions"
import ConfigLotTestDetailTable from "./configLotTestDetailTable"
import Header from "./header"
import AddLotTestMachine from "./Modal/AddLotTestMachine"
import LotTestModal from "./Modal/LotTestModal"
import LotTestTextModal from "./Modal/LotTestTextModal"
import { showErrToast } from "components/Common/ErrToastr"

const RESOURCE = ModuleIds.ManagementQC

const ConfigLotTestQCRight = ({
    t,
    updatedTimeLotTests,
    loadingLotTests,
    lotTests,
    config,
    onAddNewLotTestQC,
    onGetQCLotTest,
    updatingLotTests,
    onUpdateLotTestQC
}) => {
    const ref = useRef();
    const [warningModal, setWarningModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [testModal, setTestModal] = useState(false)
    const [testTextModal, setTestTextModal] = useState(false)
    const [copyModal, setCopyModal] = useState(false)
    const [disableSave, setDisableSave] = useState(true)
    const [rowSelected, setRowSelected] = useState([])
    const [deleteSelected, setDeleteSelected] = useState([])
    const initModel = {
        insId: config.instrumentId || '',
        lotId: config.lotId,
        search: ''
    }
    const [model, setModel] = useState(initModel)
    useEffect(() => {
        setModel({
            ...model,
            insId: config.instrumentId || '',
            lotId: config.lotId,
        })
        if (config.lotId)
            getQCLotTest({
                insId: config.instrumentId || '',
                lotId: config.lotId,
            })
    }, [config])

    const getQCLotTest = (val = {}) => {
        if (config.lotId) {
            onGetQCLotTest({
                data: { ...model, ...val }
            })
            setDisableSave(true)
        }
    }

    const onSubmitAddNew = (values) => {
        if ((values.qcInfo == undefined || values.qcInfo.length == 0) && (values.qcTextInfo == undefined || values.qcTextInfo.length == 0)) {
            showErrToast(t("Please input data test, level"))
            return;
        }
        onAddNewLotTestQC({
            data: values, callback: () => {
                getQCLotTest()
                setTestModal(false)
                setTestTextModal(false)
            }
        })

    }

    const onSubmitUpdateTable = async () => {
        let data = ref.current?.onGetData();
        if (data.data.length == 0 && data.deletes.length == 0) {
            setWarningModal(true)
            return;
        }
        setConfirmModal(true)
    }

    const onSubmitUpdateTableHandler = async () => {
        let data = ref.current?.onGetData();
        onUpdateLotTestQC({
            data: {
                data: data.data,
                deletes: data.deletes
            }, callback: () => {
                // getQCLotTest() không load lại để "Thiết lập rule không load lại"
                setConfirmModal(prev => !prev)
            }
        })
    }

    const onRefreshHandler = () => {
        getQCLotTest()
    }

    const onSubmitSigma = async (vals) => {
        let data = ref.current?.onGetData();
        if (data.data.length == 0) {
            setWarningModal(true)
            return;
        }

        let dt = {
            lotTestId: data.data.map(x => x.id),
            rules: vals
        }

        await updateQCLotTestSigma(dt)
        showToast(
            `${t("message:UpdatedMessage")}`
        )
        // onRefreshHandler()
    }

    return (
        <React.Fragment>
            <div className="management-qc-right">
                {loadingLotTests &&
                    <div className=" w-100" style={{
                        height: '93%',
                        position: 'absolute', zIndex: 9,
                        background: 'rgba(256,256,256,0.5)'
                    }}>
                        <div className="w-100 " style={{ height: '93%' }}>
                            <div className="text-center" style={{ marginTop: '50px' }}>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Header
                    disableSave={!(disableSave == false && (rowSelected.length > 0 || deleteSelected.length > 0))}
                    onClickAdd={() => {
                        if (config.id)
                            setTestModal(true)
                    }}
                    onClickAddText={() => {
                        if (config.id)
                            setTestTextModal(true)
                    }}
                    onClickCopy={() => {
                        setCopyModal(true)
                    }}
                    config={config}
                    onSaveClick={() => {
                        onSubmitUpdateTable()
                    }}

                />
                <ConfigLotTestDetailTable
                    config={config}
                    updatedTime={updatedTimeLotTests}
                    onSearch={(val) => {
                        setModel({ ...model, ...val })
                        getQCLotTest(val)
                    }}
                    onResetFilter={() => {
                        setModel(initModel)
                        getQCLotTest({
                            ...initModel,
                            _6sigma: null,
                            levels: null
                        })
                    }}
                    model={model}
                    data={lotTests || []}
                    ref={ref}
                    onRefresh={onRefreshHandler}
                    onChangeData={(val, rows) => {
                        setDisableSave(false)
                    }}
                    onSubmitSigma={vals => {
                        onSubmitSigma(vals)
                    }}
                    onChangeRows={(vals) => {
                        setRowSelected(vals)
                    }}
                    onChangeDeleteRows={(vals) => {
                        setDeleteSelected(vals)
                    }}
                />
            </div>
            {testModal == true &&
                <LotTestModal
                    modal={testModal}
                    toggle={() => { setTestModal(prev => !prev) }}
                    data={config}
                    onValidSubmit={val => {
                        onSubmitAddNew(val)
                    }}
                />
            }
            {testTextModal == true &&
                <LotTestTextModal
                    modal={testTextModal}
                    toggle={() => { setTestTextModal(prev => !prev) }}
                    data={config}
                    onValidSubmit={val => {
                        onSubmitAddNew(val)
                    }}
                />
            }
            <AddLotTestMachine
                modal={copyModal}
                toggle={() => { setCopyModal(prev => !prev) }}
                data={config || {}}
                onValidSubmit={val => {
                    // onSubmitAddNew(val)
                }}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal2
                modal={confirmModal}
                title={t("Lot Test")}
                message={t("Save Lot Test") + "?"}
                onToggle={() => { setConfirmModal(prev => !prev) }}
                onConfirm={onSubmitUpdateTableHandler}
                btnConfirmText={t("common:Save")}
                isEdit
            />
        </React.Fragment>
    )
}
const mapStateToProps = ({ lotQCs }) => ({
    updatedTimeLotTests: lotQCs.updatedTimeLotTests,
    loadingLotTests: lotQCs.loadingLotTests,
    lotTests: lotQCs.lotTests,
    updatingLotTests: lotQCs.updatingLotTests
})
const mapDispatchToProps = dispatch => ({
    onAddNewLotTestQC: payload => dispatch(addNewLotTestQC(payload)),
    onGetQCLotTest: payload => dispatch(getQCLotTest(payload)),
    onUpdateLotTestQC: payload => dispatch(updateLotTestQC(payload)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["managementQCPage", "message", "common"])(ConfigLotTestQCRight)))
