import { Check, CustomBootstrapTable } from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import { ModuleIds, RESULT_STATE_Text, TestRequest_Test_Type, parameterCode, permissionType } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

const RESOURCE = ModuleIds.ChartiQC

const LotIndexTable = ({
    t,
    configs
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(configs)
    }, [configs])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "30px" },
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "index",
            text: t("Index"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "200px" },
            headerStyle: { width: "200px" },
        },
        {
            dataField: "level1",
            text: t("Level") + ' 1',
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
        },
        {
            dataField: "level2",
            text: t("Level") + ' 2',
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
        },
        {
            dataField: "level3",
            text: t("Level") + ' 3',
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
        },
    ]

    return (
        <div className="">
            <CustomBootstrapTable
                columns={columns}
                data={data}
                style={{ height: '250px' }}
                resource={RESOURCE}
                keyField="id"
                isEnableRefresh={false}
                paging={{ dataSize: data?.length }}
                showSelectRow={false}
                isEnableExport={false}
                disableToggle={true}
                TableTitle={t("Index")}
                headerClasses={'table-light result-test-color'}
            />
        </div>
    )
}

export default withTranslation(["chartiQCPage", "common"])(LotIndexTable)
