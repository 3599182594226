import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/datapoints`
const GetQCDatapoints = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
const GetLotForChart = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetLotForChart?${q}`)
}

export {
    GetQCDatapoints,
    GetLotForChart,
}