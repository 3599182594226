import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ChartiQC from "./ChartiQC";

const RESOURCE = ModuleIds.ChartiQC
const ChartiQCContainer = ({ t }) => {
    const titleTag = `Chart iQC`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("sidebar:Chart iQC")}
                resource={RESOURCE}
            />
            <ChartiQC />
        </PageContent>
    );
}

ChartiQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["chartiQCPage"])(ChartiQCContainer)