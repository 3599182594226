// ChartiQC.jsx
import CustomSplitPane from "components/Common/CustomSplitPaneTriple"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "react-splitter-layout/lib/index.css"
import BodyLeft from "./LeftFrame"
import BodyMid from "./MidFrame"
import BodyRight from "./RightFrame"

const RESOURCE = ModuleIds.ChartiQC

const ChartiQC = ({ t }) => {
  const [selected, setSelected] = useState(null)
  const [filterChart, setFilterChart] = useState("validated")
  const [machineItems, setMachineItems] = useState([]);

  const handleFilterChart = newFilterChart => {
    setFilterChart(newFilterChart)
  };

  const handleMachineItemsChange = (items) => {
    setMachineItems(items);
  };

  return (
    <div className="row position-relative h-100">
      <CustomSplitPane
        leftFrame={() => (
          <div className="px-2">
            <BodyLeft
              onChangeSelected={val => setSelected(val)}
              onChangeModel={val => setPayload(val)}
              filterChart={filterChart}
              onMachineItemsChange={handleMachineItemsChange}
            />
          </div>
        )}
        midFrame={() => (
          <div className="px-2">
            <BodyMid
              selected={selected}
              onChangeFilterChart={handleFilterChart}
              machineItems={machineItems}
            />
          </div>
        )}
        rightFrame={() => (
          <div className="px-2">
            <BodyRight selected={selected} filterChart={filterChart} />
          </div>
        )}
      />
      </div>
  )
}

ChartiQC.propTypes = {
  showBothCharts: PropTypes.bool,
  showUnvalidatedPoint: PropTypes.bool,
  levels: PropTypes.string, 
  payload: PropTypes.object, 
  loadingDatapoints: PropTypes.bool,
  error: PropTypes.string,
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "common"])(ChartiQC)))
