import { CustomSelect, CustomSelectGroup } from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"
const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                  <CustomSelectGroup
                      portal
                      name="testCategory"
                      value={model?.testCategory || ""}
                      code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                      label={t("Test Category")}
                      onChange={(name, value, label, item) => {
                        model.testCategory = value[0];
                    }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Lot Group")}
                        name="lotGroup"
                        portal
                        value={model?.lotGroup || ""}
                        code={parameterCode.LOT_GROUP_QC}
                        onChange={(name, value, label, item) => {
                            model.lotGroup = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                <CustomSelect
                    portal
                    name="userId"
                    value={model.userId || ''}
                    code={parameterCode.USERS}
                    label={t("User Name")}
                    onChange={(name, value, item) => {
                        model.userId = value[0]
                    }}
                />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["managementQCPage", "common"])(FilterForm)