import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton,
  CustomModal
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

const WestgardRulesQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Westgard Rules")
  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="westgardSigmaQCForm"
          onValidSubmit={onValidSubmit}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row className="px-2">
            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="code"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Code")}
                  validate={{
                    required: { value: true },
                    maxLength: { value: 20 },
                  }}
                  value={data.code || ""}
                  disabled={isEdit}
                  label={t("Code")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Name")}
                  validate={{
                    required: { value: true },
                    maxLength: { value: 50 },
                  }}
                  value={data.name || ""}
                  label={t("Name")}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="displayOrder"
                  type="number"
                  value={data.displayOrder || ""}
                  label={t("Display Order")}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col className="col-6">
              <Label for="inUse">{t("common:In Use")}</Label>
              <FormSwitchForm
                style={{ marginLeft: 2 }}
                name="inUse"
                label=""
                value={data.inUse || true}
              />
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["westgardSigmaQCPage", "common"])(
  WestgardRulesQCModal
)
