import { CustomBootstrapTable } from "components/Common"
import { CustomTooltipButton } from "components/Common/Button"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds } from "constant"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import ImportExportButton from "./ImportExportForm"

const RESOURCE = ModuleIds.CausalActioniQC

const CausalActionQCTable = ({
  onSelect,
  onSelectAll,
  causalActionQCs,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    type: "",
    category: "",
    sampleType: "",
  })
  const searchQuery = useSelector(state => state.causalActionQC.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      type: "",
    }
    let x = document.getElementsByName("type")
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    if (causalActionQCs) {
      let tmp = causalActionQCs || []
      setData(tmp)
    }
  }, [causalActionQCs])

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, causalActionQC, index) => {
        return cellContent
      },
    },
    {
      dataField: "id",
      text: "#",
      style: { width: 20 },
      formatter: (cellContent, causalActionQC, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "code",
      text: t("Code"),
      sort: true,
    },
    {
      dataField: "name",
      text: t("Name"),
      sort: true,
    },
    {
      dataField: "rules",
      text: t("Rule"),
    },
    {
      dataField: "errorType",
      text: t("Error Type"),
    },
    {
      dataField: "description",
      text: t("Description"),
    },
    {
      dataField: "inUse",
      text: t("common:Status"),
      sort: true,
      formatter: (cellContent, causalActionQC, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent ? "Active" : "Inactive"}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, param, index) => {
        return (
          <div style={{ display: "flex" }}>
            <CustomTooltipButton
              index={index}
              component={param}
              onClone={onClone}
              onEdit={onEdit}
              onDelete={onDelete}
              t={t}
              resource={RESOURCE}
            />
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <CustomBootstrapTable
        columns={columns}
        search
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        isSelected
        onRefresh={onRefresh}
        // filterForm={() => <FilterForm model={model} />}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSubmitFilter={onSubmitFilter}
        searchText={model.search}
        loading={loading}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        isImportExportButton={true}
        importExportForm={() => <ImportExportButton model={model} />}
      />
    </div>
  )
}

export default withTranslation(["causalActionQCPage"])(CausalActionQCTable)
