import { getLottestQCList, updateApplySigmaTeQC, updateLottestQC } from "helpers/app-backend/IQC/QC_Calculation_backend_helper"
import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
import {
    GET_LOT_TEST_QC_LIST,
    UPDATE_APPLY_SIGMA_TE_CALCULATION_QC,
    UPDATE_LOT_TEST_CALCULATION_QC
} from "./actionTypes"
import {
    getLottestListQCFail,
    getLottestListQCSuccess,
    updateApplySigmaTeCalculationQCFail,
    updateApplySigmaTeCalculationQCSuccess,
    updateLottestCalculationQCFail,
    updateLottestCalculationQCSuccess
} from "./actions"
import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchLottestList({ payload, callback }) {
    try {
        const meanPeer = yield select(state => state.calculationQC.meanPeer)
        const cvPeer = yield select(state => state.calculationQC.cvPeer)

        const response = yield call(getLottestQCList, payload)
        if (cvPeer.length > 0) {
            response.forEach(item => {
                item.lottests.forEach(x => {
                    x.cv = cvPeer.find(cv => cv.id == x.id)?.cv
                    x.sd = (x.cv * x.mean) / 100
                })
            })
        }

        if (meanPeer.length > 0) {
            response.forEach(item => {
                item.lottests.forEach(x => {
                    x.mean = meanPeer.find(cv => cv.id == x.id)?.mean
                    x.sd = (x.cv * x.mean) / 100
                })
            })

        }

        if (callback) {
            callback()
        }
        yield put(getLottestListQCSuccess(response))
    } catch (error) {
        yield put(getLottestListQCFail(error))
    }
}

function* onUpdateLottestCalculationQC({ payload, callback }) {
    try {
        const response = yield call(updateLottestQC, payload)
        yield put(updateLottestCalculationQCSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "calculationQCPage:Calculation List"
                )}`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        yield put(updateLottestCalculationQCFail(error))
    }
}

function* onUpdateApplySigmaTeCalculationQC({ payload, callback }) {
    try {
        const response = yield call(updateApplySigmaTeQC, payload)
        yield put(updateApplySigmaTeCalculationQCSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "calculationQCPage:Calculation List"
                )}`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        yield put(updateApplySigmaTeCalculationQCFail(error))
    }
}

function* calculationQCsSaga() {
    yield takeEvery(GET_LOT_TEST_QC_LIST, fetchLottestList)
    yield takeEvery(UPDATE_LOT_TEST_CALCULATION_QC, onUpdateLottestCalculationQC)
    yield takeEvery(UPDATE_APPLY_SIGMA_TE_CALCULATION_QC, onUpdateApplySigmaTeCalculationQC)
}

export default calculationQCsSaga